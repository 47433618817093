import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { BreakpointObserver } from '@angular/cdk/layout';
import { formatDate } from '@angular/common';
import { lastValueFrom } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { tuiCreateTimePeriods } from '@taiga-ui/kit';
import { ResizableBaseComponent } from '@src/app/components/resizable-base-component';
import { ANSWER_TYPES } from '@src/constants';
import { PollService, AlertService, BreakpointObserverHelperService, DocumentService } from '@src/core/services';
import { AnswerType, QuestionFormType } from '@src/models';
import {
  AnswerOptionWithAlternates,
  DocumentView,
  PollFullView,
  QuestionView,
  UserReplies,
  UserRepliesWithAlternates,
} from '@src/api';
import { Language } from '@src/core';

@Component({
  selector: 'app-poll-start',
  templateUrl: './poll-start.component.html',
  styleUrls: ['./poll-start.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PollStartComponent extends ResizableBaseComponent implements OnChanges {
  @Input() data?: QuestionView[];
  @Input() pollData?: PollFullView | null;
  @Input() documentsList?: DocumentView[] | null;
  @Output() stopPoll: EventEmitter<void> = new EventEmitter();
  @ViewChild('fileInput') fileInput?: ElementRef;
  infoForm!: UntypedFormGroup;
  loading: boolean = true;

  answerTypes: AnswerType[] = ANSWER_TYPES;
  readonly timeItems = tuiCreateTimePeriods(0, 24, [0, 15, 30, 45]);

  constructor(
    readonly cdr: ChangeDetectorRef,
    readonly breakpointObserver: BreakpointObserver,
    readonly breakpointObserverHelperService: BreakpointObserverHelperService,
    private formBuilder: UntypedFormBuilder,
    private pollService: PollService,
    private documentService: DocumentService,
    private readonly alertService: AlertService,
    private readonly translateService: TranslateService,
  ) {
    super(cdr, breakpointObserver, breakpointObserverHelperService);

    this.initForm();
  }

  get questions(): UntypedFormArray {
    return this.infoForm.get('questions') as UntypedFormArray;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data) {
      this.loading = true;
      if (this.data) {
        this.setFormData();
        this.loading = false;
      }
    }

    this.cdr.markForCheck();
  }

  addQuestion(): UntypedFormGroup {
    return this.formBuilder.group({
      id: [''],
      text: [''],
      altAnswer: [null],
      answerOption: [],
      answerType: [],
      answers: [],
      file: [],
      date: [],
      time: [],
    });
  }

  async saveReplies() {
    const isAllReplies =
      this.infoForm.value.questions.findIndex(
        (question: any) => !question.altAnswer && !question.file && !question.date && !question.answerOption,
      ) === -1;

    if (!isAllReplies) {
      this.alertService.error(this.translateService.instant('components.pollStart.alerts.errors.fillReplies'));
      return;
    }

    const data = this.infoForm.value;
    const files = await this.upload(data);

    // TODO: сделать рефакторинг. очень сложный код
    const repliesWithAlternates: UserRepliesWithAlternates = this.infoForm.value.questions.map((question: any) => {
      const isMultipleAnswer = question.answerType.id === 3;
      const answer = isMultipleAnswer ? question.answerOption : [question.answerOption];

      if (question.answerType.type === 'date') {
        const dateTime = new Date(
          question.date.year,
          question.date.month,
          question.date.day,
          question.time?.hours ?? 0,
          question.time?.minutes ?? 0,
        );
        question.altAnswer = formatDate(dateTime, 'dd.MM.yyyy HH:mm', Language.RU);
      }

      const answerOptions = answer.map((option: any) => {
        return <AnswerOptionWithAlternates>{
          answerOptionId: option?.id ?? null,
          altAnswer: !isMultipleAnswer || option?.isAlternative ? question.altAnswer : null,
          fileId: question?.file && files ? files.find(item => item.fileName === question.file.name)?.id : null,
        };
      });

      return <UserRepliesWithAlternates>{
        questionId: question.id,
        answerOptions,
      };
    });

    if (!this.pollData?.id) return;

    const reply = <UserReplies>{
      pollId: this.pollData.id,
      repliesWithAlternates,
    };

    this.pollService
      .saveReplies(reply)
      .pipe(takeUntil(this.destroyed$$))
      .subscribe(() => {
        this.alertService.success(this.translateService.instant('components.pollInfo.alerts.successes.saveReplies'), {
          autoClose: 10 * 1000,
        });
        this.stopPoll.emit();
      });
  }

  async upload(data: any) {
    const files = data.questions
      .map((question: any) => {
        return question.file;
      })
      .filter((item: any) => item);
    if (!files.length) return null;
    return (await this.uploadDocuments(files, 10))?.files;
  }

  cancel() {
    this.stopPoll.emit();
  }

  isAlternativeSelected(question: any) {
    return question.get('answerOption')?.value?.find((answer: any) => answer.isAlternative);
  }

  private initForm(): void {
    this.infoForm = this.formBuilder.group({
      questions: this.formBuilder.array([]),
    });
  }

  private setFormData(): void {
    if (!this.data) return;

    const questionsFormatted = (this.data as QuestionFormType[])?.map(question => {
      this.questions.push(this.addQuestion());

      question.answerType = this.answerTypes.find(answerType => answerType.id === question.answerTypeId);
      return question;
    });

    this.infoForm.patchValue({
      questions: questionsFormatted,
    });
  }

  private uploadDocuments(docs: File[], documentType?: number) {
    if (!docs || !documentType) return;

    return lastValueFrom(this.documentService.addDocuments(docs, undefined, documentType));
  }
}
