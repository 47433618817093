import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, Subject, takeUntil } from 'rxjs';
import { TuiDayRange } from '@taiga-ui/cdk';
import { TranslateService } from '@ngx-translate/core';
import { GetUsersBirthdaysResponseItemDto } from '@src/api';
import { TuiDayTimeTransformer } from '@src/utils';
import { Optional } from '@src/types/utils';
import { HeaderOptions } from '@src/app/components';

import { BirthdaysApiService } from '../../services';

@Component({
  selector: 'app-birthdays-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListComponent implements OnInit, OnDestroy {
  @Input() selectedId?: string;
  @Output() selectedIdChange = new EventEmitter<Optional<string>>();

  items$?: Observable<Array<GetUsersBirthdaysResponseItemDto>>;

  headerOptions: HeaderOptions = {
    title: this.translateService.instant('components.birthdaysList.labels.title'),
  };

  readonly periodControl = new FormControl<TuiDayRange | null>(null);

  private readonly destroyed$$ = new Subject<void>();

  constructor(
    private readonly birthdaysApiService: BirthdaysApiService,
    private readonly translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    this.periodControl.valueChanges.pipe(takeUntil(this.destroyed$$)).subscribe(period => {
      const dateStart = TuiDayTimeTransformer.tuiDayToDate(period?.from);
      const dateEnd = TuiDayTimeTransformer.tuiDayToDate(period?.to);
      if (dateStart && dateEnd) {
        this.items$ = this.birthdaysApiService.getUsersBirthdays(dateStart, dateEnd);
      }

      this.resetSelectedId();
    });

    const dateStart = new Date();
    const dateEnd = new Date();
    dateEnd.setDate(dateEnd.getDate() + 14);

    this.periodControl.patchValue(
      new TuiDayRange(TuiDayTimeTransformer.dateToTuiDay(dateStart), TuiDayTimeTransformer.dateToTuiDay(dateEnd)),
    );
  }

  ngOnDestroy(): void {
    this.destroyed$$.next();
    this.destroyed$$.complete();
  }

  onChangeSelectedId(selectedId?: string) {
    this.selectedIdChange.emit(selectedId);
  }

  resetSelectedId() {
    this.selectedId = undefined;
    this.onChangeSelectedId(this.selectedId);
  }
}
