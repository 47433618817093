import { Injectable } from '@angular/core';
import { logger } from '@src/utils';
import { environment } from '@src/environments/environment';

import { TelegramWebApp } from '../types';
import { TELEGRAM_API_CONFIG } from '../constants';

@Injectable({
  providedIn: 'root',
})
export class TelegramWebAppService {
  private initData?: string;

  private tgWebApp: TelegramWebApp;

  constructor() {
    logger('TelegramWebAppService constructor');

    this.tgWebApp = window.Telegram.WebApp;

    if (environment.emulateBotEnv?.enabled) {
      this.initData = environment.emulateBotEnv.key;
    } else {
      this.initData = this.tgWebApp.initData;
    }
  }

  isRunAsTelegramWebApp() {
    return !!this.initData || environment.emulateBotEnv?.enabled;
  }

  getData() {
    return this.initData;
  }

  openTelegramLink(url: string, baseUrl: boolean = false) {
    this.tgWebApp.openTelegramLink(baseUrl ? `${TELEGRAM_API_CONFIG.baseUrl}${url}` : url);
  }

  close() {
    this.tgWebApp.close();
  }
}
