import { Component, ChangeDetectionStrategy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { reload } from '@src/utils';
import { StorageKeys } from '@src/constants/storage';
import { APP_CONFIG, Language } from '@src/core';

import { StorageService } from '../../auth';

@Component({
  selector: 'app-languauge',
  templateUrl: './language.component.html',
  styleUrls: ['./language.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LanguageComponent {
  languageCodes: Language[] = APP_CONFIG.localizations;

  constructor(private readonly translateService: TranslateService, private readonly storageService: StorageService) {}

  getLanguage(code: Language): string {
    return this.translateService.instant('components.language.labels.' + code);
  }

  isSelected(code: Language): boolean {
    return this.translateService.currentLang === code;
  }

  selectLanguage(code: Language) {
    this.storageService.setItem(StorageKeys.Locale, code);
    this.translateService.use(code);

    // TODO: убрать после исправления ошибки #9372
    if (this.translateService.currentLang !== code) {
      reload();
    }
  }
}
