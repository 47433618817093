<div class="search-chats">
  <div class="header">
    <tui-input
      tuiTextfieldSize="s"
      [tuiTextfieldCleaner]="true"
      [(ngModel)]="searchQuery"
      (ngModelChange)="searchQueryChangeHandler($event)"
    >
      {{ 'common.fields.search' | translate }}
    </tui-input>
  </div>
  <tui-loader [showLoader]="!foundChats">
    <app-list
      listType="chats"
      [items]="foundChats"
      [(selectedId)]="chatSelectedId"
      (selectedIdChange)="selectedChat($event)"
    ></app-list>
  </tui-loader>
</div>
