import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Injector,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { AppService } from '@src/core/services';
import { PolymorpheusComponent } from '@tinkoff/ng-polymorpheus';
import { TuiBrightness, TuiDialogService } from '@taiga-ui/core';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { BrandingService } from './modules/branding';
import { UpdateAppDialogComponent, OfflineDialogComponent } from './shared/dialogs';
import {
  ThemeSwitcherService,
  ThemeBrightnessEnum,
  DEFAULT_DARK_THEME_FOR_ASSOCIATION,
} from './modules/theme-switcher';

const APP_NAME = 'UNIONS';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, OnDestroy {
  isDark$ = this.themeSwitcherService.isDark$;

  private offlineDialogSubscription?: Subscription;
  private destroyed$$: Subject<void> = new Subject<void>();
  private readonly confirmUpdateAppDialog = this.dialogService.open<boolean>(
    new PolymorpheusComponent(UpdateAppDialogComponent, this.injector),
    {
      closeable: false,
      dismissible: false,
      size: 's',
    },
  );
  private readonly offlineDialog = this.dialogService.open<boolean>(
    new PolymorpheusComponent(OfflineDialogComponent, this.injector),
    {
      closeable: false,
      dismissible: false,
      size: 's',
    },
  );

  constructor(
    private readonly cdr: ChangeDetectorRef,
    private readonly titleService: Title,
    private readonly brandingService: BrandingService,
    private readonly appService: AppService,
    private readonly themeSwitcherService: ThemeSwitcherService,
    private translateService: TranslateService,
    @Inject(TuiDialogService) private readonly dialogService: TuiDialogService,
    @Inject(Injector) private readonly injector: Injector,
  ) {
    window.addEventListener('online', event => this.updateOnlineStatus(event));
    window.addEventListener('offline', event => this.updateOnlineStatus(event));
  }

  ngOnInit(): void {
    this.brandingService.data$.subscribe(brandData => {
      let appTitle = APP_NAME;
      if (this.brandingService.isBrand() && brandData) {
        appTitle = brandData.shortName ?? this.translateService.instant('common.labels.noData');

        // Выставляем по-умолчанию тёмную тему для нужных ассоциаций
        if (
          this.themeSwitcherService.getTheme() === null &&
          brandData.id &&
          DEFAULT_DARK_THEME_FOR_ASSOCIATION.includes(brandData.id)
        ) {
          this.themeSwitcherService.setTheme(true);
        }
      }

      this.titleService.setTitle(appTitle);
      this.cdr.markForCheck();
    });

    this.appService.isCurrentVersionUnsupported().then(res => {
      if (res === true) this.openConfirmUpdateAppDialog();
    });
  }

  ngOnDestroy(): void {
    this.destroyed$$.next();
    this.destroyed$$.complete();
  }

  get mode(): TuiBrightness | null {
    return this.isDark$.value ? ThemeBrightnessEnum.Dark : null;
  }

  openConfirmUpdateAppDialog(): void {
    const brandData = this.brandingService.data$.value;

    this.confirmUpdateAppDialog.pipe(takeUntil(this.destroyed$$)).subscribe({
      next: res => {
        if (res) {
          this.appService.updateApplicationVersion(brandData);
        }
      },
    });
  }

  private updateOnlineStatus(event: Event) {
    const condition = event.type;

    if (condition === 'offline') {
      this.offlineDialogSubscription = this.offlineDialog.pipe(takeUntil(this.destroyed$$)).subscribe();
    } else if (condition === 'online') {
      this.offlineDialogSubscription?.unsubscribe();
    }
  }
}
