import { Component, ChangeDetectionStrategy, ChangeDetectorRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { BehaviorSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ResizableBaseComponent } from '@src/app/components/resizable-base-component';
import { APP_CONFIG } from '@src/core';
import { AlertService, BreakpointObserverHelperService } from '@src/core/services';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

import { CodeName, CustomNamesItem, ObjectType } from './types';
import { CustomNamesService } from './services/custom-names.service';

@Component({
  selector: 'app-custom-names-tabs',
  templateUrl: './custom-names-tabs.component.html',
  styleUrls: ['./custom-names-tabs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomNamesTabsComponent extends ResizableBaseComponent implements OnChanges {
  /** id комитета или организации */
  @Input() objectId?: string | null;

  @Input() objectType?: ObjectType;

  items$: BehaviorSubject<CustomNamesItem[] | undefined> = this.customNamesService.data$;
  visibledItems?: CustomNamesItem[];
  readonly localizations = APP_CONFIG.localizations;
  private selectedLocalization: string = this.localizations[0];

  form = new UntypedFormGroup({});

  constructor(
    readonly cdr: ChangeDetectorRef,
    readonly breakpointObserver: BreakpointObserver,
    readonly breakpointObserverHelperService: BreakpointObserverHelperService,
    private alertService: AlertService,
    private customNamesService: CustomNamesService,
    private readonly translateService: TranslateService,
  ) {
    super(cdr, breakpointObserver, breakpointObserverHelperService);
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.selectedLocalization = this.localizations[0];

    this.items$.pipe(takeUntil(this.destroyed$$)).subscribe(() => {
      this.setVisibledItems(this.selectedLocalization);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.objectType) {
      if (this.objectId && (this.objectType === 'committee' || this.objectType === 'organisation')) {
        this.customNamesService.getObjectMenuWCustom(this.objectId);
        this.items$ = this.customNamesService.objectMenuWCustom$;
      } else if (this.objectType === 'navigation') {
        this.customNamesService.getData();
        this.items$ = this.customNamesService.data$;
      }
    }
  }

  onClickTabButton(localization: string) {
    this.selectedLocalization = localization;
    this.setVisibledItems(localization);
  }

  onClickSaveButton() {
    if (!this.items$.value) return;

    // TODO: удалить после доработки бэка
    this.items$.value?.map(item => {
      let navigationItem: CustomNamesItem | undefined;
      if (item?.codeName && this.objectType !== 'navigation') {
        navigationItem = this.customNamesService.getItem(item.codeName as CodeName, item.languageCode);
      }

      item.title = item?.title || navigationItem?.title || item?.defaultTitle;
      return item;
    });

    switch (this.objectType) {
      case 'committee':
        if (!this.objectId) return;

        this.customNamesService
          .saveCommitteeData(this.objectId, this.items$.value)
          .pipe(takeUntil(this.destroyed$$))
          .subscribe(() => {
            if (this.objectId) {
              this.customNamesService.getObjectMenuWCustom(this.objectId);
            }

            this.alertService.success(this.translateService.instant('common.alerts.successes.saveData'));
          });
        break;

      case 'organisation':
        if (!this.objectId) return;

        this.customNamesService
          .saveOrganisationData(this.objectId, this.items$.value)
          .pipe(takeUntil(this.destroyed$$))
          .subscribe(() => {
            if (this.objectId) {
              this.customNamesService.getObjectMenuWCustom(this.objectId);
            }

            this.alertService.success(this.translateService.instant('common.alerts.successes.saveData'));
          });
        break;

      case 'navigation':
        this.customNamesService
          .saveData(this.items$.value)
          .pipe(takeUntil(this.destroyed$$))
          .subscribe(() => {
            this.customNamesService.getData();
            this.alertService.success(this.translateService.instant('common.alerts.successes.saveData'));
          });
        break;

      default:
        this.alertService.error(
          this.translateService.instant('components.customNamesTabs.alerts.errors.unknownObjectType'),
        );
        break;
    }
  }

  onMenuItemVisibilityOptionChange(menuItem: CustomNamesItem) {
    this.items$.value
      ?.filter(item => item.codeName === menuItem.codeName)
      .map(item => {
        item.visibilityOption = menuItem.visibilityOption;
        return item;
      });
    this.setVisibledItems(this.selectedLocalization);
  }

  get menuItems(): CustomNamesItem[] {
    return (this.visibledItems || []).filter(visibledItem => visibledItem.isMenuItem);
  }

  get isMenuItems() {
    return this.menuItems.length > 0;
  }

  get nonMenuItems(): CustomNamesItem[] {
    return (this.visibledItems || []).filter(visibledItem => !visibledItem.isMenuItem);
  }

  get isNonMenuItems() {
    return this.nonMenuItems.length > 0;
  }

  private setVisibledItems(localization: string) {
    this.visibledItems = this.items$.value?.filter(item => item.languageCode === localization);

    this.form = new UntypedFormGroup({});
    this.visibledItems?.forEach(item => {
      this.form.addControl(item.codeName!, new UntypedFormControl(item));
    });

    this.cdr.markForCheck();
  }
}
