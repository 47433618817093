import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  ChangeDetectorRef,
  OnDestroy,
} from '@angular/core';
import { Multimedia, ViewMode } from '@src/models';
import { DocumentService } from '@src/core/services';
import { Subject, takeUntil } from 'rxjs';

import { PhotoSize } from './types';

/**
 * Компонент для отображения, добавления и удаления картинки объекта
 * @param {ViewMode} mode {@link ViewMode} - режим отображения (view или edit)
 * @param {string} photoId - id картинки
 * @param {string} photoSrc - ссылка картинки (заменяет photoId)
 * @param {string} documentId - id документа (заменяет photoId и photoSrc)
 * @param {Multimedia} photoChange {@link Multimedia} - возвращаемый объект новой картинки
 * @param {PhotoSize} size {@link PhotoSize} - размер картинки (стандартный)
 * @param {string} width - ширина картинки (нестандартный размер)
 * @param {string} height - высота картинки (нестандартный размер)
 * @param {boolean} rounded - скругление краев
 * @param {boolean} loading - отображение загрузки
 *
 * @see https://www.figma.com/file/tdtoaaGrQa3XOG9Qqv96VI/Unions-(Taiga-3.0)?type=design&node-id=727-66631&mode=dev
 */
@Component({
  selector: 'app-photo',
  templateUrl: './photo.component.html',
  styleUrls: ['./photo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
// TODO: Научить работать с формами через extends AbstractTuiControl
export class PhotoComponent implements OnChanges, OnDestroy {
  @Input() mode: ViewMode = 'view';
  @Input() text?: string;
  @Input() photoId?: string;
  @Input() photoSrc?: string;
  @Input() documentId?: string;
  @Input() unionId?: string;
  @Output() photoChange: EventEmitter<Multimedia | undefined> = new EventEmitter();
  @Output() photoDeleted: EventEmitter<void> = new EventEmitter();

  @Input() size: PhotoSize = 'xxl';
  @Input() width?: string;
  @Input() height?: string;
  @Input() rounded: boolean = false;
  @Input() loading: boolean = false;

  private destroyed$$: Subject<void> = new Subject<void>();

  constructor(readonly cdr: ChangeDetectorRef, private readonly documentService: DocumentService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.documentId && this.documentId) {
      this.documentService
        .downloadDocumentData(this.documentId)
        .pipe(takeUntil(this.destroyed$$))
        .subscribe(document => {
          this.photoSrc = window.URL.createObjectURL(document);
          this.cdr.markForCheck();
        });
    }
  }

  ngOnDestroy(): void {
    this.destroyed$$.next();
    this.destroyed$$.complete();
  }

  onChangePhoto(newPhoto?: Multimedia) {
    this.photoChange.emit(newPhoto);
  }

  onDeletedPhoto() {
    this.photoDeleted.emit();
  }
}
