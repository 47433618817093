import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import { catchError, map, takeUntil } from 'rxjs/operators';
import {
  SubscriptionsForOrganisationView,
  SubscriptionsForOrganisationService as ApiSubscriptionsForOrganisationService,
  AddSubscriptionWithChat,
  EditSubscription,
} from '@src/api';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionsForOrganisationService implements OnDestroy {
  subscription$: BehaviorSubject<SubscriptionsForOrganisationView | null>;
  subscriptions$: BehaviorSubject<SubscriptionsForOrganisationView[] | null>;
  associationSubscriptions$: BehaviorSubject<SubscriptionsForOrganisationView[] | null>;

  private destroyed$$: Subject<void> = new Subject<void>();

  constructor(private subscriptionsForOrganisationService: ApiSubscriptionsForOrganisationService) {
    this.subscription$ = new BehaviorSubject<SubscriptionsForOrganisationView | null>(null);
    this.subscriptions$ = new BehaviorSubject<SubscriptionsForOrganisationView[] | null>(null);
    this.associationSubscriptions$ = new BehaviorSubject<SubscriptionsForOrganisationView[] | null>(null);
  }

  ngOnDestroy(): void {
    this.destroyed$$.next();
    this.destroyed$$.complete();
  }

  getSubscription(id: string): void {
    this.subscriptionsForOrganisationService
      .subscription(id)
      .pipe(
        catchError(err => {
          // TODO show error notification
          return throwError(err);
        }),
        takeUntil(this.destroyed$$),
      )
      .subscribe(subscription => this.subscription$.next(subscription));
  }

  getSubscriptions(organisationId: string, callback?: (value: boolean) => void): void {
    callback?.(true);

    this.subscriptionsForOrganisationService
      .organisationSubsctiptions(organisationId)
      .pipe(
        catchError(err => {
          // TODO show error notification
          callback?.(false);
          return throwError(err);
        }),
        map(subscriptions => this.sortingSubscriptions(subscriptions)),
        takeUntil(this.destroyed$$),
      )
      .subscribe(subscriptions => {
        this.subscriptions$.next(subscriptions);
        callback?.(false);
      });
  }

  getAssociationSubscriptions(associationId: string): void {
    this.subscriptionsForOrganisationService
      .organisationSubsctiptions(associationId)
      .pipe(
        catchError(err => {
          // TODO show error notification
          return throwError(err);
        }),
        map(subscriptions => this.sortingSubscriptions(subscriptions)),
        takeUntil(this.destroyed$$),
      )
      .subscribe(subscriptions => this.associationSubscriptions$.next(subscriptions));
  }

  getSubscriptionsData(organisationId: string): Observable<SubscriptionsForOrganisationView[]> {
    return this.subscriptionsForOrganisationService.organisationSubsctiptions(organisationId).pipe(
      catchError(err => {
        // TODO show error notification
        return throwError(err);
      }),
      map(subscriptions => this.sortingSubscriptions(subscriptions)),
    );
  }

  addSubscriptions(subscriptionsData: AddSubscriptionWithChat[]) {
    return this.subscriptionsForOrganisationService.addSubscriptionList(subscriptionsData);
  }

  editSubscription(subscriptionData: EditSubscription) {
    return this.subscriptionsForOrganisationService.editSubscription(subscriptionData);
  }

  deleteSubscription(channelId: string) {
    return this.subscriptionsForOrganisationService.deleteOrganisationSubsctiption(channelId);
  }

  resetSubscription() {
    this.subscription$.next(null);
  }

  resetSubscriptions() {
    this.subscriptions$.next(null);
  }

  resetAll() {
    this.resetSubscription();
    this.resetSubscriptions();
  }

  private sortingSubscriptions(subscriptions: SubscriptionsForOrganisationView[]): SubscriptionsForOrganisationView[] {
    subscriptions.sort((subscription1, subscription2) => {
      const subscriptionName1 = subscription1.name?.toLowerCase();
      const subscriptionName2 = subscription2.name?.toLowerCase();

      if (!subscriptionName1) return 1;
      if (!subscriptionName2) return -1;

      if (subscriptionName1 < subscriptionName2) return -1;
      if (subscriptionName1 > subscriptionName2) return 1;
      return 0;
    });

    return subscriptions;
  }
}
