import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { OrganisationView } from '@src/api';

import { NavigationItem } from '../types';

@Component({
  selector: 'app-navigation-mobile',
  templateUrl: './navigation-mobile.component.html',
  styleUrls: ['../styles/navigation.scss', './navigation-mobile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationMobileComponent {
  @Input() items?: NavigationItem[];
  @Input() parentOrganisations?: OrganisationView[] | null;
  @Input() currentParentOrganisation?: OrganisationView;
  @Input() isMembershipPaid?: boolean;
  @Input() membershipStatus?: string;
  @Input() isBrand?: boolean;
  @Output() selectedItemChanged: EventEmitter<NavigationItem>;
  @Output() currentParentOrganisationChanged: EventEmitter<OrganisationView>;

  constructor() {
    this.selectedItemChanged = new EventEmitter<NavigationItem>();
    this.currentParentOrganisationChanged = new EventEmitter<OrganisationView>();
  }

  emitCurrentParentOrganisation(selectedParentOrganisation: OrganisationView): void {
    this.currentParentOrganisationChanged.emit(selectedParentOrganisation);
  }
}
