import { Component, Inject, Injector, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TuiDialogService } from '@taiga-ui/core';
import { PolymorpheusComponent } from '@tinkoff/ng-polymorpheus';
import { UserUI } from '@src/models';
import { UserService } from '@src/core/services';
import { LogoutService } from '@src/app/modules/auth';
import { DialogConfirmComponent } from '@src/app/shared/dialogs';
import { NavigationItem, INFO_NAVIGATION, NAVIGATION_ITEMS } from '@src/app/modules/navigation';
import { TranslateService } from '@ngx-translate/core';
import { CustomNamesItem, CustomNamesService } from '@src/app/modules/custom-name-tabs';

@Component({
  selector: 'app-information',
  templateUrl: './information.component.html',
  styleUrls: ['./information.component.scss'],
})
export class InformationComponent implements OnInit, OnDestroy {
  items?: NavigationItem[];
  selectedItem?: NavigationItem;
  allCustomNames$: BehaviorSubject<CustomNamesItem[] | undefined> = this.customNamesService.allCustomNames$;
  authUser$: BehaviorSubject<UserUI | undefined> = this.userService.authUser$;

  private destroyed$$: Subject<void> = new Subject<void>();
  private readonly confirmDialog = this.dialogService.open<boolean>(
    new PolymorpheusComponent(DialogConfirmComponent, this.injector),
    {
      label: this.translateService.instant('common.dialogs.logOutHeader'),
      size: 's',
      closeable: false,
    },
  );

  constructor(
    private router: Router,
    private logoutService: LogoutService,
    private userService: UserService,
    private customNamesService: CustomNamesService,
    private readonly translateService: TranslateService,
    @Inject(TuiDialogService) private readonly dialogService: TuiDialogService,
    @Inject(Injector) private readonly injector: Injector,
  ) {}

  ngOnInit(): void {
    this.allCustomNames$.pipe(takeUntil(this.destroyed$$)).subscribe(allCustomNames => {
      if (!allCustomNames?.length) return;

      this.initNavigation();
    });
  }

  ngOnDestroy(): void {
    this.destroyed$$.next();
    this.destroyed$$.complete();
  }

  onClickItem(item: NavigationItem): void {
    item.action && item.action();
    item.active = true;
    this.selectedItem = item;

    if (this.selectedItem.link) {
      this.router.navigate([this.selectedItem.link]);
    }
  }

  onClickCuratorButton(): void {
    this.userService.communicationWithCurator();
  }

  onClickExitButton(): void {
    this.confirmDialog.pipe(takeUntil(this.destroyed$$)).subscribe({
      next: res => {
        if (res) {
          this.logoutService.logout();
        }
      },
    });
  }

  private initNavigation() {
    this.items = NAVIGATION_ITEMS.filter(
      navigationItem =>
        INFO_NAVIGATION.filter(item => !!item.itemName).findIndex(
          infoNavigationItem => infoNavigationItem.itemName === navigationItem.id,
        ) > -1,
    )
      .filter(navItem => {
        if (navItem.id === 'menu.curator') {
          return !!this.authUser$.value?.curatorId;
        }

        return true;
      })
      .map(item => {
        if (item.id === 'menu.curator') {
          item.action = () => this.onClickCuratorButton();
        }

        if (item.id === 'menu.logout') {
          item.action = () => this.onClickExitButton();
        }

        item.title = this.customNamesService.getItem(item.id)?.title ?? item.title;

        return item;
      });
  }
}
