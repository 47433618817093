import {
  Component,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  ViewChild,
  ElementRef,
  Input,
  Inject,
  Injector,
  Output,
  EventEmitter,
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PolymorpheusComponent } from '@tinkoff/ng-polymorpheus';
import { TuiDialogService } from '@taiga-ui/core';
import { APP_CONFIG } from '@src/core';
import { AlertService, PhotoService } from '@src/core/services';
import { Multimedia } from '@src/models';
import { getImageSrc } from '@src/utils';
import { DialogConfirmComponent } from '@src/app/shared/dialogs';

import { PhotoSize } from '../types';

@Component({
  selector: 'photo-edit',
  templateUrl: './photo-edit.component.html',
  styleUrls: ['./photo-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PhotoEditComponent {
  @Input() text?: string;
  @Input() photoId?: string;
  @Output() photoChange: EventEmitter<Multimedia | undefined> = new EventEmitter();
  @Output() photoDeleted: EventEmitter<void> = new EventEmitter();

  @Input() size: PhotoSize = 'xxl';
  @Input() width?: string;
  @Input() height?: string;
  @Input() rounded: boolean = false;
  @Input() loading: boolean = false;

  @ViewChild('photoFileInput') photoFileInput?: ElementRef;

  newPhoto?: Multimedia;

  private destroyed$$: Subject<void> = new Subject<void>();
  private readonly confirmPhotoDeleteDialog = this.dialogService.open<boolean>(
    new PolymorpheusComponent(DialogConfirmComponent, this.injector),
    {
      label: 'Удаление фото',
      size: 's',
      closeable: false,
    },
  );

  constructor(
    private readonly cdr: ChangeDetectorRef,
    private readonly alertService: AlertService,
    private readonly photoService: PhotoService,
    @Inject(TuiDialogService) private readonly dialogService: TuiDialogService,
    @Inject(Injector) private readonly injector: Injector,
  ) {}

  getPhotoUrl() {
    return this.newPhoto?.source?.src ?? getImageSrc(this.photoId);
  }

  onClickPhoto(e: any): void {
    e.stopPropagation();

    if (!this.photoFileInput) return;

    this.photoFileInput.nativeElement.value = '';
    this.photoFileInput.nativeElement.click();
  }

  photoFileInputChange(event: Event): void {
    const files: FileList | null = (event.target as HTMLInputElement).files;
    if (!files?.length || !this.photoFileInput) return;

    const file = files.item(0);
    if (!file) return;

    if (file.size > APP_CONFIG.fileSizeMax.image) {
      this.alertService.error('Файл превышает объём 10 МБ');

      this.photoFileInput.nativeElement.value = '';
      this.newPhoto = undefined;
    } else {
      this.newPhoto = {
        file,
        source: { src: window.URL.createObjectURL(file) },
      };
    }

    this.photoChange.emit(this.newPhoto);

    this.cdr.markForCheck();
  }

  onPhotoDelete(): void {
    this.newPhoto = undefined;

    const photoId = this.photoId;
    if (photoId) {
      this.confirmPhotoDeleteDialog.pipe(takeUntil(this.destroyed$$)).subscribe({
        next: res => {
          if (res === true) {
            this.loading = true;
            this.photoService
              .deletePhoto(photoId)
              .pipe(takeUntil(this.destroyed$$))
              .subscribe(() => {
                this.photoId = undefined;
                this.loading = false;
                this.photoDeleted.emit();
                this.cdr.markForCheck();
              });
          }
          this.cdr.markForCheck();
        },
      });
    }

    this.cdr.markForCheck();
  }
}
