import { Component, ChangeDetectionStrategy, ChangeDetectorRef, Input } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SessionService } from '@src/app/modules/auth';
import { OrganisationView } from '@src/api';
import { BreakpointObserverHelperService, OrganisationService, UserService } from '@src/core/services';
import { UserUI } from '@src/models';
import { reload } from '@src/utils';
import { MEMBERSHIP_STATUS } from '@src/constants';
import { BrandingService, OrganisationShortInfoUI } from '@src/app/modules/branding';
import { ResizableBaseComponent } from '@src/app/components/resizable-base-component';

import { CustomNamesItem, CustomNamesService } from '../custom-name-tabs';

import { NavigationItem, NavigationTree } from './types';
import { DESKTOP_NAVIGATION, MOBILE_NAVIGATION, NAVIGATION_ITEMS } from './constants';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationComponent extends ResizableBaseComponent {
  @Input() isOpen: boolean = true;

  navTree: NavigationTree[] = DESKTOP_NAVIGATION;
  navItems: NavigationItem[];
  displayedNavItems?: NavigationItem[];
  parentOrganisations$: BehaviorSubject<OrganisationView[]> = this.organisationService.parentOrganisations$;
  currentParentOrganisation?: OrganisationView;
  authUser$: BehaviorSubject<UserUI | undefined> = this.userService.authUser$;
  isMembershipPaid?: boolean = true;
  membershipStatus: string = '';
  allCustomNames$: BehaviorSubject<CustomNamesItem[] | undefined> = this.customNamesService.allCustomNames$;

  isBrand = this.brandingService.isBrand();
  brandingData$: BehaviorSubject<OrganisationShortInfoUI | null | undefined> = this.brandingService.data$;

  /** Текущий активный маршрут */
  private activeRoute = '';

  constructor(
    readonly cdr: ChangeDetectorRef,
    readonly breakpointObserver: BreakpointObserver,
    readonly breakpointObserverHelperService: BreakpointObserverHelperService,
    private readonly router: Router,
    private readonly sessionService: SessionService,
    private readonly organisationService: OrganisationService,
    private readonly userService: UserService, // private readonly badgeService: BadgeService, // TODO: for task #7880
    private readonly customNamesService: CustomNamesService,
    private readonly brandingService: BrandingService,
  ) {
    super(cdr, breakpointObserver, breakpointObserverHelperService);

    this.navItems = NAVIGATION_ITEMS;

    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.activeRoute = event.urlAfterRedirects.slice(1);
        this.initNavigation();
      }
    });
  }

  protected afterChangeScreenType() {
    this.initNavigation();
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.authUser$.pipe(takeUntil(this.destroyed$$)).subscribe(user => {
      if (!user?.parentOrganisationId) return;

      this.currentParentOrganisation = this.parentOrganisations$.value.find(
        parentOrganisation => parentOrganisation.id === user.parentOrganisationId,
      );

      this.isMembershipPaid = true;
      this.membershipStatus = MEMBERSHIP_STATUS.paid;
      if (user.parentOrganisationId && user.organisationId && !user.isFeePaid) {
        this.organisationService.getOrganisationData(user.organisationId).then(organisation => {
          const membershipPaidTillDate = organisation.membershipPaidTill
            ? new Date(organisation.membershipPaidTill)
            : undefined;
          this.isMembershipPaid = membershipPaidTillDate ? organisation.isMembershipPaid : true;
          if (!this.isMembershipPaid) {
            this.membershipStatus = MEMBERSHIP_STATUS.expired;
          } else if (membershipPaidTillDate) {
            const daysToToday = Math.ceil(
              Math.abs(membershipPaidTillDate.getTime() - new Date().getTime()) / (1000 * 3600 * 24),
            );
            if (daysToToday < 14) {
              this.membershipStatus = MEMBERSHIP_STATUS.expireInTwoWeeks;
            } else if (daysToToday <= 31) {
              this.membershipStatus = MEMBERSHIP_STATUS.expireInMonth;
            } else {
              this.membershipStatus = MEMBERSHIP_STATUS.paid;
            }
          }
        });
      } else if (user.isFeePaid) {
        this.isMembershipPaid = false;
        this.membershipStatus = MEMBERSHIP_STATUS.expired;
      }
    });

    this.allCustomNames$.pipe(takeUntil(this.destroyed$$)).subscribe(allCustomNames => {
      if (!allCustomNames?.length) return;

      this.initNavigation();
    });

    this.parentOrganisations$.pipe(takeUntil(this.destroyed$$)).subscribe(parentOrganisations => {
      if (!parentOrganisations.length) return;

      this.currentParentOrganisation = parentOrganisations.find(
        parentOrganisation => parentOrganisation.id === this.authUser$.value?.parentOrganisationId,
      );

      if (!this.currentParentOrganisation) return;
      this.customNamesService.getData();
    });

    this.userService.getAuthorizedUser();
    this.organisationService.getParentOrganisation(true);
    this.cdr.markForCheck();
  }

  onChangeSelectedItem(selectedItem: NavigationItem): void {
    if (selectedItem.link) {
      this.router.navigateByUrl(selectedItem.link);
    }
  }

  onChangeCurrentParentOrganisation(selectedParentOrganisation: OrganisationView): void {
    if (selectedParentOrganisation.id) {
      this.sessionService.changeToken(selectedParentOrganisation.id).subscribe(() => {
        this.currentParentOrganisation = selectedParentOrganisation;
        reload();
      });
    }
  }

  onClickCuratorButton(): void {
    this.userService.communicationWithCurator();
  }

  private initNavigation(): void {
    this.navTree = this.isDesktop ? DESKTOP_NAVIGATION : MOBILE_NAVIGATION;

    this.displayedNavItems = this.navItems
      .filter(navItem => this.navTree.findIndex(navTreeItem => navTreeItem.itemName === navItem.id) > -1)
      .filter(navItem => {
        if (navItem.id === 'menu.curator') {
          return !!this.authUser$.value?.curatorId;
        }

        return true;
      })
      .map(navItem => {
        navItem.title = this.customNamesService.getItem(navItem.id)?.title ?? navItem.title;

        if (navItem.id === 'menu.curator') {
          navItem.action = () => this.onClickCuratorButton();
        }

        // TODO: for task #7880
        // switch (navItem.id) {
        //   case 'channels':
        //     navItem.badge = this.badgeService.channels$;
        //     break;

        //   case 'chats':
        //     navItem.badge = this.badgeService.chats$;
        //     break;

        //   case 'events':
        //     navItem.badge = this.badgeService.events$;
        //     break;

        //   case 'polls':
        //     navItem.badge = this.badgeService.polls$;
        //     break;

        //   default:
        //     break;
        // }

        if (navItem.baseLink) {
          const [route] = this.activeRoute.split('?')?.[0].split('/');
          navItem.selectedItem = route === navItem.baseLink;
        } else if (navItem.link === 'settings' && this.activeRoute.startsWith('settings')) {
          // Отдельно для `/settings` добавили такую проверку, т.к. вложенные маршруты строятся по другому
          navItem.selectedItem = true;
        } else {
          navItem.selectedItem = navItem.link === this.activeRoute;
        }

        return navItem;
      });

    this.cdr.markForCheck();
  }
}
