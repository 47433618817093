import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { EnvService } from '@src/app/modules/env';
import { DocumentService } from '@src/core/services';

import { LoginEnterType } from '../../login.model';

@Component({
  selector: 'app-login-policy',
  templateUrl: './policy.component.html',
  styleUrls: ['./policy.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PolicyComponent {
  /** id ассоциации, в которую логинимся. если не указать, бэк выберет сам в какую */
  @Input() associationId?: string;

  /** Название ассоциации */
  @Input() associationShortName?: string;

  /** Ссылка на политику конфиденциальности ассоциации */
  @Input() privacyPolicyLink?: string;

  /** тип входа */
  @Input() enterType?: LoginEnterType;

  /** Закрыть страницу */
  @Output() close: EventEmitter<void> = new EventEmitter();

  constructor(private readonly documentService: DocumentService, private readonly env: EnvService) {}

  get isCordova() {
    return this.env.isCordova;
  }

  downloadAssociationPolicy() {
    if (!this.associationId) return;

    this.documentService.downloadAssociationPolicy(this.associationId);
  }

  closePage() {
    this.close.emit();
  }
}
