import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import {
  TuiAccordionModule,
  TuiBadgeModule,
  TuiCheckboxLabeledModule,
  TuiInputModule,
  TuiToggleModule,
} from '@taiga-ui/kit';
import {
  TuiButtonModule,
  TuiDataListModule,
  TuiHostedDropdownModule,
  TuiLabelModule,
  TuiLinkModule,
  TuiLoaderModule,
  TuiTextfieldControllerModule,
  TuiSvgModule,
  TuiPrimitiveTextfieldModule,
  TuiDropdownModule,
  TuiHintModule,
  TuiGroupModule,
} from '@taiga-ui/core';
import { TuiAutoFocusModule } from '@taiga-ui/cdk';
import { TuiLegendItemModule } from '@taiga-ui/addon-charts';
import { SharedModule } from '@src/app/shared';
import { IconModule } from '@src/app/modules/icon';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { PhotoModule } from '@src/app/modules/photo';

import { LogoComponent } from './logo/logo.component';
import { TermOfThePrivacyComponent } from './term-of-the-privacy/term-of-the-privacy.component';
import { MobileAppDownloadLinksComponent } from './mobile-app-download-links/mobile-app-download-links.component';
import { SkeletonRowComponent } from './skeleton-row/skeleton-row.component';
import { SkeletonWrapperComponent } from './skeleton-wrapper/skeleton-wrapper.component';
import { CopyToClipboardButtonComponent } from './copy-to-clipboard-button/copy-to-clipboard-button.component';
import { ParseLinksComponent } from './parse-links/parse-links.component';
import { ResizableBaseComponent } from './resizable-base-component';
import { SvgIconComponent } from './svg-icon/svg-icon.component';
import { SearchComponent } from './search';
import { BadgeComponent } from './badge';
import { LabelComponent } from './label/label.component';
import { PreviewButtonComponent } from './preview-button/preview-button.component';
import { ToggleComponent } from './toggle/toggle.component';
import { HeaderComponent } from './header/header.component';
import { LoaderWrapperComponent } from './loader-wrapper/loader-wrapper.component';
import { MoreButtonComponent } from './more-button';
import { LegendComponent } from './legend';
import { ElderlyComponent } from './elderly/elderly.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { AccordionSelectionComponent } from './accordion-selection/accordion-selection.component';
import { ParseMultimediaLinksComponent } from './parse-multimedia-links';
import { LinkComponent } from './link/link.component';
import { WhatsAppLinkComponent } from './whats-app-link/whats-app-link.component';
import { TelegramLinkComponent } from './telegram-link/telegram-link.component';
import { DownloadLinkComponent } from './download-link/download-link.component';

@NgModule({
  declarations: [
    BadgeComponent,
    LogoComponent,
    TermOfThePrivacyComponent,
    MobileAppDownloadLinksComponent,
    SkeletonRowComponent,
    SkeletonWrapperComponent,
    CopyToClipboardButtonComponent,
    ParseLinksComponent,
    ResizableBaseComponent,
    SvgIconComponent,
    SearchComponent,
    BadgeComponent,
    LabelComponent,
    PreviewButtonComponent,
    ToggleComponent,
    HeaderComponent,
    LoaderWrapperComponent,
    MoreButtonComponent,
    LegendComponent,
    ElderlyComponent,
    ConfirmDialogComponent,
    AccordionSelectionComponent,
    ParseMultimediaLinksComponent,
    LinkComponent,
    WhatsAppLinkComponent,
    TelegramLinkComponent,
    DownloadLinkComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    IconModule,
    ReactiveFormsModule,
    RouterModule,
    SharedModule,
    TuiAutoFocusModule,
    TuiBadgeModule,
    TuiButtonModule,
    TuiDataListModule,
    TuiDropdownModule,
    TuiHostedDropdownModule,
    TuiInputModule,
    TuiLabelModule,
    TuiLegendItemModule,
    TuiLoaderModule,
    TuiLinkModule,
    TuiSvgModule,
    TuiTextfieldControllerModule,
    TuiToggleModule,
    TuiPrimitiveTextfieldModule,
    TuiAccordionModule,
    TuiHintModule,
    TuiGroupModule,
    TuiCheckboxLabeledModule,
    DragDropModule,
    PhotoModule,
  ],
  exports: [
    BadgeComponent,
    CopyToClipboardButtonComponent,
    LabelComponent,
    LogoComponent,
    TermOfThePrivacyComponent,
    MobileAppDownloadLinksComponent,
    ParseLinksComponent,
    PreviewButtonComponent,
    SearchComponent,
    SkeletonRowComponent,
    SkeletonWrapperComponent,
    SvgIconComponent,
    ToggleComponent,
    HeaderComponent,
    LoaderWrapperComponent,
    MoreButtonComponent,
    LegendComponent,
    ElderlyComponent,
    AccordionSelectionComponent,
    ParseMultimediaLinksComponent,
    LinkComponent,
    WhatsAppLinkComponent,
    TelegramLinkComponent,
    DownloadLinkComponent,
  ],
})
export class ComponentsModule {}
