<app-group-info-view
  [mode]="mode"
  [data]="data"
  [members]="members"
  [isChannel]="isChannel"
  [memberCount]="memberCount"
  [allowEditing]="allowEditing$ | async"
  [allowDeleting]="allowDeleting$ | async"
  [allowSubscribing]="allowSubscribing"
  [allowSpecialFieldsViewing]="allowSpecialFieldsViewing"
  [loading]="loading"
  [membersLoading]="membersLoading"
  (startEditing)="onStartEditing()"
  (saved)="onSaveData($event)"
  (canceled)="onCancel()"
  (deleted)="openConfirmDialog()"
  (deletePhoto)="onDeletePhoto()"
  (subscribed)="onSubscribe()"
  (unsubscribed)="onUnsubscribe()"
  (updateMembers)="refreshMembers()"
></app-group-info-view>

<ng-template #confirmDialogTemplate let-observer>
  <p>{{ 'common.dialogs.areYouSureTitle' | translate }}</p>
  <div *ngIf="allowDeletingFromTelegram" class="tui-space_bottom-3" waitTelegramChats>
    <tui-checkbox-block [(ngModel)]="deleteFromTelegram" [size]="tuiElementMediumSize">
      {{ 'components.groupInfo.labels.deleteFromTelegram' | translate }}
    </tui-checkbox-block>
  </div>
  <button
    tuiButton
    type="button"
    class="tui-space_right-3"
    [size]="tuiElementMediumSize"
    (click)="applyDelete(observer)"
  >
    {{ 'common.buttons.yes' | translate }}
  </button>
  <button tuiButton type="button" [size]="tuiElementMediumSize" (click)="observer.complete()">
    {{ 'common.buttons.no' | translate }}
  </button>
</ng-template>
