import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TuiButtonModule, TuiTextfieldControllerModule } from '@taiga-ui/core';
import { TuiInputModule } from '@taiga-ui/kit';
import { TranslateModule } from '@ngx-translate/core';

import { ListModule } from '../list';

import { SearchGroupsComponent } from './search-groups.component';

@NgModule({
  declarations: [SearchGroupsComponent],
  imports: [
    CommonModule,
    FormsModule,
    ListModule,
    TuiButtonModule,
    TuiInputModule,
    TuiTextfieldControllerModule,
    TranslateModule,
  ],
  exports: [SearchGroupsComponent],
})
export class SearchGroupsModule {}
