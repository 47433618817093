import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard, RedirectGuard, UnAuthGuard } from '@src/app/modules/auth';
import { FoldersComponent } from '@src/app/modules/telegram';
import { getPermissionGuardProps } from '@src/utils';

import { AppLayoutComponent } from './app-layout';
import { AssociationComponent } from './pages/association-page';
import { InformationComponent } from './pages/information-page';
import { LoginComponent } from './modules/login';
import { ModeratorPageComponent } from './pages/moderator-page';
import { PageNotFoundComponent } from './pages/page-not-found';
import { PrivacyPageComponent } from './pages/privacy-page';
import { BirthdaysPageComponent } from './pages/birthdays-page';
import {
  NotificationsComponent,
  SettingsComponent,
  SupportPageComponent,
  LicensesPageComponent,
  ChangelogPageComponent,
  PreferencesComponent,
  ProfileComponent,
  LanguageComponent,
  ThemeComponent,
} from './modules/settings';
import { PolicyPageComponent } from './pages/policy-page';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [UnAuthGuard],
  },
  {
    path: 'not-found',
    component: PageNotFoundComponent,
  },
  {
    path: 'policy',
    component: PolicyPageComponent,
  },
  {
    path: 'privacy',
    component: PrivacyPageComponent,
  },
  {
    path: 'support',
    component: SupportPageComponent,
  },
  {
    path: 'user-registration',
    component: ModeratorPageComponent,
    canActivate: [UnAuthGuard],
  },
  {
    path: '',
    component: AppLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: InformationComponent,
        canActivate: [RedirectGuard],
      },
      {
        path: 'info',
        component: InformationComponent,
        ...getPermissionGuardProps('pageInfoViewing'),
      },
      {
        path: 'chats',
        loadChildren: () => import('./pages/chats-page').then(module => module.ChatsPageModule),
        ...getPermissionGuardProps('pageChatsViewing'),
      },
      {
        path: 'events',
        loadChildren: () => import('./pages/events-page').then(module => module.EventsPageModule),
        ...getPermissionGuardProps('pageEventsViewing'),
      },
      {
        path: 'association',
        component: AssociationComponent,
        ...getPermissionGuardProps('pageAssociationViewing'),
      },
      {
        path: 'association-organisations',
        loadChildren: () => import('./pages/organisations-page').then(module => module.OrganisationsPageModule),
        ...getPermissionGuardProps('pageAssociationOrganisationsViewing'),
      },
      {
        path: 'association-users',
        loadChildren: () => import('./pages/users-page').then(module => module.UsersPageModule),
        ...getPermissionGuardProps('pageAssociationUsersViewing'),
      },
      {
        path: 'committees',
        loadChildren: () => import('./pages/committees-page').then(module => module.CommitteesPageModule),
        ...getPermissionGuardProps('pageCommitteesViewing'),
      },
      // {
      //   path: 'sub-committees',
      //   loadChildren: () => import('./pages/sub-committees-page').then(module => module.SubCommitteesPageModule),
      //   ...getPermissionGuardProps('pageSubCommitteesViewing'),
      // },
      {
        path: 'organisation',
        loadChildren: () => import('./pages/organisation-page').then(module => module.OrganisationModule),
        ...getPermissionGuardProps('pageOrganisationViewing'),
      },
      {
        path: 'polls',
        loadChildren: () => import('./pages/polls-page').then(module => module.PollsModule),
        ...getPermissionGuardProps('pagePollsViewing'),
      },
      {
        path: 'newsletter',
        loadChildren: () => import('./pages/newsletter-page').then(module => module.NewsletterPageModule),
        ...getPermissionGuardProps('pageNewsletterViewing'),
      },
      {
        path: 'news-feed',
        loadChildren: () => import('./pages/news-feed-page').then(module => module.NewsFeedPageModule),
        ...getPermissionGuardProps('pageNewsFeedViewing'),
      },
      {
        path: 'settings',
        component: SettingsComponent,
        ...getPermissionGuardProps('pageSettingsViewing'),
        children: [
          {
            path: 'profile',
            component: ProfileComponent,
            ...getPermissionGuardProps('pageProfileViewing'),
          },
          {
            path: 'folders',
            component: FoldersComponent,
            ...getPermissionGuardProps('pageSettingsFoldersViewing'),
          },
          {
            path: 'notifications',
            component: NotificationsComponent,
            ...getPermissionGuardProps('pageSettingsNotificationsViewing'),
          },
          {
            path: 'administration',
            loadChildren: () => import('./modules/settings/administration').then(module => module.AdministrationModule),
            ...getPermissionGuardProps('pageAdministrationViewing'),
          },
          {
            path: 'language',
            component: LanguageComponent,
          },
          {
            path: 'theme',
            component: ThemeComponent,
          },
          {
            path: 'licenses',
            component: LicensesPageComponent,
          },
          {
            path: 'changelog',
            component: ChangelogPageComponent,
          },
          {
            path: 'support',
            component: SupportPageComponent,
          },
          {
            path: 'preferences',
            component: PreferencesComponent,
            ...getPermissionGuardProps('pageSettingsPreferencesViewing'),
          },
        ],
      },
      {
        path: 'loyalty',
        loadChildren: () => import('./pages/loyalty-page').then(module => module.LoyaltyPageModule),
        ...getPermissionGuardProps('pageLoyaltyViewing'),
      },
      {
        path: 'cooperation-chains',
        loadChildren: () =>
          import('./pages/cooperation-chains-page').then(module => module.CooperationChainsPageModule),
        ...getPermissionGuardProps('cooperationChainsViewing'),
      },
      {
        path: 'birthdays',
        component: BirthdaysPageComponent,
      },
      {
        path: '**',
        component: PageNotFoundComponent,
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      anchorScrolling: 'enabled',
      onSameUrlNavigation: 'reload',
      scrollPositionRestoration: 'disabled', // TODO: disabled, т.к. используем NgxScrollPositionRestorationModule
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
