import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TuiToggleModule } from '@taiga-ui/kit';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TuiButtonModule } from '@taiga-ui/core';
import { SharedModule } from '@src/app/shared';
import { ComponentsModule } from '@src/app/components';

import { ThemeSwitcherComponent } from './components/theme-switcher/theme-switcher.component';

@NgModule({
  declarations: [ThemeSwitcherComponent],
  imports: [
    CommonModule,
    TuiToggleModule,
    ReactiveFormsModule,
    FormsModule,
    TuiButtonModule,
    SharedModule,
    ComponentsModule,
  ],
  exports: [ThemeSwitcherComponent],
})
export class ThemeSwitcherModule {}
