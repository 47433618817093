import { Component, Input, Output, ChangeDetectionStrategy, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { TuiSizeL, TuiSizeS } from '@taiga-ui/core/types';
import { TuiInputComponent } from '@taiga-ui/kit';

/**
 * Поле поиска. Умеет принимать строку и возвращать строку введенную в поле
 * @param {string} searchQuery - строка
 *
 * @see https://www.figma.com/file/tdtoaaGrQa3XOG9Qqv96VI/Unions-(Taiga-3.0)?type=design&node-id=503-46564&mode=dev
 */
@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchComponent implements OnInit {
  @Input() searchQuery: string = '';
  @Input() size: TuiSizeL | TuiSizeS = 's';
  @Input() autofocus = false;

  @ViewChild(TuiInputComponent)
  private readonly textField?: TuiInputComponent;

  @Output() searchQueryChange: EventEmitter<string> = new EventEmitter();

  ngOnInit() {
    if (this.autofocus) {
      setTimeout(() => this.textField?.nativeFocusableElement?.focus());
    }
  }

  searchQueryChangeHandler(searchQuery: string) {
    this.searchQueryChange.emit(searchQuery);
  }
}
