<div class="settings">
  <div class="settings__header">
    <button
      *ngIf="!showFullPage && selectedItem"
      tuiIconButton
      class="settings__return-button"
      type="button"
      appearance="icon"
      icon="tuiIconArrowLeftLarge"
      size="s"
      [title]="'common.buttons.return' | translate"
      (click)="onClickReturnButton()"
    ></button>
    <h1 class="settings__title">
      {{
        selectedItem && !showFullPage
          ? (selectedItem.title | translate)
          : ('components.navigation.constants.navigationItems.settingsTitle' | translate)
      }}
    </h1>
    <app-hint
      *ngIf="selectedItem && !showFullPage && selectedItem?.hint"
      class="hint"
      [value]="selectedItem.hint ?? ''"
    ></app-hint>
  </div>

  <tui-scrollbar [hide]="!showFullPage" class="settings__list">
    <mat-selection-list [multiple]="false">
      <ng-container *ngFor="let item of items; let i = index">
        <mat-list-option
          *ngxPermissionsOnly="item.permissions || []"
          class="settings-list-item"
          [selected]="selectedItem?.id === item.id"
          (click)="onClickItem(item)"
        >
          <lucide-icon matListIcon class="settings-list-item__icon" [name]="item.icon!"></lucide-icon>
          <div matLine class="settings-list-item__title">{{ item.title | translate }}</div>
          <div *ngIf="item.description" matLine class="settings-list-item__description">
            {{ item.description | translate }}
          </div>
        </mat-list-option>
      </ng-container>
    </mat-selection-list>
  </tui-scrollbar>

  <div class="settings__content">
    <div class="body">
      <div *ngIf="showFullPage && selectedItem?.title" class="body__header">
        <h1 class="body__title">{{ selectedItem?.title ?? '' | translate }}</h1>
        <app-hint *ngIf="selectedItem?.hint" class="hint" [value]="selectedItem?.hint ?? '' | translate"></app-hint>
      </div>

      <tui-scrollbar class="body__content">
        <router-outlet></router-outlet>
      </tui-scrollbar>
    </div>
  </div>
</div>
