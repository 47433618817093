/**
 * Unions API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PhotoDataLoyalty } from './photoDataLoyalty';
import { ContactLoyalty } from './contactLoyalty';
import { DiscountGroupLoyalty } from './discountGroupLoyalty';


/**
 * Добавить продукт программы лояльности – товар / услуга
 */
export interface AddLoyaltyProduct { 
    /**
     * Наименование продукта лояльности.
     */
    name: string;
    /**
     * Описание продукта лояльности.
     */
    description: string;
    /**
     * ID организации продукта.
     */
    organisationId?: string;
    /**
     * ID ассоциации.
     */
    parentOrganisationId?: string;
    /**
     * Информация о компании.
     */
    organisationInfo?: string;
    /**
     * Список групп получателей скидки.
     */
    discountGroups?: Array<DiscountGroupLoyalty>;
    /**
     * Список контактов по продукту.
     */
    contacts?: Array<ContactLoyalty>;
    /**
     * Список фотографий продукта.
     */
    photos?: Array<PhotoDataLoyalty>;
    /**
     * ID категории.
     */
    categoryId?: string;
    /**
     * ID региона.
     */
    regionId?: string;
}

