import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  OnDestroy,
  ChangeDetectorRef,
  Output,
  EventEmitter,
} from '@angular/core';
import { CommitteeService } from '@src/core/services';
import { CommitteeUI } from '@src/models';
import { TuiContextWithImplicit, TuiStringHandler } from '@taiga-ui/cdk';
import { TuiSizeL, TuiSizeS } from '@taiga-ui/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-committee-field',
  templateUrl: './committee-field.component.html',
  styleUrls: ['./committee-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommitteeFieldComponent implements OnInit, OnDestroy {
  // TODO: необходимо научить компонент работать с ReactiveFormsModule (примеры - https://readerstacks.com/create-custom-form-control-in-angular-12/)
  @Input() size: TuiSizeL | TuiSizeS = 'm';
  @Input() multiple: boolean = false;
  @Input() selectedIds: string[] = [];
  @Input() label?: string;
  @Input() disableSelect: string[] = [];
  @Output() selectedIdsChange: EventEmitter<string[]> = new EventEmitter();

  dropdownVisible: boolean = false;
  selectedCommittees?: CommitteeUI[] = [];
  committees$: BehaviorSubject<CommitteeUI[] | null> = this.committeeService.committees$;
  committeesTree$: BehaviorSubject<CommitteeUI[] | null> = this.committeeService.committeesTree$;

  private destroyed$$: Subject<void> = new Subject<void>();

  constructor(private cdr: ChangeDetectorRef, private committeeService: CommitteeService) {}

  ngOnInit(): void {
    this.committees$.pipe(takeUntil(this.destroyed$$)).subscribe(committees => {
      this.selectedCommittees = committees?.filter(committee => {
        return committee.id && this.selectedIds.includes(committee.id);
      });
      this.cdr.markForCheck();
    });

    this.committeeService.startGetCommittees();
    this.committeeService.startGetCommitteesTree();
  }

  ngOnDestroy(): void {
    this.destroyed$$.next();
    this.destroyed$$.complete();
  }

  onChangeActiveZone(value: boolean): void {
    // Нужен только для закрытия dropdown при нажатии вне ActiveZone. Для открытия dropdown используем onClickField()
    if (!value) {
      this.dropdownVisible = false;
    }

    this.cdr.markForCheck();
  }

  onClickField(): void {
    this.dropdownVisible = !this.dropdownVisible;
    this.cdr.markForCheck();
  }

  onChangeSelectedCommittee(selectedCommittees: CommitteeUI[]) {
    let committeeIds: string[] = [];
    selectedCommittees?.forEach(committee => {
      if (committee.id) committeeIds.push(committee.id);
    });
    committeeIds = this.filter(committeeIds, this.disableSelect);

    this.selectedIds = [...committeeIds];

    this.emitSelectedIdsChange(committeeIds);
  }

  filter(source: string[], excludes: string[] = []): string[] {
    return source.reduce((acc: string[], curr) => {
      if (!excludes.includes(curr)) {
        acc.push(curr);
      }
      return acc;
    }, []);
  }

  onChangeSelectedIds(committeeIds: string[]) {
    committeeIds = this.filter(committeeIds, this.disableSelect);

    this.selectedCommittees = [
      ...(this.committees$.value?.filter(committee => committee.id && committeeIds.includes(committee.id)) || []),
    ];

    if (!this.multiple) {
      // Если выбор одиночный - закрываем dropdown при выборе значения
      this.dropdownVisible = false;
    }

    this.selectedIds = [...committeeIds];
    this.emitSelectedIdsChange(committeeIds);
  }

  readonly stringify: TuiStringHandler<any | TuiContextWithImplicit<any>> = item => {
    return 'name' in item ? item.name : item.$implicit.name;
  };

  private emitSelectedIdsChange(committeeIds: string[]) {
    this.selectedIdsChange.emit(committeeIds);
  }
}
