<div class="administration">
  <div></div>
  <div class="administration__list">
    <tui-tabs class="menu" [underline]="false">
      <ng-container *ngFor="let menuItem of menuItems; let i = index">
        <button
          *ngIf="menuItem.allowViewing$ ? (menuItem.allowViewing$ | async) : true"
          tuiTab
          class="menu-item"
          routerLinkActive="menu-item_active"
          [routerLink]="menuItem.link ?? undefined"
        >
          <tui-svg [hide]="!showFullPage" class="menu-item__icon" [src]="menuItem.icon ?? ''"></tui-svg>
          {{ menuItem.title }}
        </button>
      </ng-container>
    </tui-tabs>
  </div>
  <tui-scrollbar class="administration__content">
    <router-outlet></router-outlet>
  </tui-scrollbar>
</div>
