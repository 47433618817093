import {
  Component,
  ChangeDetectionStrategy,
  Input,
  EventEmitter,
  Output,
  OnChanges,
  SimpleChanges,
  ChangeDetectorRef,
} from '@angular/core';
import { OrganisationShortInfoUI } from '@src/app/modules/branding';
import { environment } from '@src/environments/environment';
import { OrganisationView } from '@src/api';

import { NavigationItem } from '../types';

@Component({
  selector: 'app-navigation-desktop',
  templateUrl: './navigation-desktop.component.html',
  styleUrls: ['../styles/navigation.scss', './navigation-desktop.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationDesktopComponent implements OnChanges {
  @Input() isOpen?: boolean;
  @Input() items?: NavigationItem[];
  @Input() parentOrganisations?: OrganisationView[] | null;
  @Input() currentParentOrganisation?: OrganisationView;
  @Input() isMembershipPaid?: boolean;
  @Input() membershipStatus?: string;
  @Input() isBrand?: boolean;
  @Input() brandingData?: OrganisationShortInfoUI | null = null;
  @Output() selectedItemChanged: EventEmitter<NavigationItem>;
  @Output() currentParentOrganisationChanged: EventEmitter<OrganisationView>;

  appVersion = environment.appVersion;

  constructor(private readonly cdr: ChangeDetectorRef) {
    this.selectedItemChanged = new EventEmitter<NavigationItem>();
    this.currentParentOrganisationChanged = new EventEmitter<OrganisationView>();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.items) {
      this.cdr.markForCheck();
    }
  }

  emitCurrentParentOrganisation(selectedParentOrganisation: OrganisationView): void {
    this.currentParentOrganisationChanged.emit(selectedParentOrganisation);
  }
}
