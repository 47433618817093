<div class="send-email">
  <h1 class="send-email__title typography_size_{{ tuiElementLargeSize }}">
    {{ 'components.sendEmailForm.labels.feedbackForm' | translate }}
  </h1>

  <form class="send-email__form" [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="send-email__form-fields">
      <tui-input tuiAutoFocus maxlength="100" formControlName="subject" [tuiTextfieldSize]="tuiElementLargeSize">
        {{ 'components.sendEmailForm.fields.subject' | translate }}
      </tui-input>

      <tui-textarea
        [rows]="10"
        [maxLength]="1000"
        [expandable]="true"
        formControlName="message"
        [tuiTextfieldSize]="tuiElementLargeSize"
      >
        {{ 'components.sendEmailForm.fields.description' | translate }}
        <span class="tui-required"></span>
      </tui-textarea>

      <tui-input formControlName="email" [tuiTextfieldSize]="tuiElementLargeSize">
        {{ 'components.sendEmailForm.fields.sendersEmail' | translate }}
        <span class="tui-required"></span>
      </tui-input>

      <div class="send-email__form-input-files-wrapper">
        <tui-input-files
          class="size_{{ tuiElementLargeSize }}"
          style="width: 100%"
          [maxFileSize]="maxFileSize"
          [formControl]="files"
          (reject)="onReject($event)"
          (ngModelChange)="files.markAsTouched()"
        >
          <input tuiInputFiles [multiple]="true" [accept]="acceptFileMask" />
        </tui-input-files>

        <button
          tuiIconButton
          icon="tuiIconTrash2"
          type="button"
          appearance="outline"
          [tuiHint]="'components.sendEmailForm.buttons.deleteAllFiles' | translate"
          (click)="clearAllFiles()"
          [size]="tuiElementLargeSize"
          [disabled]="isLoading || (files.value?.length ?? 0) === 0"
        ></button>
      </div>

      <tui-files>
        <tui-file
          [ngClass]="{
            'hide-remove-button disabled': isLoading,
            size_m: tuiElementLargeSize === 'm'
          }"
          *ngFor="let file of files.valueChanges | async"
          [file]="file"
          (removed)="removeFile(file)"
        ></tui-file>

        <tui-file
          [ngClass]="{
            'hide-remove-button disabled': isLoading,
            size_m: tuiElementLargeSize === 'm'
          }"
          *ngFor="let file of rejectedFiles"
          state="error"
          [file]="file"
          (removed)="clearRejected(file)"
        ></tui-file>
      </tui-files>

      <tui-error [formControl]="files" [error]="['maxLength'] | tuiFieldError | async"></tui-error>
    </div>

    <div class="send-email__form-actions">
      <button
        tuiButton
        icon="tuiIconMail"
        [showLoader]="isLoading"
        type="submit"
        appearance="primary"
        [size]="tuiElementLargeSize"
      >
        {{ 'common.buttons.send' | translate }}
      </button>
    </div>
  </form>

  <ng-template #successTemplate>
    <p>{{ 'components.sendEmailForm.dialogs.feedbackSuccessFirstLineLabel' | translate }}</p>
    <p>{{ 'components.sendEmailForm.dialogs.feedbackSuccessSecondLineLabel' | translate }}</p>
  </ng-template>
</div>
