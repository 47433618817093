import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject, throwError } from 'rxjs';
import { catchError, map, takeUntil } from 'rxjs/operators';
import {
  CommitteeSubscriptionView,
  AddCommitteeSubscriptionWithSendTo,
  CommitteeSubscriptionsService,
  EditCommitteeSubscriptionWithSendTo,
} from '@src/api';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionsForCommitteeService implements OnDestroy {
  subscription$: BehaviorSubject<CommitteeSubscriptionView | null>;
  subscriptions$: BehaviorSubject<CommitteeSubscriptionView[] | null>;

  private destroyed$$: Subject<void> = new Subject<void>();

  constructor(private committeeSubscriptionsService: CommitteeSubscriptionsService) {
    this.subscription$ = new BehaviorSubject<CommitteeSubscriptionView | null>(null);
    this.subscriptions$ = new BehaviorSubject<CommitteeSubscriptionView[] | null>(null);
  }

  ngOnDestroy(): void {
    this.destroyed$$.next();
    this.destroyed$$.complete();
  }

  getSubscription(id: string): void {
    this.committeeSubscriptionsService
      .getCommitteeSubscription(id)
      .pipe(
        catchError(err => {
          // TODO show error notification
          return throwError(err);
        }),
        takeUntil(this.destroyed$$),
      )
      .subscribe(subscription => this.subscription$.next(subscription));
  }

  getSubscriptions(committeeId: string, callback?: (value: boolean) => void): void {
    callback?.(true);

    this.committeeSubscriptionsService
      .getCommitteeSubscriptionList(committeeId)
      .pipe(
        catchError(err => {
          // TODO show error notification
          callback?.(false);
          return throwError(err);
        }),
        map(subscriptions => this.sortingSubscriptions(subscriptions)),
        takeUntil(this.destroyed$$),
      )
      .subscribe(subscriptions => {
        this.subscriptions$.next(subscriptions);
        callback?.(false);
      });
  }

  addSubscriptions(subscriptionsData: AddCommitteeSubscriptionWithSendTo[]) {
    return this.committeeSubscriptionsService.addCommitteeSubscriptionsList(subscriptionsData);
  }

  editSubscription(subscriptionData: EditCommitteeSubscriptionWithSendTo) {
    return this.committeeSubscriptionsService.editCommitteeSubscriptionsList(subscriptionData);
  }

  deleteSubscription(subscriptionId: string) {
    return this.committeeSubscriptionsService.deleteCommitteeSubscription(subscriptionId);
  }

  resetSubscription() {
    this.subscription$.next(null);
  }

  resetSubscriptions() {
    this.subscriptions$.next(null);
  }

  resetAll() {
    this.resetSubscription();
    this.resetSubscriptions();
  }

  private sortingSubscriptions(subscriptions: CommitteeSubscriptionView[]): CommitteeSubscriptionView[] {
    subscriptions.sort((subscription1, subscription2) => {
      const subscriptionName1 = subscription1.name?.toLowerCase();
      const subscriptionName2 = subscription2.name?.toLowerCase();

      if (!subscriptionName1) return 1;
      if (!subscriptionName2) return -1;

      if (subscriptionName1 < subscriptionName2) return -1;
      if (subscriptionName1 > subscriptionName2) return 1;
      return 0;
    });

    return subscriptions;
  }
}
