import { AnswerOption, EditQuestion, PollAdd, PollWithUserDecisionView } from '../api';

import { TuiFileLikeUI } from './index';

export interface PollUI extends PollAdd, PollWithUserDecisionView {
  photo?: File;
  newDocuments?: File[];
  deleteDocuments?: TuiFileLikeUI[];
  active?: boolean;
  notifyEveryone?: boolean;
}

export interface QuestionFormType extends EditQuestion {
  answerType?: AnswerType;
  answers?: AnswerOption[];
}

export interface AnswerType {
  id: number;
  type: string;
  caption: string;
  icon?: string;
}
