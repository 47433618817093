<app-poll-info-view
  *ngIf="mode === 'view'"
  [data]="data$ | async"
  [documentsList]="documents$ | async"
  [membersList]="membersList$ | async"
  [allowEditing]="allowEditing$ | async"
  [allowMembersViewing]="allowMembersViewing$ | async"
  [allowStatisticsViewing]="allowStatisticsViewing$ | async"
  [externalLoading]="loading"
  [recallButtonLoading]="recallButtonLoading"
  (startEditing)="onStartEditing()"
  (startPoll)="startPoll()"
  (copyPoll)="onCopyPoll()"
  (declinePoll)="declinePoll()"
  (deletePoll)="deletePoll($event)"
  (statistics)="getStatistics()"
  (recallMembers)="resendNotifications($event)"
></app-poll-info-view>

<app-poll-info-edit
  *ngIf="mode === 'create' || mode === 'edit'"
  [mode]="mode"
  [data]="data$ | async"
  [oldDocuments]="tuiFiles$ | async"
  [membersList]="membersList$ | async"
  [externalLoading]="loading"
  (saved)="onSaveData($event)"
  (canceled)="onCancel()"
></app-poll-info-edit>

<app-poll-start
  *ngIf="mode === 'execute'"
  [data]="pollQuestions"
  [pollData]="data$ | async"
  [documentsList]="documents$ | async"
  (stopPoll)="stopPoll()"
></app-poll-start>

<ng-template #confirmEditDialogTemplate let-observer>
  <tui-checkbox-labeled class="tui-space_bottom-3 margin-auto" size="m" [(ngModel)]="notifyEveryone">
    {{ 'components.pollInfo.dialogs.notifyEveryoneLabel' | translate }}
  </tui-checkbox-labeled>
  <button tuiButton type="button" size="s" class="tui-space_top-3" (click)="observer.complete()">
    {{ 'components.pollInfo.buttons.confirm' | translate }}
  </button>
</ng-template>
