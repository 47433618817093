import { Injectable } from '@angular/core';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { OrganisationSettingsService, OrganisationSettingView } from '@src/api';
import { UserUI } from '@src/models';

@Injectable({
  providedIn: 'root',
})
export class PreferencesService {
  settings$: BehaviorSubject<OrganisationSettingView | undefined> = new BehaviorSubject<
    OrganisationSettingView | undefined
  >(undefined);

  private items: OrganisationSettingView = { showParentOrgUsersInLists: false, showOnlyChannelsInNewsFeed: false };

  private orgId?: string;

  constructor(private api: OrganisationSettingsService) {}

  get settings() {
    return this.items;
  }

  getData(user?: UserUI) {
    this.orgId = user?.parentOrganisationId;

    return this.refresh();
  }

  async refresh(): Promise<OrganisationSettingView> {
    if (this.orgId) {
      this.items = await lastValueFrom(this.api.organisationSettingsByOrganisationId(this.orgId));
      this.items.showParentOrgUsersInLists ??= true;
      this.items.showOnlyChannelsInNewsFeed ??= false;
    }

    this.settings$.next(this.items);

    return this.settings;
  }
}
