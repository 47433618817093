import { NotificationEventsOptionsModel } from '@src/api';
import { AbstractControl } from '@angular/forms';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';

export type FormDataControls = { [key in keyof NotificationEventsOptionsModel]: AbstractControl };
export type SendByNotification = {
  code: string;
  default?: boolean;
  title: string;
  showOnlyFor?: string[]; // TODO: Убрать, когда будут реализованы пуши для рассылок и ПЛ на бэке
  description?: string;
};

export const SEND_BY_NOTIFICATIONS: SendByNotification[] = [
  {
    code: 'sendByDefault',
    default: true,
    title: marker('components.notifications.constants.sendByDefaultTitle'),
    description: marker('components.notifications.constants.sendByDefaultDescription'),
  },
  {
    code: 'sendByEmail',
    title: marker('components.notifications.constants.sendByEmailTitle'),
  },
  {
    code: 'sendByPush',
    showOnlyFor: ['onEventChanged', 'onPollChanged'],
    title: marker('components.notifications.constants.sendByPushTitle'),
  },
  {
    code: 'sendByBot',
    title: marker('components.notifications.constants.sendByBotTitle'),
  },
];
