<tui-input
  class="search _no-hover"
  [tuiTextfieldSize]="size"
  [tuiTextfieldCleaner]="true"
  [tuiTextfieldLabelOutside]="true"
  [ngModel]="searchQuery"
  (ngModelChange)="searchQueryChangeHandler($event)"
>
  <tui-svg src="tuiIconSearch"></tui-svg>
  {{ 'common.fields.search' | translate }}
</tui-input>
