<app-navigation-desktop
  *ngIf="isDesktop; else mobileNavigation"
  [isOpen]="isOpen"
  [items]="displayedNavItems"
  [parentOrganisations]="parentOrganisations$ | async"
  [currentParentOrganisation]="currentParentOrganisation"
  [isMembershipPaid]="isMembershipPaid"
  [membershipStatus]="membershipStatus | translate"
  [isBrand]="isBrand"
  [brandingData]="brandingData$ | async"
  (selectedItemChanged)="onChangeSelectedItem($event)"
  (currentParentOrganisationChanged)="onChangeCurrentParentOrganisation($event)"
></app-navigation-desktop>

<ng-template #mobileNavigation>
  <app-navigation-mobile
    [items]="displayedNavItems"
    [parentOrganisations]="parentOrganisations$ | async"
    [currentParentOrganisation]="currentParentOrganisation"
    [isMembershipPaid]="isMembershipPaid"
    [membershipStatus]="membershipStatus | translate"
    [isBrand]="isBrand"
    (selectedItemChanged)="onChangeSelectedItem($event)"
    (currentParentOrganisationChanged)="onChangeCurrentParentOrganisation($event)"
  ></app-navigation-mobile>
</ng-template>
