import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule } from '@angular/forms';
import { TuiButtonModule, TuiLoaderModule, TuiTextfieldControllerModule } from '@taiga-ui/core';
import { TuiInputModule, TuiTextareaModule } from '@taiga-ui/kit';
import { TranslateModule } from '@ngx-translate/core';

import { ListModule } from '../list';

import { SearchChatsComponent } from './search-chats.component';

@NgModule({
  declarations: [SearchChatsComponent],
  imports: [
    CommonModule,
    FormsModule,
    ListModule,
    MatButtonModule,
    MatIconModule,
    TuiButtonModule,
    TuiInputModule,
    TuiLoaderModule,
    TuiTextareaModule,
    TuiTextfieldControllerModule,
    TranslateModule,
  ],
  exports: [SearchChatsComponent],
})
export class SearchChatsModule {}
