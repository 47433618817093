import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

import { LegendItemSize, LegendItemType } from './types';

/**
 * Компонент для отображения легенды
 * @param items {@link LegendItemType} - Массив значений
 * @param size {@link LegendItemSize} - Размер
 */
@Component({
  selector: 'app-legend',
  templateUrl: './legend.component.html',
  styleUrls: ['./legend.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LegendComponent {
  @Input() items: LegendItemType[] = [];
  @Input() size: LegendItemSize = 's';
}
