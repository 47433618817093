<a class="logo__wrapper" routerLink="/">
  <div class="logo__layout">
    <app-branding-logo class="logo__image" [width]="32" [height]="32"></app-branding-logo>
  </div>
</a>

<nav class="navigation">
  <ng-container *ngFor="let item of items">
    <ng-container *ngxPermissionsOnly="item.permissions || []">
      <ng-container *tuiLet="item.badge | async as badgeValue">
        <tui-badged-content [colorTop]="badgeValue ? '#42befea6' : ''" size="s" [contentTop]="badgeValue">
          <a
            class="navigation-item navigation-item_active"
            [routerLink]="item.link"
            [class.navigation-item_active]="item.selectedItem"
            (click)="item.action && item.action(item.id)"
          >
            <lucide-icon
              *ngIf="item.icon"
              class="navigation-item__icon navigation-item__icon_name_{{ item.icon }}"
              [name]="item.icon"
            ></lucide-icon>
            <p class="navigation-item__title">
              {{ item.title | translate }}
            </p>
          </a>
        </tui-badged-content>
      </ng-container>
    </ng-container>
  </ng-container>
</nav>

<div class="right-menu">
  <div
    *ngIf="currentParentOrganisation && membershipStatus"
    class="message"
    [ngClass]="isMembershipPaid ? 'message_info' : 'message_warning'"
    tuiHintDescribe="message"
    tuiHintDirection="right"
    [tuiHint]="membershipStatus"
    [tuiHintShowDelay]="300"
    [tuiHintHideDelay]="0"
  >
    <lucide-icon class="message__icon" name="alert-circle"></lucide-icon>
  </div>

  <tui-hosted-dropdown
    *ngIf="!isBrand && currentParentOrganisation"
    class="association-menu__wrap"
    [content]="associationMenuTmpl"
    [sided]="true"
  >
    <div class="association-menu">
      <tui-avatar
        class="association-menu__icon"
        size="s"
        [autoColor]="!currentParentOrganisation.photoId"
        [avatarUrl]="currentParentOrganisation.photoId | getImageSrc"
        [text]="currentParentOrganisation.shortName ? currentParentOrganisation.shortName : ''"
      ></tui-avatar>
    </div>
  </tui-hosted-dropdown>
</div>

<ng-template #associationMenuTmpl>
  <tui-data-list *ngIf="parentOrganisations">
    <tui-opt-group [label]="'components.navigation.labels.associationMenu' | translate">
      <button
        *ngFor="let parentOrganisation of parentOrganisations"
        tuiOption
        class="association-menu-item"
        (click)="emitCurrentParentOrganisation(parentOrganisation)"
      >
        <tui-badged-content
          size="xs"
          [colorTop]="parentOrganisation.id === currentParentOrganisation?.id ? 'var(--tui-success-fill)' : ''"
        >
          <tui-avatar
            class="association-menu-item__icon"
            size="xs"
            [autoColor]="!parentOrganisation.photoId"
            [avatarUrl]="parentOrganisation.photoId | getImageSrc"
            [text]="parentOrganisation.shortName ? parentOrganisation.shortName : ''"
          ></tui-avatar>
        </tui-badged-content>
        <span class="association-menu-item__title">
          {{ parentOrganisation.shortName ? parentOrganisation.shortName : '' }}
        </span>
      </button>
    </tui-opt-group>
  </tui-data-list>
</ng-template>
