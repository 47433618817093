import { ViewLoyaltyProduct } from '@src/api';

import { LoyaltyRating, LoyaltyStatus } from '../types';
import {
  LoyaltyProgramCategory,
  LoyaltyProgramProduct,
  LoyaltyProgramProductDiscountType,
  LoyaltyProgramProductImage,
  LoyaltyProgramProductPerson,
  LoyaltyProgramProductTerms,
  LoyaltyProgramRegion,
} from '../../models';
import { LOYALTY_GRADES_MAP, LOYALTY_STATUSES_MAP } from '../constants';

export const convertToProduct = (item: ViewLoyaltyProduct): LoyaltyProgramProduct => {
  const coverImageId = item.photos?.find(item => item.isCover)?.id ?? item.photos?.[0]?.id;

  const images = [...(item.photos ?? [])].sort((a, b) => (a?.sortOrder ?? 0) - (b?.sortOrder ?? 0));

  return {
    id: item.id,
    // TODO: нужно описание, как вычислять это поле
    isNew: false,
    grade: LOYALTY_GRADES_MAP[item.rating as LoyaltyRating],
    status: LOYALTY_STATUSES_MAP[item.status as LoyaltyStatus],
    // @ts-ignore Валера не прописал данное поле в схеме
    createdBy: item['createdBy'],
    name: item.name,
    description: item.description,
    eridAdInfo: item.eridAdInfo,
    eridToken: item.eridToken,
    categories: item.categories.map<LoyaltyProgramCategory>(category => ({
      id: category.id,
      name: category.name ?? '',
      parent: { id: category.categoryGroupId ?? '', name: category.categoryGroupName ?? '' },
    })),
    regions:
      item.regions?.length === 0
        ? undefined
        : item.regions?.map<LoyaltyProgramRegion>(region => ({
            id: region.id,
            name: region.name ?? '',
          })),
    images: images.map<LoyaltyProgramProductImage>(({ id, name, isCover }) => ({ id, file: name ?? '', isCover })),
    coverImageId,
    // TODO: пока не отображаем
    usedCount: undefined,
    contacts: {
      company: {
        id: item.organisationId ?? item.externalOrganisationId,
        name: item.organisationShortName ?? item.externalOrganisationName ?? '',
        logoId: item.organisationLogoId ?? item.externalOrganisationLogoId,
        info: item.organisationInfo,
      },
      mentor: item.contactUserId
        ? {
            id: item.contactUserId,
            // @ts-ignore поле не описано в схеме
            name: item.contactUserFIO ?? '',
          }
        : undefined,
      persons:
        item.contacts?.map<LoyaltyProgramProductPerson>(contact => ({
          ...contact,
          address: contact.address ?? '',
        })) ?? [],
    },
    terms:
      item.discountGroups?.map<LoyaltyProgramProductTerms>(group => {
        let discountType: LoyaltyProgramProductDiscountType = 'rate';

        if (group.discountAbsolute) {
          discountType = 'cash';
        } else if (group.discountText) {
          discountType = 'tag';
        }

        return {
          id: group.id,
          discountType,
          discount: group.discountRate ?? group.discountAbsolute ?? group.discountText ?? 0,
          description: group.discountOptions ?? '',
          promoCode: group.discountCode,
          showAsQRCode: group.generateQRCode,
          qrCodeImage: group.discountQRCodeFileId
            ? {
                id: group.discountQRCodeFileId,
                file: 'Изображение с QR кодом',
              }
            : undefined,
          jobs: group.jobTitles?.map(job => ({ id: job.jobTitleId, name: job.description ?? '' })) ?? [],
        };
      }) ?? [],
    unionId: !!item.externalOrganisationId ? item.parentOrganisationId : undefined,
    cost: {
      requestLeadContacts: item.requestLeadContacts,
      // TODO: остальное заполняется в CostStepComponent
    },
  };
};
