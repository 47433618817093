import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Injector } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BreakpointObserver } from '@angular/cdk/layout';
import { takeUntil } from 'rxjs/operators';
import { TuiDialogService } from '@taiga-ui/core';
import { PolymorpheusComponent } from '@tinkoff/ng-polymorpheus';
import { BreakpointObserverHelperService } from '@src/core/services';
import { LogoutService } from '@src/app/modules/auth';
import { EnvService } from '@src/app/modules/env';
import { ResizableBaseComponent } from '@src/app/components/resizable-base-component';
import { DialogConfirmComponent } from '@src/app/shared/dialogs';
import { TranslateService } from '@ngx-translate/core';

import { NavigationItem, SETTINGS_NAVIGATION, NAVIGATION_ITEMS } from '../navigation';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SettingsComponent extends ResizableBaseComponent {
  items: NavigationItem[];
  selectedItem?: NavigationItem;

  private readonly confirmDialog = this.dialogService.open<boolean>(
    new PolymorpheusComponent(DialogConfirmComponent, this.injector),
    {
      label: this.translateService.instant('common.dialogs.logOutHeader'),
      size: 's',
      closeable: false,
    },
  );

  constructor(
    readonly cdr: ChangeDetectorRef,
    readonly breakpointObserver: BreakpointObserver,
    readonly breakpointObserverHelperService: BreakpointObserverHelperService,
    private router: Router,
    private logoutService: LogoutService,
    private env: EnvService,
    private readonly translateService: TranslateService,
    @Inject(TuiDialogService) private readonly dialogService: TuiDialogService,
    @Inject(Injector) private readonly injector: Injector,
  ) {
    super(cdr, breakpointObserver, breakpointObserverHelperService);

    this.items = NAVIGATION_ITEMS.filter(
      navigationItem =>
        SETTINGS_NAVIGATION.filter(
          settingsNavigationItem => !(this.env.isBot && settingsNavigationItem.itemName === 'menu.folders'),
        ).findIndex(settingsNavigationItem => settingsNavigationItem.itemName === navigationItem.id) > -1,
    ).map(item => {
      if (item.id === 'menu.logout') {
        item.action = () => this.onClickExitButton();
      }

      return item;
    });

    this.router.events.pipe(takeUntil(this.destroyed$$)).subscribe(routerEvent => {
      if (routerEvent instanceof NavigationEnd) {
        this.selectedItem = this.items.find(item => item.link && routerEvent.url.includes(item.link));
        this.cdr.markForCheck();
      }
    });
  }

  get showFullPage(): boolean {
    return !this.selectedItem || this.isLargeScreen;
  }

  onClickReturnButton() {
    this.selectedItem = undefined;
    this.router.navigate(['settings']);
  }

  onClickItem(item: NavigationItem): void {
    item.action && item.action();

    if (item.link) {
      item.active = true;
      this.selectedItem = item;
      this.router.navigate([this.selectedItem.link]);
    }
  }

  onClickExitButton(): void {
    this.confirmDialog.pipe(takeUntil(this.destroyed$$)).subscribe({
      next: res => {
        if (res) {
          this.logoutService.logout();
        }
      },
    });
  }
}
