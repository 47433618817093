<div class="search-users-dialog">
  <app-search size="m" (searchQueryChange)="onChangeSearchQuery($event)" [autofocus]="true"></app-search>

  <search-users-filters (filtersChange)="onFiltersChange($event)"></search-users-filters>

  <ng-container *ngIf="items$ | async as items">
    <div class="search-users-dialog__title">
      <span class="tui-text_body-s" [style.color]="'var(--tui-text-02)'">
        {{ loading ? ('components.searchUsers.labels.loading' | translate) : title }}
      </span>

      <select-all-link
        *ngIf="mode === 'multiple' && items.length > 0 && !loading"
        [mode]="addOrRemoveItemsMode"
        (clickChange)="onAddOrRemoveItemsToResult()"
      ></select-all-link>
    </div>
  </ng-container>

  <tui-loader
    *ngIf="loading"
    size="l"
    [showLoader]="true"
    [inheritColor]="true"
    [overlay]="true"
    class="search-users-dialog__loading"
  ></tui-loader>

  <ng-container *ngIf="!loading">
    <ng-container *ngIf="items$ | async as items">
      <div *ngIf="!items.length" class="search-users-dialog__no-data">
        <span class="tui-text_body-m">
          {{ noDataMessage }}
        </span>
      </div>

      <tui-scrollbar *ngIf="!!items.length" class="search-users-dialog__wrapper">
        <div class="search-users-dialog__wrapper_list">
          <div *ngFor="let item of items">
            <button [id]="item.id" class="search-users-dialog__wrapper_item" (click)="onToggleItem(item)">
              <render-user-row [user]="item" [statusContent]="statusContent"></render-user-row>

              <tui-primitive-checkbox
                size="l"
                *ngIf="mode === 'multiple'"
                [value]="isChecked(item)"
                [style.background-color]="'var(--unions-color-status-disabled)'"
              ></tui-primitive-checkbox>
              <tui-svg [src]="'tuiIconCheck'" *ngIf="mode === 'single' && isChecked(item)"></tui-svg>
            </button>

            <div class="search-users-dialog__wrapper_divider"></div>
          </div>
        </div>
      </tui-scrollbar>
    </ng-container>
  </ng-container>

  <div class="search-users-dialog__footer">
    <ng-container *ngIf="result$ | async as items">
      <ng-container *ngIf="items.length > 0">
        <div class="search-users-dialog__result-count tui-text_body-s">
          {{ resultTitle }}
        </div>

        <users-tag
          [items]="items"
          (removeItem)="onRemoveItem($event)"
          (removeAllItems)="onRemoveAllItems()"
        ></users-tag>
      </ng-container>
    </ng-container>

    <button
      tuiButton
      type="button"
      [size]="'m'"
      (click)="onSave()"
      class="search-users-dialog__action"
      [disabled]="!(result$ | async)?.length"
    >
      {{ 'common.buttons.save' | translate }}
    </button>
  </div>
</div>
