import { Component, ChangeDetectionStrategy, ChangeDetectorRef, Inject, TemplateRef, OnDestroy } from '@angular/core';
import { UserService } from '@src/core/services';
import { POLYMORPHEUS_CONTEXT } from '@tinkoff/ng-polymorpheus';
import { TuiDialogContext } from '@taiga-ui/core';
import { UsersSearchParameters } from '@src/api';
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, takeUntil, tap } from 'rxjs/operators';
import { UserUI } from '@src/models';
import { NumeralPluralsPipe } from '@src/app/shared/pipes';
import { Optional } from '@src/types/utils';
import { TranslateService } from '@ngx-translate/core';

import { addOrRemoveItemsToResult } from '../../utils/addOrRemoveItemsToResult';
import { checkAddOrRemoveItemsStatus } from '../../utils/checkAddOrRemoveItemsStatus';
import { FilterParams } from '../search-users-filters';
import { SearchUsersProps, SearchUsersMode, FilterItem } from '../../types';
import { DEFAULT_USERS_SEARCH_PARAMS } from '../../constants';
import { SelectAllLinkMode } from '../select-all-link';

@Component({
  templateUrl: './search-users-dialog.component.html',
  styleUrls: ['./search-users-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchUsersDialogComponent implements OnDestroy {
  loading = true;
  title = this.generateTitle(0);
  resultTitle = this.generateTitle(0, true);
  addOrRemoveItemsMode: SelectAllLinkMode = 'select-all';

  readonly items$ = new BehaviorSubject<UserUI[]>([]);
  readonly result$ = new BehaviorSubject<UserUI[]>([]);
  readonly searchQuery$ = new BehaviorSubject<UsersSearchParameters>(DEFAULT_USERS_SEARCH_PARAMS);

  private firstRender = false;
  private readonly destroyed$$: Subject<void> = new Subject<void>();

  constructor(
    readonly cdr: ChangeDetectorRef,
    @Inject(POLYMORPHEUS_CONTEXT)
    private readonly context: TuiDialogContext<Optional<UserUI[]>, SearchUsersProps>,
    private readonly userService: UserService,
    private readonly plural: NumeralPluralsPipe,
    private readonly translateService: TranslateService,
  ) {
    const result = this.context.data.items?.slice() ?? [];
    this.result$.next(result);

    const filter = {
      ...DEFAULT_USERS_SEARCH_PARAMS,
      ...this.context.data.filter,
    };
    this.searchQuery$.next(filter);

    let singleItemId = '';
    if (this.mode === 'single' && result.length === 1) {
      singleItemId = result[0].id!;
    }

    this.searchQuery$
      .pipe(
        tap(() => (this.loading = true)),
        debounceTime(300),
        takeUntil(this.destroyed$$),
      )
      .subscribe(query => {
        this.cdr.markForCheck();

        this.userService
          .searchUsers(query)
          .pipe(takeUntil(this.destroyed$$))
          .subscribe(items => {
            this.items$.next(this.getFilteredUsers(items));
            this.loading = false;
            this.cdr.markForCheck();

            if (!this.firstRender) {
              this.firstRender = true;

              if (singleItemId) {
                setTimeout(() => {
                  const el = document.getElementById(singleItemId);
                  el?.scrollIntoView({ block: 'center' });
                });
              }
            }
          });
      });

    this.items$.pipe(takeUntil(this.destroyed$$)).subscribe(items => {
      this.checkAddOrRemoveItemsStatus();
      this.title = this.generateTitle(items.length);
    });

    this.result$.pipe(takeUntil(this.destroyed$$)).subscribe(items => {
      this.checkAddOrRemoveItemsStatus();
      this.resultTitle = this.generateTitle(items.length, true);
    });
  }

  ngOnDestroy() {
    this.destroyed$$.next();
    this.destroyed$$.complete();
  }

  get mode(): SearchUsersMode {
    return this.context.data.mode;
  }

  get statusContent(): Optional<TemplateRef<UserUI>> {
    return this.context.data.statusContent;
  }

  get noDataMessage(): string {
    const name = this.searchQuery$.value.name;

    if (name) {
      return this.translateService.instant('components.searchUsers.labels.searchEmptyResult', { value: name });
    }

    return this.translateService.instant('components.searchUsers.labels.personsFilterEmptyResult');
  }

  isChecked(item: UserUI): boolean {
    return this.result$.value.some(({ id }) => item.id === id);
  }

  onSave() {
    const prev = this.context.data.items ?? [];
    const result = this.result$.value.slice();

    let isPossibleToSave;
    if (prev.length !== result.length) {
      isPossibleToSave = true;
    } else {
      isPossibleToSave = prev.some(prevItem => !result.find(resultItem => resultItem.id === prevItem.id));
    }

    if (isPossibleToSave) {
      this.context.completeWith(result);
    } else {
      // TODO: закрываем диалог и передаем undefined, т.к. изменений не было
      this.context.completeWith(undefined);
    }
  }

  onToggleItem(item: UserUI) {
    if (this.mode === 'single') {
      if (this.result$.value[0]?.id !== item.id) {
        this.result$.next([{ ...item }]);
      }

      return;
    }

    const result = this.result$.value.slice();

    const index = result.findIndex(({ id }) => id === item.id);
    if (index !== -1) {
      result.splice(index, 1);
    } else {
      result.push({ ...item });
    }

    this.result$.next(result);
  }

  onChangeSearchQuery(query: string) {
    this.searchQuery$.next({ ...this.searchQuery$.value, name: query });
  }

  onFiltersChange(filters: FilterParams) {
    const prev = this.searchQuery$.value;
    const payload: Partial<UsersSearchParameters> = {
      jobTitleIds: this.getFilterItemIds(filters.jobs),
      committeeIds: this.getFilterItemIds(filters.committees),
      organisationIds: this.getFilterItemIds(filters.organizations),
      organisationTypeIds: this.getFilterItemIds(filters.businessTypes),
    };

    this.searchQuery$.next({ ...prev, ...payload, name: prev.name });
  }

  onRemoveItem(item: UserUI) {
    const result = this.result$.value;

    this.result$.next(result.filter(({ id }) => id !== item.id));
  }

  onRemoveAllItems() {
    this.result$.next([]);
  }

  onAddOrRemoveItemsToResult() {
    const items = this.items$.value;
    const result = this.result$.value;

    this.result$.next(addOrRemoveItemsToResult(this.addOrRemoveItemsMode, items, result));
  }

  private checkAddOrRemoveItemsStatus() {
    const items = this.items$.value;
    const result = this.result$.value;

    this.addOrRemoveItemsMode = checkAddOrRemoveItemsStatus(items, result);
  }

  private generateTitle(length: number, isResultTitle = false) {
    const group = isResultTitle ? 'selectedPersons' : 'foundPersons';

    return this.plural.transform(
      length,
      [
        this.translateService.instant(`components.searchUsers.labels.${group}One`, { count: length }),
        this.translateService.instant(`components.searchUsers.labels.${group}Few`, { count: length }),
        this.translateService.instant(`components.searchUsers.labels.${group}Many`, { count: length }),
      ],
      true,
    );
  }

  private getFilterItemIds(items: FilterItem[]): string[] {
    return items.map(({ id }) => id);
  }

  private getFilteredUsers(users: UserUI[]) {
    if (this.context.data.excludeUsersIds?.length) {
      users = users.filter(user => user.id && !this.context.data.excludeUsersIds?.includes(user.id));
    }

    if (this.context.data.excludeUsersTelegramIds?.length) {
      users = users.filter(user => {
        if (!user.telegramId) return true;

        return !this.context.data.excludeUsersTelegramIds?.includes(user.telegramId);
      });
    }

    return users;
  }
}
