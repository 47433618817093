/**
 * Unions API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AddQuestion } from './addQuestion';


/**
 * Добавить опрос.
 */
export interface PollAdd { 
    /**
     * Название.
     */
    titleText?: string;
    /**
     * Описание.
     */
    descriptionText?: string;
    /**
     * Ссылка на анкету.
     */
    pollLink?: string;
    /**
     * Дата начала опроса.
     */
    dateStart?: string;
    /**
     * Дата окончания опроса.
     */
    dateEnd?: string;
    /**
     * Время прохождения опроса.
     */
    pollDuration?: number;
    /**
     * Создал мероприятие.
     */
    createdBy?: string;
    /**
     * Идентификатор ассоциации.
     */
    organisationId?: string;
    /**
     * Идентификатор комитета.
     */
    committeeId?: string;
    /**
     * Идентификаторы участников для участия в опросе.
     */
    sendTo?: Array<string>;
    /**
     * Список вариантов ответов
     */
    questions?: Array<AddQuestion>;
    /**
     * Текст приглашения.
     */
    inviteText?: string;
}

