<ng-container *ngIf="!!value">
  <app-label [label]="label">
    <tui-primitive-textfield
      #parentActiveZone="tuiActiveZone"
      [invalid]="computedInvalid"
      [focusable]="focusable"
      [disabled]="disabled"
      [readOnly]="readOnly"
      [value]="value.title!"
      [tuiTextfieldIcon]="showVisibilityOption ? iconContent : null"
      (valueChange)="onValueChange($event)"
      (tuiActiveZoneChange)="activeZoneChange($event)"
      [tuiTextfieldLabelOutside]="true"
      (focusedChange)="onFocused($event)"
      [pseudoHover]="false"
    >
      <ng-content></ng-content>
      <input tuiTextfield type="text" />
    </tui-primitive-textfield>
    <div *ngIf="showVisibilityOption" class="visibility-hint">{{ visibility | translate }}</div>

    <ng-template #iconContent>
      <tui-hosted-dropdown [tuiDropdownAlign]="'left'" [content]="showFor" [(open)]="open">
        <tui-svg class="icon" [src]="'tuiIconEyeLarge'"></tui-svg>
      </tui-hosted-dropdown>
    </ng-template>

    <ng-template #showFor>
      <div class="visibility">
        <tui-data-list [tuiActiveZoneParent]="parentActiveZone">
          <p class="visibility__header">{{ 'components.customName.constants.visibilityOptions.title' | translate }}</p>
          <button
            *ngFor="let visibility of visibilities"
            tuiOption
            (click)="changeVisibility(visibility.value)"
            [value]="visibility.value"
          >
            {{ visibility.name | translate }}
            <tui-svg *ngIf="visibility.value === value.visibilityOption" src="tuiIconCheck"></tui-svg>
          </button>
        </tui-data-list>
      </div>
    </ng-template>
  </app-label>
</ng-container>
