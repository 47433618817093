import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import {
  TuiCheckboxBlockModule,
  TuiCheckboxModule,
  TuiDataListWrapperModule,
  TuiInputModule,
  TuiInputDateModule,
  TuiInputDateTimeModule,
  TuiInputNumberModule,
  TuiInputTimeModule,
  TuiIslandModule,
  TuiRadioBlockModule,
  TuiRadioModule,
  TuiSelectModule,
  TuiSliderModule,
  TuiTextareaModule,
  TuiMultiSelectModule,
  TuiInputFilesModule,
  TuiInputSliderModule,
  TuiCheckboxLabeledModule,
} from '@taiga-ui/kit';
import {
  TuiButtonModule,
  TuiDataListModule,
  TuiGroupModule,
  TuiLoaderModule,
  TuiScrollbarModule,
  TuiTextfieldControllerModule,
} from '@taiga-ui/core';
import { TuiAutoFocusModule } from '@taiga-ui/cdk';
import { ComponentsModule } from '@src/app/components';
import { ListModule } from '@src/app/shared/list';
import { SharedModule } from '@src/app/shared';
import { FieldModule } from '@src/app/shared/field';
import { PhotoModule } from '@src/app/modules/photo';
import { CommitteeFieldModule } from '@src/app/modules/committees/committee-field';

import { ModernSearchUsersModule } from '../search-users';

import { PollInfoComponent } from './poll-info.component';
import { PollInfoViewComponent } from './poll-info-view/poll-info-view.component';
import { PollInfoEditComponent } from './poll-info-edit/poll-info-edit.component';
import { PollStartComponent } from './poll-start/poll-start.component';

@NgModule({
  declarations: [PollInfoComponent, PollInfoViewComponent, PollInfoEditComponent, PollStartComponent],
  imports: [
    CommonModule,
    FormsModule,
    ListModule,
    PhotoModule,
    ReactiveFormsModule,
    RouterModule,
    ModernSearchUsersModule,
    SharedModule,
    TuiAutoFocusModule,
    TuiButtonModule,
    TuiCheckboxBlockModule,
    TuiCheckboxModule,
    TuiDataListModule,
    TuiDataListWrapperModule,
    TuiGroupModule,
    TuiInputModule,
    TuiInputDateModule,
    TuiInputDateTimeModule,
    TuiInputFilesModule,
    TuiInputNumberModule,
    TuiInputTimeModule,
    TuiIslandModule,
    TuiLoaderModule,
    TuiRadioBlockModule,
    TuiRadioModule,
    TuiScrollbarModule,
    TuiSelectModule,
    TuiSliderModule,
    TuiTextareaModule,
    TuiTextfieldControllerModule,
    FieldModule,
    ComponentsModule,
    TuiMultiSelectModule,
    CommitteeFieldModule,
    TuiInputSliderModule,
    TuiCheckboxLabeledModule,
  ],
  exports: [PollInfoComponent],
})
export class PollInfoModule {}
