import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { BreakpointObserver } from '@angular/cdk/layout';
import { takeUntil } from 'rxjs/operators';
import { TuiDay, TuiTime } from '@taiga-ui/cdk';
import { tuiCreateTimePeriods } from '@taiga-ui/kit';
import { APP_CONFIG } from '@src/core';
import { ANSWER_TYPES } from '@src/constants';
import { AlertService, BreakpointObserverHelperService } from '@src/core/services';
import { AnswerType, Multimedia, PollUI, QuestionFormType, TuiFileLikeUI, UserUI, ViewMode } from '@src/models';
import { PollFullView, convertDateToApiFormat, AnswerOption } from '@src/api';
import { ResizableBaseComponent } from '@src/app/components/resizable-base-component';
import { TranslateService } from '@ngx-translate/core';

interface FormData {
  titleText: string;
  descriptionText: string;
  pollDuration: number;
  dateStart: TuiDay;
  timeStart: TuiTime;
  dateEnd: TuiDay;
  timeEnd: TuiTime;
  documents: TuiFileLikeUI[];
  members: UserUI[];
  questions: QuestionFormType[];
  committeeId: string;
}

type FormDataControls = { [key in keyof FormData]: AbstractControl };

@Component({
  selector: 'app-poll-info-edit',
  templateUrl: './poll-info-edit.component.html',
  styleUrls: ['./poll-info-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PollInfoEditComponent extends ResizableBaseComponent implements OnChanges {
  @Input() mode: ViewMode = 'create';
  @Input() data?: PollFullView | null;
  @Input() oldDocuments?: TuiFileLikeUI[] | null;
  @Input() membersList?: UserUI[] | null;
  @Input() externalLoading?: boolean = true;
  @Output() saved: EventEmitter<PollUI | null> = new EventEmitter();
  @Output() canceled: EventEmitter<void> = new EventEmitter();

  infoForm!: UntypedFormGroup;
  innerLoading: boolean = true;
  newPhoto?: Multimedia;
  readonly maxDocFileSize = APP_CONFIG.fileSizeMax.doc;
  readonly timeItems = tuiCreateTimePeriods(0, 24, [0, 15, 30, 45]);

  answerTypes: AnswerType[] = ANSWER_TYPES;

  private preventClearQuestionAfterChangeType = false;

  constructor(
    readonly cdr: ChangeDetectorRef,
    readonly breakpointObserver: BreakpointObserver,
    readonly breakpointObserverHelperService: BreakpointObserverHelperService,
    private formBuilder: UntypedFormBuilder,
    private alertService: AlertService,
    private readonly translateService: TranslateService,
  ) {
    super(cdr, breakpointObserver, breakpointObserverHelperService);

    this.initForm();
  }

  get loading() {
    return this.externalLoading || this.innerLoading;
  }

  get dateStart(): TuiDay {
    return this.infoForm.get('dateStart')?.value ?? new TuiDay(2000, 0, 1);
  }

  get dateEnd(): TuiDay {
    return this.infoForm.get('dateEnd')?.value ?? new TuiDay(3000, 0, 1);
  }

  // TODO: refactoring - https://angular24.ru/guide/dynamic-form#модель-вопроса
  get questions(): UntypedFormArray {
    return this.infoForm.get('questions') as UntypedFormArray;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data) {
      this.innerLoading = true;
      this.initForm();
      if (this.data) {
        try {
          this.preventClearQuestionAfterChangeType = true;
          const mappedData = this.mapPollUIToFormData(this.data);

          this.infoForm.patchValue(mappedData);
        } finally {
          this.preventClearQuestionAfterChangeType = false;
          this.innerLoading = false;
        }
      }
    }

    if (changes.mode) {
      if (this.mode === 'create') {
        this.innerLoading = false;
      }
    }

    if (changes.oldDocuments) {
      this.infoForm.get('documents')?.patchValue(this.oldDocuments);
    }

    if (changes.membersList && this.membersList) {
      this.infoForm.patchValue({
        members: this.membersList,
      });
    }

    this.cdr.markForCheck();
  }

  addQuestion(): void {
    this.questions.push(this.createQuestion());
  }

  removeQuestion(index: number): void {
    this.questions.removeAt(index);
  }

  getAnswerOptionsByIndex(questionIndex: number): UntypedFormArray {
    const question = this.getQuestionByIndex(questionIndex);
    return this.getAnswerOptions(question);
  }

  addAnswerOptionByIndex(questionIndex: number, answer?: AnswerOption): void {
    const question = this.getQuestionByIndex(questionIndex);
    this.addAnswerOption(question, answer);
  }

  removeAnswerOption(questionIndex: number, index: number): void {
    const question = this.getQuestionByIndex(questionIndex);
    this.getAnswerOptions(question).removeAt(index);
  }

  getOtherVisible(questionIndex: number): boolean {
    const answerOptions = this.getAnswerOptionsByIndex(questionIndex);
    for (let i = 0; i < answerOptions.length; i++) {
      if (answerOptions.at(i)?.value?.isAlternative) {
        return false;
      }
    }
    return true;
  }

  onClickSaveButton(): void {
    this.infoForm.markAllAsTouched();

    if (this.infoForm.invalid) {
      this.alertService.error(this.translateService.instant('common.alerts.errors.fillRequired'));
      return;
    }

    const pollData = this.mapFormDataToPollUI(this.infoForm.getRawValue());
    if (!pollData) return;

    // Не отправляем вопросы, если при редактировании параметр canEditPollQuestions === false.
    // Редактируем только данные опроса
    if (this.mode === 'edit' && !this.data?.canEditPollQuestions) {
      pollData.questions = undefined;
    }

    this.newPhoto = undefined;
    this.saved.emit(pollData);
  }

  onClickCancelButton(): void {
    this.newPhoto = undefined;
    this.canceled.emit();
  }

  onPhotoChange(newPhoto?: Multimedia): void {
    this.newPhoto = newPhoto;
    this.cdr.markForCheck();
  }

  onChangeSelectedCommitteeIds([committeeId]: string[]) {
    this.infoForm.patchValue({ committeeId: committeeId });
  }

  removeFile(formControl: AbstractControl | null, file: any): void {
    if (!!formControl) {
      formControl.setValue(formControl.value?.filter((current: File) => current !== file) ?? []);
    }
  }

  private initForm(): void {
    this.infoForm = new UntypedFormGroup(<FormDataControls>{
      titleText: new UntypedFormControl('', Validators.required),
      descriptionText: new UntypedFormControl(''),
      pollDuration: new UntypedFormControl(),
      dateStart: new UntypedFormControl(null, Validators.required),
      timeStart: new UntypedFormControl(null, Validators.required),
      dateEnd: new UntypedFormControl(null, Validators.required),
      timeEnd: new UntypedFormControl(null, Validators.required),
      documents: new UntypedFormControl(),
      members: new UntypedFormControl(null),
      questions: this.formBuilder.array([]),
      committeeId: new UntypedFormControl(),
    });
  }

  private mapPollUIToFormData(poll: PollFullView) {
    const { titleText, descriptionText, pollDuration, dateStart, dateEnd, questions, committeeId } = poll;

    const startNormalize = dateStart ? new Date(dateStart) : undefined; // TODO: delete after fix nswag DateTime format
    const dateStartNormalize = startNormalize
      ? new TuiDay(startNormalize.getFullYear(), startNormalize.getMonth(), startNormalize.getDate())
      : undefined;
    const timeStartNormalize = startNormalize
      ? new TuiTime(startNormalize.getHours(), startNormalize.getMinutes())
      : undefined;

    const endNormalize = dateEnd ? new Date(dateEnd) : undefined; // TODO: delete after fix nswag DateTime format
    const dateEndNormalize = endNormalize
      ? new TuiDay(endNormalize.getFullYear(), endNormalize.getMonth(), endNormalize.getDate())
      : undefined;
    const timeEndNormalize = endNormalize ? new TuiTime(endNormalize.getHours(), endNormalize.getMinutes()) : undefined;

    const questionsFormatted = (questions as QuestionFormType[])?.map((question, index) => {
      this.addQuestion();
      question.answers?.forEach(answer => this.addAnswerOptionByIndex(index, answer));
      question.answerType = this.answerTypes.find(answerType => answerType.id === question.answerTypeId);
      return question;
    });

    return <FormData>{
      titleText,
      descriptionText,
      pollDuration,
      dateStart: dateStartNormalize,
      timeStart: timeStartNormalize,
      dateEnd: dateEndNormalize,
      timeEnd: timeEndNormalize,
      questions: questionsFormatted,
      members: this.membersList,
      documents: [],
      committeeId,
    };
  }

  private mapFormDataToPollUI(formData: FormData) {
    const { titleText, descriptionText, pollDuration, members, documents, committeeId } = formData;

    const dateStart = new Date(
      formData.dateStart.year,
      formData.dateStart.month,
      formData.dateStart.day,
      formData.timeStart.hours,
      formData.timeStart.minutes,
    );

    const dateEnd = new Date(
      formData.dateEnd.year,
      formData.dateEnd.month,
      formData.dateEnd.day,
      formData.timeEnd.hours,
      formData.timeEnd.minutes,
    );

    const questions = formData.questions?.map(question => {
      return {
        answerTypeId: question?.answerType?.id,
        text: question.text,
        answers: question.answers,
      } as QuestionFormType;
    });

    if (
      questions.find(
        question => (question.answerTypeId === 2 || question.answerTypeId === 3) && !question.answers?.length, //2 - radio, 3 - check
      )
    ) {
      this.alertService.error(this.translateService.instant('components.pollInfo.alerts.errors.noAnswers'));
      return;
    }

    const deleteDocuments = this.oldDocuments?.filter(oldDocument => !documents?.includes(oldDocument));
    const newDocuments = documents?.filter(doc => !doc.id);

    const sendTo = members?.map(member => member.id);

    return <PollUI>{
      titleText,
      descriptionText,
      dateStart: convertDateToApiFormat(dateStart),
      dateEnd: convertDateToApiFormat(dateEnd),
      pollDuration,
      questions,
      sendTo,
      newDocuments,
      deleteDocuments,
      id: this.data?.id,
      photo: this.newPhoto?.file,
      committeeId,
    };
  }

  private getQuestionByIndex(questionIndex: number): AbstractControl {
    return this.questions.at(questionIndex);
  }

  private createQuestion(): UntypedFormGroup {
    const question = this.formBuilder.group({
      text: ['', Validators.required],
      answerType: [null, Validators.required],
      answers: this.formBuilder.array([]),
      // sortOrder: [], // TODO: невидимое поле
    });

    if (!this.preventClearQuestionAfterChangeType) {
      question
        .get('answerType')
        ?.valueChanges.pipe(takeUntil(this.destroyed$$))
        .subscribe(() => {
          this.removeAllAnswerOptions(question);
          this.addAnswerOption(question);
        });
    }

    return question;
  }

  private createAnswerOption(index: number = 0, answer?: AnswerOption): UntypedFormGroup {
    return this.formBuilder.group({
      textValue: [
        answer?.isAlternative
          ? {
              value: answer?.textValue ?? this.translateService.instant('components.pollInfo.labels.other') + '...',
              disabled: true,
            }
          : answer?.textValue ?? this.translateService.instant('components.pollInfo.labels.option') + ' ' + (index + 1),
        Validators.required,
      ],
      isAlternative: answer?.isAlternative ?? false,
      sortOrder: answer?.sortOrder ?? index,
    });
  }

  private getAnswerOptions(question: AbstractControl): UntypedFormArray {
    return question.get('answers') as UntypedFormArray;
  }

  private addAnswerOption(question: AbstractControl, answer?: AnswerOption): void {
    const answerOptions = this.getAnswerOptions(question);
    answerOptions.push(this.createAnswerOption(answerOptions.length, answer));
  }

  private removeAllAnswerOptions(question: AbstractControl): void {
    const answerOptions = this.getAnswerOptions(question);
    for (let i = answerOptions.length - 1; i >= 0; i--) {
      answerOptions.removeAt(i);
    }
  }
}
