import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, lastValueFrom, of, Subject, throwError } from 'rxjs';
import { catchError, map, takeUntil } from 'rxjs/operators';
import {
  LabelItemsService,
  LabelItemsView,
  LabelItemForUnionAdd,
  ObjectMenuService,
  ObjectMenuCustomAdd,
} from '@src/api';
import { MenuItem } from '@src/models';
import { TranslateService } from '@ngx-translate/core';
import { NgxRolesService } from 'ngx-permissions';
import { AssociationMenuItem } from '@src/app/pages/organisation-page/constants';
import { UserService } from '@src/core/services';

import { CodeName, CustomNamesItem, VisibilityEnum } from '../types';
import { RoleCode } from '../../auth';

@Injectable({
  providedIn: 'root',
})
export class CustomNamesService implements OnDestroy {
  data$: BehaviorSubject<CustomNamesItem[] | undefined> = new BehaviorSubject<CustomNamesItem[] | undefined>(undefined);
  objectMenuWCustom$: BehaviorSubject<CustomNamesItem[] | undefined> = new BehaviorSubject<
    CustomNamesItem[] | undefined
  >(undefined);
  allCustomNames$: BehaviorSubject<CustomNamesItem[] | undefined> = new BehaviorSubject<CustomNamesItem[] | undefined>(
    undefined,
  );

  private destroyed$$: Subject<void> = new Subject<void>();

  constructor(
    private customNamesService: LabelItemsService,
    private readonly objectMenuService: ObjectMenuService,
    private readonly translateService: TranslateService,
    private readonly ngxRolesService: NgxRolesService,
    private readonly userService: UserService,
  ) {
    this.data$
      .pipe(
        map(items => this.mergingCustomNames(items)),
        takeUntil(this.destroyed$$),
      )
      .subscribe(allCustomNames => this.allCustomNames$.next(allCustomNames));
  }

  ngOnDestroy(): void {
    this.destroyed$$.next();
    this.destroyed$$.complete();
  }

  async getData() {
    return new Promise<void>(resolve => {
      lastValueFrom(
        this.customNamesService.labelItems().pipe(
          catchError(err => {
            // TODO show error notification
            return of(null);
          }),
          map(data => this.mapDataToDataUI(data)),
          map(items => this.sortingCustomNames(items)),
          takeUntil(this.destroyed$$),
        ),
      ).then(data => {
        this.data$.next(data);

        resolve();
      });
    });
  }

  async getObjectMenuWCustom(id: string) {
    this.objectMenuService
      .objectMenuWCustom(id)
      .pipe(
        catchError(err => {
          return throwError(err);
        }),
        map(data => this.mapDataToDataUI(data, id)),
        map(items => this.sortingCustomNames(items)),
        map(items => this.formatIsMenuItem(items)),
        takeUntil(this.destroyed$$),
      )
      .subscribe(data => {
        this.objectMenuWCustom$.next(data);
      });
  }

  saveData(items: CustomNamesItem[]) {
    const data = this.mapDataUIToData(items);
    return this.customNamesService.upsertLabelItemsForUnion(data).pipe(
      catchError(err => {
        // TODO show error notification
        return throwError(err);
      }),
      takeUntil(this.destroyed$$),
    );
  }

  saveCommitteeData(id: string, items: CustomNamesItem[]) {
    const data = items.map<ObjectMenuCustomAdd>(item => ({
      attachId: id,
      codeName: item.codeName ?? '',
      languageCode: item.languageCode ?? '',
      title: item.title || undefined,
      visibilityOption: item.visibilityOption,
    }));

    return this.objectMenuService.upsertObjectMenuCustom(data).pipe(
      catchError(err => {
        return throwError(err);
      }),
      takeUntil(this.destroyed$$),
    );
  }

  saveOrganisationData(id: string, items: CustomNamesItem[]) {
    const data = items.map<ObjectMenuCustomAdd>(item => ({
      attachId: id,
      codeName: item.codeName ?? '',
      languageCode: item.languageCode ?? '',
      title: item.title || undefined,
      visibilityOption: item.visibilityOption,
    }));

    return this.objectMenuService.upsertObjectMenuCustomForOrganization(data).pipe(
      catchError(err => {
        return throwError(err);
      }),
      takeUntil(this.destroyed$$),
    );
  }

  getItem(codeName: CodeName, languageCode?: string) {
    return this.allCustomNames$.value?.find(
      item =>
        item.codeName === codeName &&
        (languageCode ? item.languageCode === languageCode : item.languageCode === this.translateService.currentLang),
    );
  }

  getVisibleMenuItems(menuItems?: MenuItem<string, string>[]): MenuItem<string, any>[] {
    const roles = this.ngxRolesService.getRoles();

    return (
      menuItems?.filter(item => {
        switch (item.visibilityOption) {
          case VisibilityEnum.INVISIBLE:
            return false;

          case VisibilityEnum.ADMINISTRATORS:
            return RoleCode.AdminUO in roles;

          case VisibilityEnum.ALL:
            return true;

          default:
            return true;
        }
      }) ?? []
    );
  }

  resetObjectMenuWCustom() {
    this.objectMenuWCustom$.next(undefined);
  }

  private mapDataToDataUI(data: LabelItemsView | null, objectId?: string): CustomNamesItem[] {
    if (!data?.defaults) return [];

    return data.defaults.map((defaultItem: CustomNamesItem) => {
      defaultItem.defaultTitle = defaultItem.title;

      if (
        objectId &&
        defaultItem.codeName === AssociationMenuItem.Committees &&
        objectId === this.userService.authUser?.parentOrganisationId
      ) {
        defaultItem.visibilityOption = VisibilityEnum.INVISIBLE;
      }

      const findCustomItem =
        data.customs?.find(customItem => customItem.labelItemsDefaultId === defaultItem.id) ??
        data.customs?.find(
          item => item.codeName === defaultItem.codeName && item.languageCode === defaultItem.languageCode,
        );

      defaultItem.title = findCustomItem
        ? findCustomItem.title
        : this.getItem(defaultItem.codeName as CodeName, defaultItem.languageCode)?.title || defaultItem.title;
      defaultItem.isVisible = findCustomItem ? findCustomItem.isVisible : defaultItem.isVisible;
      defaultItem.labelItemsDefaultId = findCustomItem ? findCustomItem.labelItemsDefaultId : defaultItem.id;
      defaultItem.visibilityOption = findCustomItem ? findCustomItem.visibilityOption : defaultItem.visibilityOption;
      defaultItem.parentOrganisationId = findCustomItem
        ? findCustomItem.parentOrganisationId
        : defaultItem.parentOrganisationId;

      delete defaultItem.id;

      return defaultItem;
    });
  }

  private mapDataUIToData(items: CustomNamesItem[]): LabelItemForUnionAdd[] {
    return items
      .filter(item => item.title)
      .map(item => {
        delete item.defaultTitle;
        delete item.description;
        delete item.isMenuItem;
        delete item.sortOrder;

        return item;
      });
  }

  private sortingCustomNames(items: CustomNamesItem[]): CustomNamesItem[] {
    items.sort((item1, item2) => {
      const itemSortOrder1 = item1.sortOrder;
      const itemSortOrder2 = item2.sortOrder;

      if (!itemSortOrder1) return 1;
      if (!itemSortOrder2) return -1;

      if (itemSortOrder1 < itemSortOrder2) return -1;
      if (itemSortOrder1 > itemSortOrder2) return 1;
      return 0;
    });

    return items;
  }

  private mergingCustomNames(items?: CustomNamesItem[]): CustomNamesItem[] | undefined {
    if (!items) return;

    return [...items.map(item => ({ ...item }))].map((item: CustomNamesItem) => {
      item.title = item.title && item.title !== '' ? item.title : item.defaultTitle ?? '';

      delete item.defaultTitle;

      return item;
    });
  }

  /** Проставление isMenuItem = true всем элементам. Необходимо для корректного отображения настроек организаций и комитетов */
  private formatIsMenuItem(items?: CustomNamesItem[]): CustomNamesItem[] | undefined {
    return items?.map(item => {
      item.isMenuItem = true;

      return item;
    });
  }
}
