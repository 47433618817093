<form class="poll-info-start" [formGroup]="infoForm">
  <div *ngIf="pollData" class="poll-info-start__info info">
    <h2 class="info__title">{{ 'components.pollStart.labels.poll' | translate }}: {{ pollData.titleText }}</h2>
    <h3 *ngIf="pollData.descriptionText" class="info__description">
      <app-parse-links [value]="pollData.descriptionText"></app-parse-links>
    </h3>
    <div *ngIf="pollData.pollDuration" class="info__time">
      {{ 'components.pollStart.labels.pollDuration' | translate }}: {{ pollData.pollDuration
      }}{{ 'components.pollStart.labels.minutesShort' | translate }}
    </div>
    <div *ngIf="documentsList?.length" class="info__documents">
      <app-field
        [name]="'components.pollStart.fields.additionalDocs' | translate"
        [typeName]="'files-list'"
        [value]="documentsList"
        [size]="tuiElementLargeSize"
      ></app-field>
    </div>
  </div>
  <div *ngIf="questions.controls.length; else noData" class="poll-info-start__questions" formArrayName="questions">
    <tui-island *ngFor="let question of questions.controls; let qIndex = index" class="tui-island">
      <div class="tui-island__paragraph item" [formGroupName]="qIndex">
        <div class="item__answer-control">
          <h3 class="tui-island__title">
            <app-parse-links [value]="question.get('text')?.value"></app-parse-links>
          </h3>

          <ng-container [ngSwitch]="question.get('answerType')?.value?.type">
            <tui-input *ngSwitchCase="'text'" [tuiTextfieldSize]="tuiElementLargeSize" formControlName="altAnswer">
              {{ 'components.pollStart.fields.answer' | translate }}
            </tui-input>

            <tui-textarea
              *ngSwitchCase="'area'"
              [expandable]="true"
              [rows]="10"
              [tuiTextfieldSize]="tuiElementLargeSize"
              formControlName="altAnswer"
            >
              {{ 'components.pollStart.fields.answer' | translate }}
            </tui-textarea>

            <tui-select *ngSwitchCase="'select'" formControlName="altAnswer"></tui-select>

            <ng-container *ngSwitchCase="'file'">
              <tui-input-files
                *ngIf="!question.get('file')?.value"
                class="size_{{ tuiElementLargeSize }}"
                formControlName="file"
                [tuiTextfieldSize]="tuiElementLargeSize"
                [size]="tuiElementLargeSize"
              ></tui-input-files>
              <tui-files *ngIf="!!question.get('file')?.value">
                <tui-file [file]="question.get('file')?.value" (removed)="question.get('file')?.reset()"></tui-file>
              </tui-files>
            </ng-container>

            <div tuiGroup *ngSwitchCase="'date'">
              <tui-input-date formControlName="date" [tuiTextfieldSize]="tuiElementLargeSize">
                {{ 'components.pollStart.fields.selectDate' | translate }}
              </tui-input-date>

              <tui-input-time formControlName="time" [tuiTextfieldSize]="tuiElementLargeSize" [items]="timeItems">
                {{ 'components.pollStart.fields.selectTime' | translate }}
              </tui-input-time>
            </div>

            <div class="answer" *ngSwitchCase="'radio'">
              <ng-container *ngFor="let answer of question.get('answers')?.value">
                <tui-radio-block [size]="tuiElementLargeSize" formControlName="answerOption" [item]="answer">
                  {{ answer.textValue }}
                </tui-radio-block>
              </ng-container>
              <tui-input
                *ngIf="question.get('answerOption')?.value?.isAlternative"
                [tuiTextfieldSize]="tuiElementLargeSize"
                formControlName="altAnswer"
              >
                {{ 'components.pollStart.fields.yourAnswer' | translate }}
              </tui-input>
            </div>

            <div class="answer" *ngSwitchCase="'check'">
              <tui-data-list>
                <tui-opt-group tuiMultiSelectGroup formControlName="answerOption">
                  <button *ngFor="let answer of question.get('answers')?.value" tuiOption [value]="answer">
                    {{ answer.textValue }}
                  </button>
                </tui-opt-group>
              </tui-data-list>
              <tui-input
                *ngIf="isAlternativeSelected(question)"
                [tuiTextfieldSize]="tuiElementLargeSize"
                formControlName="altAnswer"
              >
                {{ 'components.pollStart.fields.yourAnswer' | translate }}
              </tui-input>
            </div>

            <span *ngSwitchDefault class="">{{ 'components.pollStart.labels.unknownResponseType' | translate }}</span>
          </ng-container>
        </div>
      </div>
    </tui-island>
  </div>

  <div *ngIf="!loading" class="poll-info-start__buttons">
    <button
      tuiButton
      class="poll-info-start-button poll-info-start-button_edit"
      type="button"
      appearance="primary"
      [size]="tuiElementLargeSize"
      (click)="saveReplies()"
    >
      {{ 'components.pollStart.buttons.sendReplies' | translate }}
    </button>
    <button tuiButton type="button" appearance="primary" [size]="tuiElementLargeSize" (click)="cancel()">
      {{ 'common.buttons.cancel' | translate }}
    </button>
  </div>
</form>

<ng-template #noData>
  <p>{{ 'common.labels.noData' | translate }}</p>
</ng-template>
