import { marker } from '@biesbjerg/ngx-translate-extract-marker';

import { FilterStore, FilterParams } from './types';

export const DEFAULT_FILTER_STORE: Readonly<FilterStore> = {
  jobs: { title: marker('components.searchUsers.constants.jobs'), items: [], result: [] },
  businessTypes: { title: marker('components.searchUsers.constants.businessTypes'), items: [], result: [] },
  organizations: {
    title: marker('components.searchUsers.constants.organization'),
    items: [],
    result: [],
    variant: 'without-avatar',
  },
  committees: { title: marker('components.searchUsers.constants.committees'), items: [], result: [], variant: 'full' },
};

export const DEFAULT_FILTER_PARAMS: Readonly<FilterParams> = {
  jobs: [],
  businessTypes: [],
  organizations: [],
  committees: [],
};
