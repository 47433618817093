import {
  Component,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  OnDestroy,
  EventEmitter,
  Output,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { BusinessTypesService, CommitteeService, JobTitleService, OrganisationService } from '@src/core/services';
import { takeUntil } from 'rxjs/operators';
import { FilterFieldsOptions, FilterItem } from '@src/app/modules/search-users/types';
import { CommitteeUI } from '@src/models';

import { DEFAULT_FILTER_STORE, DEFAULT_FILTER_PARAMS } from './constants';
import { FilterParams, FilterStoreItem, FilterStoreKeys } from './types';

@Component({
  selector: 'search-users-filters',
  templateUrl: './search-users-filters.component.html',
  styleUrls: ['./search-users-filters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchUsersFiltersComponent implements OnInit, OnDestroy, OnChanges {
  /** Поля, скрытые в фильтре */
  @Input() excludeFilterFields: FilterFieldsOptions[] = [];

  @Output() filtersChange = new EventEmitter<FilterParams>();

  filtersCount = 0;
  isPossibleToApply = false;
  store = { ...DEFAULT_FILTER_STORE };
  open$ = new BehaviorSubject<boolean>(false);

  appliedFilterCount = 0;
  appliedStore = { ...DEFAULT_FILTER_PARAMS };

  private readonly destroyed$$: Subject<void> = new Subject<void>();

  constructor(
    readonly cdr: ChangeDetectorRef,
    private readonly translateService: TranslateService,
    private readonly jobTitlesService: JobTitleService,
    private readonly businessTypesService: BusinessTypesService,
    private readonly organisationService: OrganisationService,
    private readonly committeeService: CommitteeService,
  ) {
    this.jobTitlesService.jobTitles$.pipe(takeUntil(this.destroyed$$)).subscribe(items => {
      this.store.jobs.items = items.map(item => ({ id: item.jobTitleId ?? '', label: item.jobTitleName ?? '' }));
    });

    this.businessTypesService.businessTypes$.pipe(takeUntil(this.destroyed$$)).subscribe(items => {
      this.store.businessTypes.items =
        items?.map(item => ({
          id: item.businessTypeId ?? '',
          label: item.businessTypeName ?? '',
        })) ?? [];
    });

    this.organisationService.organisationList$.pipe(takeUntil(this.destroyed$$)).subscribe(items => {
      this.store.organizations.items =
        items?.map(item => ({
          id: item.id ?? '',
          label: item.shortName ?? '',
          subLabel: item.fullName,
        })) ?? [];
    });

    this.committeeService.committeesTree$.pipe(takeUntil(this.destroyed$$)).subscribe(items => {
      const committees: FilterItem[] = [];
      this.getCommittees(committees, items ?? []);

      this.store.committees.items = committees;
    });

    this.open$.pipe(takeUntil(this.destroyed$$)).subscribe(open => {
      if (open) {
        this.restoreStore();
      } else {
        this.clearStore();
      }

      this.onCheckFiltersCount();
    });
  }

  ngOnInit() {
    this.jobTitlesService.getJobTitles();
    this.businessTypesService.getBusinessTypes();
    this.organisationService.getOrganisationList();
    this.committeeService.loadCommitteesTreeData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.excludeFilterFields) {
      this.store = { ...DEFAULT_FILTER_STORE };
      this.appliedStore = { ...DEFAULT_FILTER_PARAMS };

      if (this.excludeFilterFields.length) {
        this.excludeFilterFields.forEach(value => {
          delete this.store[value];
          delete this.appliedStore[value];
        });
      }
    }
  }

  ngOnDestroy() {
    this.onReset();

    this.destroyed$$.next();
    this.destroyed$$.complete();
  }

  onToggleDropdown() {
    this.open$.next(!this.open$.value);
  }

  onObscured(obscured: boolean): void {
    if (obscured) {
      this.open$.next(false);
    }
  }

  onActiveZone(active: boolean): void {
    this.open$.next(active && this.open$.value);
  }

  onApply() {
    this.onCheckFiltersCount();

    const result = Object.keys(this.store).reduce<FilterParams>((acc, key) => {
      acc[key as FilterStoreKeys] = this.store[key as FilterStoreKeys].result;

      return acc;
    }, DEFAULT_FILTER_PARAMS);

    this.appliedStore = result;
    this.appliedFilterCount = this.filtersCount;
    this.open$.next(false);

    this.cdr.markForCheck();

    this.filtersChange.emit(result);
  }

  onReset() {
    this.clearStore();

    this.onApply();
  }

  onCheckFiltersCount() {
    this.filtersCount = Object.values(this.store).reduce((acc, item) => {
      return acc + item.result.length;
    }, 0);

    if (this.filtersCount !== this.appliedFilterCount) {
      this.isPossibleToApply = true;
    } else {
      this.isPossibleToApply = Object.keys(this.store).some(key => {
        const storeResult = this.store[key as FilterStoreKeys].result;
        const appliedResult = this.appliedStore[key as FilterStoreKeys];

        if (storeResult.length !== appliedResult.length) {
          return true;
        }

        return storeResult.some(storeItem => !appliedResult.find(appliedItem => appliedItem.id === storeItem.id));
      });
    }

    this.cdr.markForCheck();
  }

  getStoreValues(): FilterStoreItem[] {
    return Object.values(this.store);
  }

  private clearStore() {
    Object.keys(this.store).forEach(key => {
      this.store[key as FilterStoreKeys].result = [];
    });
  }

  private restoreStore() {
    Object.keys(this.store).forEach(key => {
      this.store[key as FilterStoreKeys].result = this.appliedStore[key as FilterStoreKeys].slice();
    });
  }

  private getCommittees(store: FilterItem[], list: CommitteeUI[], parent?: string[]) {
    list.forEach(item => {
      parent = parent ?? [];

      if (!item.parentCommitteeId) {
        parent = [item.name ?? ''];
      } else {
        parent.push(item.name ?? '');
      }

      store.push({
        id: item.id ?? '',
        label: item.name ?? '',
        avatarId: item.photoId,
        subLabel: parent.length ? parent.join(' > ') : undefined,
      });
      if (item.subCommitties?.length) {
        this.getCommittees(store, item.subCommitties, parent);
      }
    });
  }
}
