import { ChatFilterInfoUnion } from '@airgram/web';
import { Component, OnInit, ChangeDetectionStrategy, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { lastValueFrom, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TelegramMessengerService } from '@src/app/modules/telegram';
import { EnvService } from '@src/app/modules/env';
import { SessionService } from '@src/app/modules/auth';
import { APP_CONFIG } from '@src/core';
import { AlertService, UserService } from '@src/core/services';
import { capitalizeFirstLetter, reload } from '@src/utils';
import { TranslateService } from '@ngx-translate/core';

import { FolderConfig } from './types';

const UNIONS_FOLDER_CONFIG = APP_CONFIG.folders.unions;

@Component({
  selector: 'telegram-folders',
  templateUrl: './folders.component.html',
  styleUrls: ['./folders.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FoldersComponent implements OnInit, OnDestroy {
  currentFolders?: ChatFilterInfoUnion[];
  unionsFolder?: ChatFilterInfoUnion;
  folderLimit = this.messengerService.limits.foldersCount;

  folderConfig?: FolderConfig;

  allChatsIds$ = this.messengerService.allChatsIds$;

  private destroyed$$: Subject<void> = new Subject<void>();

  constructor(
    private cdr: ChangeDetectorRef,
    private messengerService: TelegramMessengerService,
    private userService: UserService,
    private env: EnvService,
    private sessionService: SessionService,
    private readonly alertService: AlertService,
    private readonly translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    this.init();
  }

  ngOnDestroy(): void {
    this.destroyed$$.next();
    this.destroyed$$.complete();
  }

  onLogged() {
    this.init();
  }

  onClickCreateFolderButton() {
    if (!this.env.isLoggedMessenger()) {
      this.env.viewConfirmLoginMessenger(() => this.reload());
    }

    if (!this.currentFolders || this.currentFolders.length === this.folderLimit || !this.folderConfig) return;

    const allTgChatsIds = this.allChatsIds$.value;
    if (!allTgChatsIds.length) return;

    lastValueFrom(this.userService.getUnionsChatsIds(allTgChatsIds)).then(allUnionsChatsIds => {
      const includedChatIds = allUnionsChatsIds;

      this.messengerService.api
        .createChatFilter({
          _: 'chatFilter',
          title: this.folderConfig?.title,
          iconName: this.folderConfig?.iconName,
          includedChatIds,
        })
        .then(res => {
          if (res.response._ === 'error') return;

          this.alertService.success(
            `${this.translateService.instant('components.telegramFolders.alerts.successes.folder')} "${
              this.folderConfig?.title
            }" ${this.translateService.instant('components.telegramFolders.alerts.successes.folderCreated')}`,
            false,
          );
        });
    });
  }

  onClickEditFolderButton() {
    if (!this.env.isLoggedMessenger()) {
      this.env.viewConfirmLoginMessenger(() => this.reload());
    }

    if (!this.currentFolders) return;

    const folder = this.unionsFolder;
    if (!folder) return;

    const allTgChatsIds = this.allChatsIds$.value;
    if (!allTgChatsIds.length) return;

    lastValueFrom(this.userService.getUnionsChatsIds(allTgChatsIds)).then(allUnionsChatsIds => {
      const includedChatIds = allUnionsChatsIds;

      this.messengerService.api
        .editChatFilter(folder.id, {
          _: 'chatFilter',
          title: this.folderConfig?.title,
          iconName: this.folderConfig?.iconName,
          includedChatIds,
        })
        .then(res => {
          if (res.response._ === 'error') return;

          this.alertService.success(
            `${this.translateService.instant('components.telegramFolders.alerts.successes.folder')} "${
              this.folderConfig?.title
            }" ${this.translateService.instant('components.telegramFolders.alerts.successes.folderEdited')}`,
            false,
          );
        });
    });
  }

  onClickDeleteFolderButton(folder: ChatFilterInfoUnion) {
    if (!this.env.isLoggedMessenger()) {
      this.env.viewConfirmLoginMessenger(() => this.reload());
    }

    this.messengerService.api.deleteChatFilter(folder.id).then(() => {
      this.alertService.success(
        `${this.translateService.instant('components.telegramFolders.alerts.successes.folder')} "${
          folder.title
        }" ${this.translateService.instant('components.telegramFolders.alerts.successes.folderDeleted')}`,
        false,
      );
    });
  }

  private init() {
    this.folderConfig = UNIONS_FOLDER_CONFIG;
    if (this.sessionService.brandCodeName) {
      this.folderConfig.title = capitalizeFirstLetter(this.sessionService.brandCodeName);
    }

    this.messengerService.chatFilters$.pipe(takeUntil(this.destroyed$$)).subscribe(chatFilters => {
      this.currentFolders = chatFilters;

      this.unionsFolder = this.currentFolders?.find(folder => {
        return folder.title.toLowerCase() === this.folderConfig?.title.toLowerCase();
      });

      this.cdr.markForCheck();
    });

    this.cdr.markForCheck();
  }

  private reload() {
    reload();
  }
}
