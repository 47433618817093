import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BreakpointObserver } from '@angular/cdk/layout';
import { TuiContextWithImplicit, TuiDay, tuiPure, TuiStringHandler, TuiTime, TuiValidationError } from '@taiga-ui/cdk';
import { tuiCreateTimePeriods } from '@taiga-ui/kit';
import { TranslateService } from '@ngx-translate/core';
import { TelegramMessengerService } from '@src/app/modules/telegram';
import { EnvService } from '@src/app/modules/env';
import { AUTOCOMPLETE_TYPES, EVENT_TYPES } from '@src/constants';
import { APP_CONFIG } from '@src/core';
import { AlertService, BreakpointObserverHelperService } from '@src/core/services';
import { ChatModel, EventUI, ViewMode, UserUI, Multimedia } from '@src/models';
import { DocumentView, convertDateToApiFormat } from '@src/api';
import { ResizableBaseComponent } from '@src/app/components/resizable-base-component';
import { UsersTableValue } from '@src/app/modules/users-table';
import { Nullable } from '@src/types/utils';

import { EventType, FormData, FormDataControls } from './types';

@Component({
  selector: 'app-event-info-edit',
  templateUrl: './event-info-edit.component.html',
  styleUrls: ['./event-info-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventInfoEditComponent extends ResizableBaseComponent implements OnChanges {
  @Input() mode: ViewMode = 'edit';
  @Input() data?: EventUI | null;
  @Input() documentsList?: DocumentView[] | null;
  @Input() membersList?: UserUI[] | null;
  @Input() allowEditing?: boolean | null = false;
  @Input() externalLoading?: boolean = true;
  @Output() saved: EventEmitter<EventUI> = new EventEmitter();
  @Output() canceled: EventEmitter<void> = new EventEmitter();

  infoForm!: UntypedFormGroup;
  newPhoto?: Multimedia;
  innerLoading: boolean = true;

  chatsListVisible = false;

  chatsFilter = {
    chatType: ['chatTypeBasicGroup', 'chatTypeSupergroup'],
    onlyUnionsChats: true,
  };

  readonly timeItems = tuiCreateTimePeriods(0, 24, [0, 15, 30, 45]);
  readonly maxDocFileSize = APP_CONFIG.fileSizeMax.doc;
  readonly eventTypes: EventType[] = EVENT_TYPES;
  readonly type2 = [
    this.translateService.instant('components.eventInfo.labels.offline'),
    this.translateService.instant('components.eventInfo.labels.online'),
  ];
  readonly autocompleteTypes = AUTOCOMPLETE_TYPES;

  constructor(
    readonly cdr: ChangeDetectorRef,
    readonly breakpointObserver: BreakpointObserver,
    readonly breakpointObserverHelperService: BreakpointObserverHelperService,
    private messengerService: TelegramMessengerService,
    private alertService: AlertService,
    private readonly translateService: TranslateService,
    public readonly env: EnvService,
  ) {
    super(cdr, breakpointObserver, breakpointObserverHelperService);

    this.initForm();
  }

  get loading() {
    return this.externalLoading || this.innerLoading;
  }

  get isLoggedMessenger(): boolean {
    return this.env.isLoggedMessenger();
  }

  get dateStart(): TuiDay {
    return this.infoForm.get('dateStart')?.value ?? new TuiDay(2000, 0, 1);
  }

  get dateEnd(): TuiDay {
    return this.infoForm.get('dateEnd')?.value ?? new TuiDay(3000, 0, 1);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data) {
      this.innerLoading = true;
      if (this.data) {
        const mappedData = this.mapEventUIToFormData(this.data);
        this.infoForm.patchValue(mappedData);
        this.innerLoading = false;
      } else {
        this.infoForm.reset();
        this.initForm();
      }
    }

    if (changes.documentsList && this.documentsList) {
      this.infoForm.patchValue({
        docs: this.mapFilesToFormData(this.documentsList).filter(doc => doc.fileType === 14), // 14     Приложение к мероприятию
        program: this.mapFilesToFormData(this.documentsList).find(doc => doc.fileType === 5), // 5      Программа
      });
    }

    if (changes.membersList && this.membersList) {
      this.infoForm.patchValue({
        participants: this.membersList.filter(member => member.id).map(member => member.id),
      });
    }

    if (changes.mode) {
      if (this.mode === 'create') {
        this.innerLoading = false;
      }
    }

    this.cdr.markForCheck();
  }

  removeDocsFile(file: any): void {
    const docs = this.infoForm.get('docs');
    if (!!docs) {
      docs.setValue(this.infoForm.get('docs')?.value?.filter((current: File) => current !== file) ?? []);
    }
  }

  async onClickSaveButton(): Promise<void> {
    this.infoForm.markAllAsTouched();

    if (this.infoForm.invalid) {
      const maxLengthError = Object.keys(this.infoForm.controls).find(
        control => this.infoForm.get(control)?.errors?.maxlength,
      );

      const requiredError = Object.keys(this.infoForm.controls).find(
        control => this.infoForm.get(control)?.errors?.required,
      );

      if (maxLengthError) {
        this.alertService.error(this.translateService.instant('common.alerts.errors.maxLength'));
      }
      if (requiredError) {
        this.alertService.error(this.translateService.instant('common.alerts.errors.fillRequired'));
      }

      return;
    }

    const eventData = await this.mapFormDataToEventUI(this.infoForm.value);
    this.newPhoto = undefined;
    this.saved.emit(eventData);
  }

  onClickCancelButton(): void {
    this.newPhoto = undefined;
    this.canceled.emit();
  }

  onPhotoChange(newPhoto?: Multimedia): void {
    this.newPhoto = newPhoto;
    this.cdr.markForCheck();
  }

  onChangeSelectedCommitteeIds([committeeId]: string[]) {
    this.infoForm.patchValue({ committeeId: committeeId });
  }

  changeChat(chat?: ChatModel): void {
    this.infoForm.patchValue({ groupChat: chat });
    this.infoForm.patchValue({ createGroupChat: false });
    this.chatsListVisible = false;
    this.cdr.markForCheck();
  }

  onChatButtonCreate(): void {
    this.infoForm.patchValue({ groupChat: 'new' });
    this.infoForm.patchValue({ createGroupChat: true });
    this.chatsListVisible = false;
    this.cdr.markForCheck();
  }

  onClickChatField(): void {
    this.chatsListVisible = !this.chatsListVisible;
    this.cdr.markForCheck();
  }

  onChatActiveZoneChange(): void {
    this.chatsListVisible = false;
    this.cdr.markForCheck();
  }

  onFillOnlineLinkChange(fillOnlineLink: boolean) {
    if (fillOnlineLink) {
      this.infoForm.get('onlineLink')?.disable({ onlySelf: true });
      this.infoForm.patchValue({ onlineLink: this.translateService.instant('components.eventInfo.fields.chatLink') });

      if (!this.infoForm.get('groupChat')?.value) {
        this.infoForm.patchValue({ groupChat: 'new', createGroupChat: true });
      }
    } else {
      this.infoForm.get('onlineLink')?.enable({ onlySelf: true });
      this.infoForm.patchValue({ onlineLink: '' });
    }
  }

  onGroupChatChange(groupChat: ChatModel) {
    if (groupChat === null) {
      this.infoForm.patchValue({ createGroupChat: false });
    }
  }

  onClickMessengerLoginButton(): void {
    if (!this.isLoggedMessenger) {
      this.env.viewConfirmLoginMessenger();
      return;
    }
  }

  onChangeParticipants(value: UsersTableValue) {
    this.infoForm.get('participants')?.patchValue(value.participants);
    this.infoForm.get('participants')?.setErrors(value.incorrect ? { incorrect: true } : null);
  }

  controlValueError(key: string): Nullable<TuiValidationError> {
    const control = this.infoForm.get(key);
    if (!control) return null;

    if (control.touched && control.errors?.maxlength) {
      return this.translateService.instant('common.labels.maxLengthError', {
        value: control.errors?.maxlength.requiredLength,
      });
    }

    return null;
  }

  @tuiPure
  eventTypesStringify(items: ReadonlyArray<EventType>): TuiStringHandler<TuiContextWithImplicit<number>> {
    const map = new Map(items.map(({ id, name }) => [id, name] as [number, string]));

    return ({ $implicit }: TuiContextWithImplicit<number>) => this.translateService.instant(map.get($implicit) || ' ');
  }

  private initForm(): void {
    this.infoForm = new UntypedFormGroup(<FormDataControls>{
      subject: new UntypedFormControl('', [Validators.required, Validators.maxLength(300)]),
      description: new UntypedFormControl('', Validators.maxLength(3000)),
      dateStart: new UntypedFormControl(null, Validators.required),
      timeStart: new UntypedFormControl('', Validators.required),
      dateEnd: new UntypedFormControl(null, Validators.required),
      timeEnd: new UntypedFormControl('', Validators.required),
      address: new UntypedFormControl('', Validators.maxLength(1500)),
      onlineLink: new UntypedFormControl('', Validators.maxLength(1000)),
      fillOnlineLink: new UntypedFormControl(false),
      eventTypeId: new UntypedFormControl(null),
      participants: new UntypedFormControl([]),
      groupChat: new UntypedFormControl(),
      groupChatId: new UntypedFormControl(),
      groupInviteCode: new UntypedFormControl(),
      committeeId: new UntypedFormControl(),
      createGroupChat: new UntypedFormControl(false),
      isVisibleToAll: new UntypedFormControl(false),
      contactPerson: new UntypedFormControl(null),

      // TODO: these fields are not in the API
      program: new UntypedFormControl(),
      docs: new UntypedFormControl(''),
      // documentsIds: new FormControl(),
    });
  }

  private mapFilesToFormData(docs: DocumentView[]) {
    return docs.map(file => {
      return {
        name: file.fileName || '',
        ...file,
      };
    });
  }

  private mapEventUIToFormData(event: EventUI) {
    const {
      subject,
      description,
      dateStart,
      dateEnd,
      address,
      onlineLink,
      eventTypeId,
      groupChatId,
      groupInviteCode,
      committeeId,
      isVisibleToAll,
      contactPersonId,
      contactPersonFullName,
    } = event;
    // TODO: modify documentsIds
    // TODO: add fields: documentsIds

    const startNormalize = dateStart ? new Date(dateStart) : undefined; // TODO: delete after fix nswag DateTime format
    const dateStartNormalize = startNormalize
      ? new TuiDay(startNormalize.getFullYear(), startNormalize.getMonth(), startNormalize.getDate())
      : undefined;
    const timeStartNormalize = startNormalize
      ? new TuiTime(startNormalize.getHours(), startNormalize.getMinutes())
      : undefined;

    const endNormalize = dateEnd ? new Date(dateEnd) : undefined; // TODO: delete after fix nswag DateTime format
    const dateEndNormalize = endNormalize
      ? new TuiDay(endNormalize.getFullYear(), endNormalize.getMonth(), endNormalize.getDate())
      : undefined;
    const timeEndNormalize = endNormalize ? new TuiTime(endNormalize.getHours(), endNormalize.getMinutes()) : undefined;

    return <FormData>{
      subject,
      description,
      dateStart: dateStartNormalize,
      timeStart: timeStartNormalize,
      dateEnd: dateEndNormalize,
      timeEnd: timeEndNormalize,
      address,
      onlineLink,
      eventTypeId,
      groupChatId,
      groupInviteCode,
      committeeId,
      isVisibleToAll: isVisibleToAll ?? false,
      contactPerson: contactPersonId ? { id: contactPersonId, fullName: contactPersonFullName } : undefined, // TODO: Заменить { ... } на contactPerson после решения задачи #10334
    };
  }

  private async mapFormDataToEventUI(formData: FormData) {
    const {
      subject,
      description,
      address,
      fillOnlineLink,
      eventTypeId,
      participants,
      groupChat,
      committeeId,
      createGroupChat,
      isVisibleToAll,
      program,
      docs,
      contactPerson,
    } = formData;

    let { onlineLink } = formData;

    const dateStart = new Date(
      formData.dateStart.year,
      formData.dateStart.month,
      formData.dateStart.day,
      formData.timeStart.hours,
      formData.timeStart.minutes,
    );

    const dateEnd = new Date(
      formData.dateEnd.year,
      formData.dateEnd.month,
      formData.dateEnd.day,
      formData.timeEnd.hours,
      formData.timeEnd.minutes,
    );

    let groupChatId: number | undefined;
    let groupInviteCode: string | undefined;

    //TODO: Избавиться от дополнительного запроса полной информации о группе в этом месте или в groupInfoService.addMembers
    if (groupChat && groupChat !== 'new') {
      groupChatId = groupChat.id;

      if (groupChat.type._ === 'chatTypeBasicGroup') {
        groupInviteCode = (await this.messengerService.api.getBasicGroupFullInfo(groupChat.type.basicGroupId))
          .inviteLink?.inviteLink;
      }
      if (groupChat.type._ === 'chatTypeSupergroup') {
        groupInviteCode = (await this.messengerService.api.getSupergroupFullInfo(groupChat.type.supergroupId))
          .inviteLink?.inviteLink;
      }
      if (fillOnlineLink && groupInviteCode) {
        onlineLink = groupInviteCode;
      }
    }
    //TODO end

    return <EventUI>{
      subject,
      description,
      address,
      onlineLink,
      fillOnlineLink,
      eventTypeId,
      participants,
      groupChat: groupChat !== 'new' ? groupChat : undefined,
      groupChatId,
      groupInviteCode,
      committeeId,
      createGroupChat,
      isVisibleToAll,
      program,
      docs,
      dateStart: convertDateToApiFormat(dateStart),
      dateEnd: convertDateToApiFormat(dateEnd),
      id: this.data?.id,
      photo: this.newPhoto?.file,
      contactPersonId: contactPerson?.id,
    };
  }
}
