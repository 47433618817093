<photo-view
  *ngIf="mode === 'view'"
  [text]="text"
  [photoId]="photoId"
  [unionId]="unionId"
  [photoSrc]="photoSrc"
  [size]="size"
  [width]="width"
  [height]="height"
  [rounded]="rounded"
  [loading]="loading"
></photo-view>

<photo-edit
  *ngIf="mode === 'edit' || mode === 'create'"
  [text]="text"
  [photoId]="photoId"
  [size]="size"
  [width]="width"
  [height]="height"
  [rounded]="rounded"
  [loading]="loading"
  (photoChange)="onChangePhoto($event)"
  (photoDeleted)="onDeletedPhoto()"
></photo-edit>
