import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { TuiAvatarModule, TuiBadgedContentModule, TuiBadgeModule, TuiInputModule } from '@taiga-ui/kit';
import {
  TuiButtonModule,
  TuiDataListModule,
  TuiHintModule,
  TuiHostedDropdownModule,
  TuiModeModule,
  TuiScrollbarModule,
  TuiSvgModule,
  TuiTooltipModule,
  TuiDropdownModule,
} from '@taiga-ui/core';
import { TuiLetModule } from '@taiga-ui/cdk';
import { NgxPermissionsModule } from 'ngx-permissions';
import { ComponentsModule } from '@src/app/components';
import { LogoModule } from '@src/app/modules/branding/modules';
import { SharedModule } from '@src/app/shared';
import { IconModule } from '@src/app/modules/icon';

import { NavigationComponent } from './navigation.component';
import { NavigationDesktopComponent } from './navigation-desktop/navigation-desktop.component';
import { NavigationMobileComponent } from './navigation-mobile/navigation-mobile.component';

@NgModule({
  declarations: [NavigationComponent, NavigationDesktopComponent, NavigationMobileComponent],
  imports: [
    CommonModule,
    ComponentsModule,
    RouterModule,
    FormsModule,
    IconModule,
    ReactiveFormsModule,
    LogoModule,
    MatBadgeModule,
    MatButtonModule,
    MatIconModule,
    MatListModule,
    NgxPermissionsModule,
    TuiAvatarModule,
    TuiBadgedContentModule,
    TuiBadgeModule,
    TuiButtonModule,
    TuiDataListModule,
    TuiDropdownModule,
    TuiInputModule,
    TuiHintModule,
    TuiHostedDropdownModule,
    TuiLetModule,
    TuiModeModule,
    TuiScrollbarModule,
    TuiSvgModule,
    TuiTooltipModule,
    SharedModule,
  ],
  exports: [NavigationComponent],
})
export class NavigationModule {}
