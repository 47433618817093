<form *ngIf="!!data" class="poll-info-view" [formGroup]="infoForm">
  <div class="poll-info-view__basic">
    <app-photo
      *ngIf="!!data?.photoId"
      class="field image"
      [class.tui-skeleton]="loading"
      [class.tui-skeleton_text]="loading"
      [text]="data.titleText"
      [photoId]="data.photoId"
      [size]="tuiElementDoubleExtraLargeSize"
      [rounded]="true"
      [loading]="loading"
    ></app-photo>

    <app-field
      class="field"
      tabindex="1"
      [name]="'components.pollInfo.fields.title' | translate"
      [class.tui-skeleton]="loading"
      [class.tui-skeleton_text]="loading"
      [typeName]="'text'"
      [value]="infoForm.get('titleText')?.value"
      [size]="tuiElementLargeSize"
    ></app-field>

    <app-field
      *ngIf="infoForm.get('descriptionText')?.value"
      class="field"
      tabindex="2"
      [name]="'components.groupInfo.fields.description' | translate"
      [class.tui-skeleton]="loading"
      [class.tui-skeleton_text]="loading"
      [typeName]="'parse-links'"
      [value]="infoForm.get('descriptionText')?.value"
      [size]="tuiElementLargeSize"
    ></app-field>

    <tui-input-number
      *ngIf="infoForm.get('pollDuration')?.value"
      class="field"
      tabindex="3"
      formControlName="pollDuration"
      [tuiTextfieldSize]="tuiElementLargeSize"
      [class.tui-skeleton]="loading"
      [class.tui-skeleton_text]="loading"
      [readOnly]="true"
    >
      {{ 'components.pollInfo.fields.pollDuration' | translate }}
    </tui-input-number>

    <tui-input-date-time
      class="field"
      tabindex="4"
      formControlName="dateStart"
      [tuiTextfieldSize]="tuiElementLargeSize"
      [class.tui-skeleton]="loading"
      [class.tui-skeleton_text]="loading"
      [readOnly]="true"
    >
      {{ 'components.pollInfo.fields.dateStart' | translate }}
    </tui-input-date-time>

    <tui-input-date-time
      class="field"
      tabindex="5"
      formControlName="dateEnd"
      [tuiTextfieldSize]="tuiElementLargeSize"
      [class.tui-skeleton]="loading"
      [class.tui-skeleton_text]="loading"
      [readOnly]="true"
    >
      {{ 'components.pollInfo.fields.dateEnd' | translate }}
    </tui-input-date-time>

    <div
      *ngIf="infoForm.get('committeeId')?.value"
      class="field"
      tabindex="9"
      [class.tui-skeleton]="loading"
      [class.tui-skeleton_text]="loading"
    >
      <app-field
        [typeName]="'committee'"
        [value]="infoForm.get('committeeId')?.value"
        [size]="tuiElementLargeSize"
      ></app-field>
    </div>

    <app-field
      class="field"
      tabindex="6"
      [name]="'components.pollInfo.fields.creator' | translate"
      [typeName]="'user'"
      [value]="data.createdBy"
      [size]="tuiElementLargeSize"
      [class.tui-skeleton]="loading"
      [class.tui-skeleton_text]="loading"
    ></app-field>
  </div>

  <div *ngIf="!loading" class="poll-info-view__buttons">
    <app-copy-to-clipboard-button [link]="getLinkToClipboard()"></app-copy-to-clipboard-button>

    <div *ngIf="data && !!data.canStartPoll" tuiGroup>
      <button
        tuiButton
        class="poll-info-view-button"
        type="button"
        appearance="positive"
        [size]="tuiElementLargeSize"
        (click)="onClickStartButton()"
      >
        {{ 'components.pollInfo.buttons.start' | translate }}
      </button>

      <button
        *ngIf="
          data &&
          data.decisionTypeCode !== undefined &&
          data.decisionTypeCode !== null &&
          data.decisionTypeCode !== decisionTypeCode.Rejected
        "
        tuiButton
        class="poll-info-view-button"
        type="button"
        appearance="accent"
        [size]="tuiElementLargeSize"
        (click)="onClickDeclineButton()"
      >
        {{ 'common.buttons.decline' | translate }}
      </button>
    </div>

    <button
      *ngIf="allowStatisticsViewing"
      tuiButton
      class="poll-info-view-button"
      type="button"
      appearance="primary"
      [size]="tuiElementLargeSize"
      (click)="getStats()"
    >
      {{ 'common.buttons.statistics' | translate }}
    </button>

    <tui-loader
      *ngIf="allowEditing && memberIdsToRecall?.length"
      [showLoader]="recallButtonLoading"
      [inheritColor]="true"
      [overlay]="true"
    >
      <button
        tuiButton
        class="poll-info-view-button"
        type="button"
        appearance="primary"
        [size]="tuiElementLargeSize"
        (click)="onClickRecallButton()"
      >
        {{ 'components.pollInfo.buttons.recall' | translate }}
      </button>
    </tui-loader>

    <div class="group">
      <button
        *ngIf="allowEditing"
        tuiButton
        class="poll-info-view-button"
        type="button"
        appearance="primary"
        [size]="tuiElementLargeSize"
        (click)="onClickEditButton()"
      >
        {{ 'common.buttons.edit' | translate }}
      </button>

      <button
        *ngIf="allowEditing"
        tuiButton
        class="poll-info-view-button"
        type="button"
        appearance="secondary"
        [size]="tuiElementLargeSize"
        (click)="onClickCopyButton()"
      >
        {{ 'common.buttons.copy' | translate }}
      </button>

      <button
        *ngIf="allowEditing"
        tuiButton
        class="poll-info-view-button"
        type="button"
        appearance="accent"
        [size]="tuiElementLargeSize"
        (click)="onClickDeleteButton()"
      >
        {{ 'common.buttons.delete' | translate }}
      </button>
    </div>
  </div>

  <div class="poll-info-view__basic">
    <app-field
      *ngIf="documentsList?.length"
      class="field"
      tabindex="8"
      [name]="'components.pollInfo.fields.additionalDocs' | translate"
      [typeName]="'files-list'"
      [value]="documentsList"
      [size]="tuiElementLargeSize"
      [class.tui-skeleton]="loading"
      [class.tui-skeleton_text]="loading"
    ></app-field>

    <div
      class="field"
      tabindex="9"
      *ngIf="allowMembersViewing && membersList?.length"
      [class.tui-skeleton]="loading"
      [class.tui-skeleton_text]="loading"
    >
      <div class="members-list-title">
        {{
          membersList
            ? (membersList.length
              | numeralPlurals
                : [
                    'components.pollInfo.labels.memberOne' | translate,
                    'components.pollInfo.labels.memberFew' | translate,
                    'components.pollInfo.labels.memberMany' | translate
                  ])
            : ''
        }}
      </div>

      <app-list
        [groupingEnable]="true"
        listType="users"
        [items]="membersList"
        (selectedIdChange)="onSelectedUserIdChange($event)"
      ></app-list>
    </div>
  </div>
</form>

<ng-template #answerTypesContent let-answerType>
  <div class="answer-type">
    {{ answerType.caption | translate }}
  </div>
</ng-template>
