import { Component, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { BreakpointObserver } from '@angular/cdk/layout';
import { map } from 'rxjs/operators';
import { NgxPermissionsService } from 'ngx-permissions';
import { ResizableBaseComponent } from '@src/app/components/resizable-base-component';
import { MenuItem } from '@src/models';
import { BreakpointObserverHelperService, UserService } from '@src/core/services';
import { TranslateService } from '@ngx-translate/core';

import { EnvService } from '../../env';

import { AdministrationMenuItemIcon } from './administration.model';

@Component({
  selector: 'app-administration',
  templateUrl: './administration.component.html',
  styleUrls: ['./administration.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdministrationComponent extends ResizableBaseComponent {
  loading: boolean = false;
  allowViewing?: boolean;
  menuItems?: MenuItem<number, AdministrationMenuItemIcon>[];

  constructor(
    readonly cdr: ChangeDetectorRef,
    readonly breakpointObserver: BreakpointObserver,
    readonly breakpointObserverHelperService: BreakpointObserverHelperService,
    private router: Router,
    private userService: UserService,
    private ngxPermissionsService: NgxPermissionsService,
    private env: EnvService,
    private readonly translateService: TranslateService,
  ) {
    super(cdr, breakpointObserver, breakpointObserverHelperService);
  }

  get showFullPage(): boolean {
    return this.isLargeScreen;
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.menuItems = [
      {
        id: 0,
        icon: 'tuiIconUsers',
        title: this.translateService.instant('components.administration.labels.menuNewUsers'),
        link: './new-users',
        allowViewing$: this.userService.authUser$.pipe(
          map(user => {
            if (!user) return false;

            const permissions = this.ngxPermissionsService.getPermissions();

            return 'pageAdministrationViewing' in permissions;
          }),
        ),
      },
      {
        id: 1,
        icon: 'tuiIconPlus',
        title: this.translateService.instant('components.administration.labels.menuNewAssociation'),
        link: './new-association',
        allowViewing$: this.userService.authUser$.pipe(
          map(user => {
            if (!user) return false;

            const permissions = this.ngxPermissionsService.getPermissions();

            return 'associationCreating' in permissions;
          }),
        ),
      },
      {
        id: 2,
        icon: 'tuiIconEdit',
        title: this.translateService.instant('components.administration.labels.menuCustomNames'),
        link: './custom-names',
        allowViewing$: this.userService.authUser$.pipe(
          map(user => {
            if (!user) return false;

            const permissions = this.ngxPermissionsService.getPermissions();

            return 'pageAdministrationViewing' in permissions;
          }),
        ),
      },
      {
        id: 3,
        icon: 'tuiIconCheckCircle',
        title: this.translateService.instant('components.administration.labels.menuSelectJobTitles'),
        link: './select-job-titles',
        allowViewing$: this.userService.authUser$.pipe(
          map(user => {
            if (!user) return false;

            const permissions = this.ngxPermissionsService.getPermissions();

            return 'pageAdministrationViewing' in permissions;
          }),
        ),
      },
      {
        id: 4,
        icon: 'tuiIconCheckCircle',
        title: this.translateService.instant('components.administration.labels.menuSelectBusinessTypes'),
        link: './select-business-types',
        allowViewing$: this.userService.authUser$.pipe(
          map(user => {
            if (!user) return false;

            const permissions = this.ngxPermissionsService.getPermissions();

            return 'pageAdministrationViewing' in permissions;
          }),
        ),
      },
      {
        id: 5,
        icon: 'tuiIconUpload',
        title: this.translateService.instant('components.administration.labels.menuImport'),
        link: './import',
        allowViewing$: this.userService.authUser$.pipe(
          map(user => {
            if (!user) return false;

            const permissions = this.ngxPermissionsService.getPermissions();

            return 'importViewing' in permissions;
          }),
        ),
      },
      {
        id: 6,
        icon: 'tuiIconMail',
        title: this.translateService.instant('components.administration.labels.menuMail'),
        link: './mail',
        allowViewing$: this.userService.authUser$.pipe(
          map(user => {
            if (!user) return false;

            const permissions = this.ngxPermissionsService.getPermissions();

            return 'pageAdministrationViewing' in permissions && this.isNotCordova;
          }),
        ),
      },
      {
        id: 7,
        icon: 'tuiIconFile',
        title: this.translateService.instant('components.administration.labels.menuReports'),
        link: './reports',
        allowViewing$: this.userService.authUser$.pipe(
          map(user => {
            if (!user) return false;

            const permissions = this.ngxPermissionsService.getPermissions();

            return 'pageAdministrationViewing' in permissions && this.isNotCordova;
          }),
        ),
      },
    ];
  }

  // TODO: isNotCordova - временное решение для задачи https://redmine.tbnsoft.ru/issues/9437
  get isNotCordova() {
    return !this.env.isCordova;
  }

  onClickReturnButton(): void {
    this.cdr.markForCheck();
  }
}
