import { Observable } from 'rxjs';

export interface MenuItem<T = number, Y = string> {
  id: T;
  title: string;
  icon?: Y;
  link?: string;
  action?: () => void;
  allowViewing$?: Observable<boolean | undefined>;

  /** Значение видимости элемента */
  visibilityOption?: string;
}
