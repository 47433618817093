import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, lastValueFrom, Observable, Subject, throwError } from 'rxjs';
import { catchError, map, takeUntil, tap } from 'rxjs/operators';
import {
  PollFullView,
  PollsService,
  Id,
  QuestionView,
  ReplyDto,
  UserReplies,
  PollAdd,
  PollEditWithParticipants,
  AnswerOptionView,
} from '@src/api';
import { DECISION_TYPE_CODE } from '@src/constants';
import { TranslateService } from '@ngx-translate/core';

import { AlertService, UserService } from '../services';

@Injectable({
  providedIn: 'root',
})
export class PollService implements OnDestroy {
  poll$: BehaviorSubject<PollFullView | null>;
  polls$: BehaviorSubject<PollFullView[] | null>;
  archivalPolls$: BehaviorSubject<PollFullView[] | null>;
  currentPolls$: BehaviorSubject<PollFullView[] | null>;
  pollsForCommittee$: BehaviorSubject<PollFullView[] | undefined>;

  private destroyed$$: Subject<void> = new Subject<void>();

  constructor(
    private pollsService: PollsService,
    private userService: UserService,
    private readonly alertService: AlertService,
    private readonly translateService: TranslateService,
  ) {
    this.poll$ = new BehaviorSubject<PollFullView | null>(null);
    this.polls$ = new BehaviorSubject<PollFullView[] | null>(null);
    this.archivalPolls$ = new BehaviorSubject<PollFullView[] | null>(null);
    this.currentPolls$ = new BehaviorSubject<PollFullView[] | null>(null);
    this.pollsForCommittee$ = new BehaviorSubject<PollFullView[] | undefined>(undefined);
  }

  ngOnDestroy(): void {
    this.destroyed$$.next();
    this.destroyed$$.complete();
  }

  getPollData(id: string): Observable<PollFullView> {
    return this.pollsService.poll(id).pipe(
      catchError(err => {
        this.alertService.error(err, { autoClose: 30000 });
        return throwError(err);
      }),
    );
  }

  getPoll(id: string, callback?: (value: boolean) => void): void {
    callback?.(true);

    this.pollsService
      .poll(id)
      .pipe(
        catchError(err => {
          callback?.(false);
          // TODO show error notification
          this.alertService.error(this.translateService.instant('components.pollInfo.alerts.errors.pollNotFound'));
          return throwError(err);
        }),
        map(poll => {
          poll.questions = poll.questions
            ?.sort((a, b) => {
              if (!a.sortOrder) return -1;
              if (!b.sortOrder) return 1;
              return a.sortOrder > b.sortOrder ? 1 : -1;
            })
            .map(question => {
              question.answers?.sort((a, b) => this.sortAnswerOptions(a, b));
              return question;
            });

          return poll;
        }),
        takeUntil(this.destroyed$$),
      )
      .subscribe(poll => {
        this.poll$.next(poll);
        callback?.(false);
      });
  }

  getPolls(callback?: (value: boolean) => void): void {
    callback?.(true);

    this.pollsService
      .pollsForCurrentUser()
      .pipe(
        catchError(err => {
          this.alertService.error(err, { autoClose: 30000 });
          callback?.(false);
          return throwError(err);
        }),
        takeUntil(this.destroyed$$),
      )
      .subscribe(polls => {
        this.polls$.next(polls);

        const currentPolls: PollFullView[] = [];
        const archivalPolls: PollFullView[] = [];

        polls.forEach(poll => {
          if (
            (poll.dateEnd ? new Date(poll.dateEnd) < new Date() : true) ||
            poll.decisionTypeCode === DECISION_TYPE_CODE.Rejected ||
            (poll.decisionTypeCode === DECISION_TYPE_CODE.Passed && poll.createdBy !== this.userService.authUser?.id)
          ) {
            return archivalPolls.push(poll);
          }

          if (
            !!this.userService.authUser?.permissions?.includes('pollsAllViewing') ||
            poll.createdBy === this.userService.authUser?.id ||
            poll.decisionTypeCode === DECISION_TYPE_CODE.Accepted ||
            poll.decisionTypeCode === DECISION_TYPE_CODE.Sended ||
            poll.decisionTypeCode === DECISION_TYPE_CODE.Readed
          ) {
            return currentPolls.push(poll);
          }

          return;
        });

        currentPolls.sort((a, b) => this.sortByDateStart(a, b, true));
        archivalPolls.sort((a, b) => this.sortByDateStart(a, b, false));

        this.currentPolls$.next(currentPolls.length ? currentPolls : null);
        this.archivalPolls$.next(archivalPolls.length ? archivalPolls : null);

        callback?.(false);
      });
  }

  getPollsForCommittee(committeeId: string, callback?: (value: boolean) => void): void {
    callback?.(true);

    this.pollsService
      .pollListByCommitteeId(committeeId)
      .pipe(
        catchError(err => {
          this.alertService.error(err, { autoClose: 30000 });
          callback?.(false);
          return throwError(err);
        }),
        takeUntil(this.destroyed$$),
      )
      .subscribe(polls => {
        polls.sort((a, b) => this.sortByDateStart(a, b));
        this.pollsForCommittee$.next(polls);
        callback?.(false);
      });
  }

  createPoll(data: PollAdd): Observable<Id> {
    return this.pollsService.pollAdd(data).pipe(
      catchError(err => {
        // TODO: почему так?
        this.alertService.error(err.errors.titleText[0]);
        this.alertService.error(err, { autoClose: 30000 });
        // TODO show error notification
        return throwError(err);
      }),
    );
  }

  editPoll(data: PollEditWithParticipants): Observable<Id> {
    return this.pollsService.pollEdit(data).pipe(
      catchError(err => {
        // TODO: почему так?
        this.alertService.error(err.errors.titleText[0]);
        this.alertService.error(err, { autoClose: 30000 });
        // TODO show error notification
        return throwError(err);
      }),
    );
  }

  startPoll(id: string): Observable<QuestionView> {
    return this.pollsService.startPoll(id).pipe(
      catchError(err => {
        this.alertService.error(err, { autoClose: 30000 });
        return throwError(err);
      }),
    );
  }

  declinePoll(id: string): Observable<Id> {
    return this.pollsService.declinePoll({ id }).pipe(
      catchError(err => {
        this.alertService.error(err, { autoClose: 30000 });
        return throwError(err);
      }),
    );
  }

  deletePoll(id: string): Observable<Id> {
    return this.pollsService.deletePoll(id).pipe(
      catchError(err => {
        this.alertService.error(err, { autoClose: 30000 });
        return throwError(err);
      }),
    );
  }

  resendNotifications(pollId: string, memberIds: string[]): Observable<void> {
    return this.pollsService.resendNotifications({ id: pollId, sendTo: memberIds }).pipe(
      catchError(err => {
        this.alertService.error(this.translateService.instant(err.message), {
          autoClose: 10 * 1000,
        });

        return throwError(err);
      }),
      tap(() => {
        this.alertService.success(
          this.translateService.instant('components.pollInfo.alerts.successes.resendNotification'),
          {
            autoClose: 10 * 1000,
          },
        );
      }),
      takeUntil(this.destroyed$$),
    );
  }

  saveReply(data: ReplyDto): Observable<string[]> {
    return this.pollsService.saveReply(data).pipe(
      catchError(err => {
        this.alertService.error(err, { autoClose: 30000 });
        return throwError(err);
      }),
    );
  }

  nextQuestion(data: string): Observable<QuestionView> {
    return this.pollsService.nextQuestion(data).pipe(
      catchError(err => {
        this.alertService.error(err, { autoClose: 30000 });
        return throwError(err);
      }),
    );
  }

  allQuestionsAsync(id: string): Promise<QuestionView[] | undefined> {
    return lastValueFrom(this.pollsService.allQuestions(id));
  }

  allQuestions(pollId: string): Observable<QuestionView[]> {
    return this.pollsService.allQuestions(pollId).pipe(
      catchError(err => {
        this.alertService.error(err, { autoClose: 30000 });
        return throwError(err);
      }),
      map(questions => {
        return questions.map(question => {
          question.answers?.sort((a, b) => this.sortAnswerOptions(a, b));
          return question;
        });
      }),
    );
  }

  saveReplies(data: UserReplies): Observable<string[]> {
    return this.pollsService.saveRepliesWithAlternates(data).pipe(
      catchError(err => {
        this.alertService.error(err, { autoClose: 30000 });
        return throwError(err);
      }),
    );
  }

  getStatistics(pollId: string, sendToBot?: boolean) {
    return this.pollsService.participantStatistics(pollId, sendToBot).pipe(
      catchError(errorMessage => {
        this.sendError(errorMessage);
        return throwError(errorMessage);
      }),
    );
  }

  getAdminStatistics(pollId: string, sendToBot?: boolean) {
    return this.pollsService.adminStatistics(pollId, sendToBot).pipe(
      catchError(errorMessage => {
        this.sendError(errorMessage);
        return throwError(errorMessage);
      }),
    );
  }

  resetPoll(): void {
    this.poll$.next(null);
  }

  resetPolls(): void {
    this.polls$.next(null);
    this.archivalPolls$.next(null);
    this.currentPolls$.next(null);
  }

  resetPollsForCommittee(): void {
    this.pollsForCommittee$.next(undefined);
  }

  resetAll(): void {
    this.resetPoll();
    this.resetPolls();
  }

  private sortByDateStart(a: PollFullView, b: PollFullView, asc: boolean = true) {
    const dateStartA = a.dateStart ? new Date(a.dateStart) : undefined;
    const dateStartB = b.dateStart ? new Date(b.dateStart) : undefined;

    if (!dateStartA || !dateStartB) return 0;

    return asc ? dateStartA.getTime() - dateStartB.getTime() : dateStartB.getTime() - dateStartA.getTime();
  }

  private sortAnswerOptions(a: AnswerOptionView, b: AnswerOptionView) {
    if (!a.sortOrder) return -1;
    if (!b.sortOrder) return 1;
    return a.sortOrder > b.sortOrder ? 1 : -1;
  }

  private sendError(errorMessage: string) {
    this.alertService.error(errorMessage, {
      label: this.translateService.instant('common.alerts.errors.error2'),
      autoClose: false,
    });
  }
}
