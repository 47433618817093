import { Component, Input } from '@angular/core';
import { EventViewUI } from '@src/models';
import { DECISION_TYPE_CODE } from '@src/constants';
import { EventView } from '@src/api';
import { UserService } from '@src/core/services';
import { ObjectId } from '@src/types/id';

import { ItemType } from '../model/item.model';

@Component({
  selector: 'app-list-item-events',
  templateUrl: './list-item-events.component.html',
  styleUrls: ['../styles/list-item.component.scss', './list-item-events.component.scss'],
})
export class ListItemEventsComponent {
  @Input() set item(data: ItemType | undefined) {
    this.data = data as EventViewUI;
  }

  @Input() selectedId: ObjectId;
  @Input() loading?: boolean;

  data?: EventView;
  authUserId?: String;

  constructor(private userService: UserService) {
    this.authUserId = this.userService.authUser?.id;
  }

  isInvitation() {
    return (
      (this.data?.decisionTypeCode === DECISION_TYPE_CODE.Sended ||
        this.data?.decisionTypeCode === DECISION_TYPE_CODE.Readed) &&
      this.data?.status !== 5 &&
      (this.data?.dateEnd ? new Date(this.data?.dateEnd) >= new Date() : true)
    );
  }
}
