<form class="group-info-view" [formGroup]="infoForm">
  <div class="group-info-view__block">
    <app-photo
      class="field image"
      tabindex="1"
      [class.tui-skeleton]="loading"
      [class.tui-skeleton_text]="loading"
      [mode]="mode"
      [text]="mode === 'view' ? data?.name : undefined"
      [photoId]="data?.photoId"
      [size]="tuiElementDoubleExtraLargeSize"
      [rounded]="true"
      [loading]="loading"
      (photoChange)="onPhotoChange($event)"
      (photoDeleted)="onPhotoDelete()"
    ></app-photo>
  </div>

  <div class="group-info-view__block">
    <tui-input
      tuiAutoFocus
      class="field"
      tabindex="2"
      formControlName="name"
      [class.tui-skeleton]="loading"
      [class.tui-skeleton_text]="loading"
      [tuiTextfieldSize]="tuiElementLargeSize"
      [readOnly]="mode === 'view'"
    >
      {{
        isChannel
          ? ('components.groupInfo.fields.channelName' | translate)
          : ('components.groupInfo.fields.groupName' | translate)
      }}
      <span *ngIf="mode !== 'view'" class="tui-required"></span>
    </tui-input>

    <tui-textarea
      *ngIf="mode !== 'view' || infoForm.get('description')?.value"
      class="field field_height_min"
      tabindex="3"
      formControlName="description"
      [class.tui-skeleton]="loading"
      [class.tui-skeleton_text]="loading"
      [tuiTextfieldSize]="tuiElementLargeSize"
      [maxLength]="mode === 'view' ? null : DESCRIPTION_LIMITS"
      [expandable]="true"
      [readOnly]="mode === 'view'"
    >
      {{ 'components.groupInfo.fields.description' | translate }}
    </tui-textarea>

    <tui-checkbox-block
      *ngIf="allowSpecialFieldsViewing"
      class="field"
      tabindex="4"
      formControlName="isDefault"
      [class.tui-skeleton]="loading"
      [class.tui-skeleton_text]="loading"
      [size]="tuiElementLargeSize"
      [readOnly]="mode === 'view'"
    >
      {{ 'components.groupInfo.fields.ifDefault' | translate }}
    </tui-checkbox-block>
  </div>

  <div class="group-info-view__block" [class.tui-skeleton]="loading" [class.tui-skeleton_text]="loading">
    <!-- TODO: -->
    <div *ngIf="mode === 'create'" class="field members">
      <search-users-input
        formControlName="members"
        mode="multiple"
        [label]="'common.labels.members' | translate"
        [pluralize]="[
          'common.labels.memberOne' | translate,
          'common.labels.memberFew' | translate,
          'common.labels.memberMany' | translate
        ]"
        [excludeUsersIds]="
          (authUser$ | async) && authUser$.value && authUser$.value.id ? [authUser$.value.id] : undefined
        "
        [filter]="{ searchForRegistredOnly: data?.organisationId ? undefined : true }"
        [showFilterButton]="true"
      ></search-users-input>
    </div>
  </div>

  <div *ngIf="!loading" class="group-info-view__buttons">
    <ng-container *ngIf="data">
      <div *ngIf="mode === 'view' && data.chatId && data.active" waitTelegramChats>
        <button
          tuiButton
          class="button button_send"
          type="button"
          appearance="primary"
          [size]="tuiElementLargeSize"
          (click)="onClickChatButton(data)"
        >
          {{ 'common.buttons.goToChat' | translate }}
        </button>
      </div>

      <ng-container *ngIf="!allowEditing && !env.isBot">
        <div *ngIf="data.active === true" waitTelegramChats>
          <button
            tuiButton
            class="button button_unsubscribe"
            type="button"
            appearance="primary"
            [size]="tuiElementLargeSize"
            (click)="onClickUnsubscribeButton()"
          >
            {{ 'components.groupInfo.buttons.unsubscribe' | translate }}
          </button>
        </div>

        <div *ngIf="allowSubscribing && data.active === false" waitTelegramChats>
          <button
            tuiButton
            class="button button_subscribe"
            type="button"
            appearance="primary"
            [size]="tuiElementLargeSize"
            (click)="onClickSubscribeButton()"
          >
            {{ 'common.buttons.subscribe' | translate }}
          </button>
        </div>
      </ng-container>

      <ng-container *ngxPermissionsOnly="['synchronizeWithTelegram']">
        <div *ngIf="isSynchronizeButtonVisible" waitTelegramChats>
          <button
            tuiButton
            class="button"
            type="button"
            appearance="secondary"
            [showLoader]="synchronizing"
            [size]="tuiElementLargeSize"
            (click)="onClickSynchronizeButton()"
          >
            {{ 'components.groupInfo.buttons.synchronize' | translate }}
          </button>
        </div>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="!env.isBot && allowEditing">
      <div waitTelegramChats>
        <button
          tuiButton
          class="button button_edit"
          type="button"
          appearance="primary"
          [size]="tuiElementLargeSize"
          (click)="mode === 'view' ? onClickEditButton() : onClickSaveButton()"
        >
          {{ mode === 'view' ? ('common.buttons.edit' | translate) : ('common.buttons.save' | translate) }}
        </button>
      </div>

      <button
        *ngIf="mode !== 'view'"
        tuiButton
        class="button button_cancel"
        type="button"
        appearance="primary"
        [size]="tuiElementLargeSize"
        (click)="onClickCancelButton()"
      >
        {{ 'common.buttons.cancel' | translate }}
      </button>
    </ng-container>

    <div *ngIf="mode === 'view' && !env.isBot && allowDeleting" waitTelegramChats>
      <button
        tuiButton
        class="button button_delete"
        type="button"
        appearance="accent"
        [size]="tuiElementLargeSize"
        (click)="onClickDeleteButton()"
      >
        {{ 'common.buttons.delete' | translate }}
      </button>
    </div>
  </div>

  <div *ngIf="isChannel && mode === 'create'" class="group-info-view__info">
    <tui-svg class="group-info-view__info_alert" src="tuiIconAlertTriangle"></tui-svg>
    {{ 'components.groupInfo.labels.infoMessage' | translate }}
  </div>

  <div
    *ngIf="mode === 'view'"
    class="group-info-view__block"
    [class.tui-skeleton]="loading || membersLoading"
    [class.tui-skeleton_text]="loading || membersLoading"
  >
    <app-users-list
      *ngIf="!!members"
      [isChannel]="isChannel"
      [memberCount]="memberCount"
      [chatId]="data?.chatId"
      [organisationId]="data?.organisationId"
      [members]="members"
      (changeMembers)="getMembers()"
      (selectedUserIdChange)="onUserIdChange($event)"
    ></app-users-list>
    <div></div>
  </div>
</form>
