<form [formGroup]="form" class="preferences">
  <div class="preference">
    <div class="preference__field" [class.tui-skeleton]="loading || saving">
      {{ 'components.preferences.labels.showUsers' | translate }}
      <tui-toggle formControlName="showParentOrgUsersInLists" [showIcons]="true" [tuiTextfieldSize]="'m'"></tui-toggle>
    </div>
    <div class="preference__description">
      {{ 'components.preferences.fields.showParentOrgUsersInLists' | translate }}
    </div>
  </div>

  <div class="preference">
    <div class="preference__field" [class.tui-skeleton]="loading || saving">
      {{ 'components.preferences.labels.showOnlyChannels' | translate }}
      <tui-toggle formControlName="showOnlyChannelsInNewsFeed" [showIcons]="true" [tuiTextfieldSize]="'m'"></tui-toggle>
    </div>
    <div class="preference__description">
      {{ 'components.preferences.fields.showOnlyChannelsInNewsFeed' | translate }}
    </div>
  </div>

  <!-- TODO: Настройка скрыта из-за того, что автоматическая рассылка дайджеста ещё не сделана #10641 -->
  <!-- <div class="preference">
    <div class="preference__field" [class.tui-skeleton]="loading || saving">
      {{ 'components.preferences.labels.enableLoyaltyNewslettersTitle' | translate }}
      <tui-toggle formControlName="enableLoyaltyNewsletters" [showIcons]="true" [tuiTextfieldSize]="'m'"></tui-toggle>
    </div>
    <div class="preference__description">
      {{ 'components.preferences.labels.enableLoyaltyNewslettersDescription' | translate }}
    </div>
  </div> -->
</form>

<div class="separator"></div>

<div class="preferences">
  <div class="preferences__title">{{ 'components.preferences.labels.digestTitle' | translate }}</div>

  <div class="preference">
    <div class="preference__field" [class.tui-skeleton]="loading || saving">
      {{ 'components.preferences.labels.sendDigest' | translate }}

      <button class="preference__button" tuiButton size="xs" (click)="sendDigest()">
        <span>
          {{ 'components.preferences.buttons.sendDigest' | translate }}
        </span>
      </button>
    </div>

    <div class="preference__description">
      {{ 'components.preferences.labels.sendDigestDescription' | translate }}
    </div>
  </div>

  <div class="preference">
    <div class="preference__field" [class.tui-skeleton]="loading || saving">
      {{ 'components.preferences.labels.sendTestDigest' | translate }}

      <button class="preference__button" tuiButton size="xs" (click)="sendTestDigest()">
        <span>
          {{ 'components.preferences.buttons.sendTestDigest' | translate }}
        </span>
      </button>
    </div>

    <div class="preference__description">
      {{ 'components.preferences.labels.sendTestDigestDescription' | translate }}
    </div>
  </div>
</div>
