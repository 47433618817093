import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, forkJoin, lastValueFrom, Observable, Subject, throwError } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import { DocumentList, DocumentsService, DocumentView, TextMessage } from '@src/api';
import { EnvService } from '@src/app/modules/env';
import { TuiFileLikeUI } from '@src/models';

// TODO: DocumentTypes
// code   name
// 1      План
// 2      Адрес
// 3      Список участников
// 4      Календарь сессий
// 5      Программа
// 6      Спикеры
// 7      Место
// 8      Жюри
// 9      Фотография
// 10     Документ
// 11     Карточка компании
// 12     Положение о комитете
// 13     Приложение к опросу
// 14     Приложение к мероприятию
// 15     Счёт для организации
// 16     Письмо с реквизитами для пользователя
// 17     Политика конфиденциальности
// 18     Согласие на обработку персональных данных
// 19     Вложение к письму

@Injectable({
  providedIn: 'root',
})
export class DocumentService implements OnDestroy {
  documents$: BehaviorSubject<DocumentView[] | null>;
  tuiFiles$: BehaviorSubject<TuiFileLikeUI[] | null>;

  private destroyed$$: Subject<void> = new Subject<void>();

  constructor(private documentsService: DocumentsService, private env: EnvService) {
    this.documents$ = new BehaviorSubject<DocumentView[] | null>(null);
    this.tuiFiles$ = new BehaviorSubject<TuiFileLikeUI[] | null>(null);
  }

  ngOnDestroy(): void {
    this.destroyed$$.next();
    this.destroyed$$.complete();
  }

  getDocuments(attachId: string): void {
    this.documentsService
      .getDocumentsList(attachId)
      .pipe(
        catchError(err => {
          // TODO show error notification
          return throwError(err);
        }),
        takeUntil(this.destroyed$$),
      )
      .subscribe(documents => {
        this.documents$.next(documents);

        const tuiFiles: TuiFileLikeUI[] = documents.map(document => {
          const tuiFile: TuiFileLikeUI = {
            id: document.id,
            name: document.fileName!,
            size: document.fileSize,
          };

          return tuiFile;
        });
        this.tuiFiles$.next(tuiFiles);
      });
  }

  getDocumentInfo(id: string) {
    return this.documentsService.getDocument(id).pipe(
      catchError(err => {
        // TODO show error notification
        return throwError(() => new Error(err));
      }),
    );
  }

  addDocuments(documents: File[], attachId?: string, documentType?: number): Observable<DocumentList> {
    return this.documentsService.addDocuments(documents, attachId, undefined, undefined, documentType).pipe(
      catchError(err => {
        // TODO show error notification
        return throwError(err);
      }),
    );
  }

  deleteDocuments(documentsIds: string[]): Observable<TextMessage>[] {
    return documentsIds.map(documentId =>
      this.documentsService.deleteDocument(documentId).pipe(
        catchError(err => {
          // TODO show error notification
          return throwError(err);
        }),
      ),
    );
  }

  resetDocuments(): void {
    this.documents$.next(null);
    this.tuiFiles$.next(null);
  }

  resetAll(): void {
    this.resetDocuments();
  }

  downloadDocumentById(id: string): void {
    if (this.env.isBot) {
      this.downloadDocumentForWebAppBot(id).then(() => {
        this.env.sendDownloadNotification();
      });
    } else {
      forkJoin([this.documentsService.getDocument(id), this.documentsService.getDocumentByIdFullSize(id)])
        .pipe(
          catchError(err => {
            // TODO handle error
            return throwError(err);
          }),
        )
        .subscribe(([documentInfo, documentData]) => {
          this.downloadDocument(documentData, documentInfo.fileName);
        });
    }
  }

  downloadDocumentData(id: string) {
    return this.documentsService.getDocumentByIdFullSize(id).pipe(
      catchError(err => {
        // TODO show error notification
        return throwError(err);
      }),
    );
  }

  downloadDocument(blob: Blob, fileName?: string) {
    this.env.downloadFile({ data: blob, fileName });
  }

  downloadAssociationPolicy(associationId: string) {
    this.documentsService
      .documentByAssociationId(associationId)
      .pipe(
        catchError(err => {
          // TODO handle error
          return throwError(err);
        }),
      )
      .subscribe(documentData => {
        this.downloadDocument(documentData);
      });
  }

  private downloadDocumentForWebAppBot(documentId: string) {
    return lastValueFrom(
      this.documentsService.documentByIdFullSizeForWebApp(documentId).pipe(
        catchError(err => {
          // TODO show error notification
          return throwError(err);
        }),
      ),
    );
  }
}
