<div class="search-users-filters">
  <button *ngIf="appliedFilterCount > 0" tuiButton appearance="mono" size="m" (click)="onReset()">
    <span class="tui-space_right-2">{{ 'components.searchUsers.buttons.clearFilters' | translate }}</span>

    <tui-badge status="primary" [value]="appliedFilterCount" class="search-users-filters__badge"></tui-badge>
  </button>

  <button
    tuiButton
    appearance="whiteblock-active"
    size="m"
    icon="tuiIconFilterLarge"
    [iconRight]="icon"
    [tuiDropdown]="dropdownContent"
    [tuiDropdownManual]="(open$ | async) ?? false"
    [tuiDropdownMaxHeight]="561"
    tuiDropdownAlign="right"
    (click)="onToggleDropdown()"
    (tuiObscured)="onObscured($event)"
    (tuiActiveZoneChange)="onActiveZone($event)"
    class="search-users-filters__show-dropdown-btn"
  >
    {{ 'components.searchUsers.buttons.filters' | translate }}
  </button>

  <ng-template #icon>
    <tui-svg
      src="tuiIconChevronDownLarge"
      class="search-users-filters__dropdown_arrow"
      [class.search-users-filters__dropdown_arrow--open]="(open$ | async) ?? false"
    ></tui-svg>
  </ng-template>
</div>

<ng-template #dropdownContent>
  <div class="search-users-filters__dropdown">
    <div class="search-users-filters__dropdown_title">
      <span class="tui-text_body-m" [style.font-weight]="800">
        {{ 'components.searchUsers.buttons.filters' | translate }}
      </span>

      <button tuiButton appearance="primary" size="s" (click)="onApply()" [disabled]="!isPossibleToApply">
        {{ 'components.searchUsers.buttons.apply' | translate }}
      </button>
    </div>

    <tui-accordion class="search-users-filters__dropdown_accordion" [rounded]="false">
      <tui-accordion-item [borders]="null" size="s" *ngFor="let storeItem of getStoreValues()">
        <div filter-accordion-title [title]="storeItem.title | translate" [count]="storeItem.result.length"></div>

        <ng-template tuiAccordionItemContent>
          <div
            filter-accordion-content
            [items]="storeItem.items"
            [(result)]="storeItem.result"
            (resultChange)="onCheckFiltersCount()"
            [variant]="storeItem.variant ?? 'short'"
          ></div>
        </ng-template>
      </tui-accordion-item>
    </tui-accordion>
  </div>
</ng-template>
