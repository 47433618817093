import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  ChangeDetectorRef,
  Inject,
  EventEmitter,
  Output,
  OnDestroy,
} from '@angular/core';
import { TuiDialogContext, TuiDialogService } from '@taiga-ui/core';
import { Observer, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TelegramAuthService } from '@src/app/modules/telegram';
import { PolymorpheusContent } from '@tinkoff/ng-polymorpheus';
import { EnvService } from '@src/app/modules/env';

@Component({
  selector: 'div[telegram-check-auth]',
  templateUrl: './check-auth.component.html',
  styleUrls: ['./check-auth.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckAuthComponent implements OnInit, OnDestroy {
  @Input() infoText = '';

  @Input() emitOnSubscribe = true;

  @Output() onLogged: EventEmitter<void> = new EventEmitter();

  isLogged: boolean | null = null;

  loading = false;

  private destroyed$$: Subject<void> = new Subject<void>();

  constructor(
    private cdr: ChangeDetectorRef,
    public env: EnvService,
    public telegramAuthService: TelegramAuthService,
    @Inject(TuiDialogService) private readonly dialogService: TuiDialogService,
  ) {
    this.telegramAuthService.isLogged$.pipe(takeUntil(this.destroyed$$)).subscribe(isLogged => {
      this.isLogged = isLogged;

      if (this.emitOnSubscribe && isLogged) {
        this.onLogged.emit();
      }

      this.cdr.markForCheck();
    });
  }

  ngOnInit(): void {
    if (this.telegramAuthService.isLogged()) {
      this.onLogged.emit();
    }

    this.cdr.markForCheck();
  }

  ngOnDestroy(): void {
    this.telegramAuthService.preventGetTokenFromBot();
  }

  showLoginToTelegram(content: PolymorpheusContent<TuiDialogContext>) {
    this.telegramAuthService.allowGetTokenFromBot();

    this.dialogService
      .open(content, {
        closeable: false,
        dismissible: false,
      })
      .subscribe();
  }

  onSuccess(observer: Observer<void>): void {
    observer.complete();

    if (this.isLogged) {
      this.onLogged.emit();
    }
  }
}
