<div class="information">
  <div class="information__header">
    <h1 class="information__title">{{ 'components.navigation.constants.navigationItems.infoTitle' | translate }}</h1>
  </div>
  <div class="information__list">
    <mat-nav-list>
      <ng-container *ngFor="let item of items">
        <mat-list-item *ngxPermissionsOnly="item.permissions || []" (click)="onClickItem(item)">
          <lucide-icon matListIcon class="information-list-item__icon" [name]="item.icon!"></lucide-icon>
          <div matLine class="information-list-item__title">{{ item.title | translate }}</div>
          <div *ngIf="item.description" matLine class="information-list-item__description">
            {{ item.description | translate }}
          </div>
        </mat-list-item>
      </ng-container>
    </mat-nav-list>
  </div>
</div>
