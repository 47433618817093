<div class="legend">
  <tui-legend-item
    *ngFor="let item of items; let index = index"
    class="legend__item"
    [size]="size"
    [color]="item.color"
    [text]="item.label | translate"
  >
    {{ item.value }}
  </tui-legend-item>
</div>
