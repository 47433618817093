import { Inject, Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NgxPermissionsService, NgxRolesObject, NgxRolesService } from 'ngx-permissions';
import { RoleCode, ROLE_PERMISSIONS_DEFAULT } from '@src/app/modules/auth';
import { UserUI } from '@src/models';
import { NavigationItem } from '@src/app/modules/navigation';
import { NAVIGATION_ITEMS_TOKEN } from '@src/app/app.module';
import { VisibilityEnum, CustomNamesService, CustomNamesItem } from '@src/app/modules/custom-name-tabs';

@Injectable({
  providedIn: 'root',
})
export class DynamicPermissionsService implements OnDestroy {
  private destroyed$$: Subject<void> = new Subject<void>();
  private user?: UserUI;

  constructor(
    private readonly ngxPermissionsService: NgxPermissionsService,
    private readonly ngxRolesService: NgxRolesService,
    private readonly customNamesService: CustomNamesService,
    @Inject(NAVIGATION_ITEMS_TOKEN) private navigationItems: NavigationItem[],
  ) {
    this.customNamesService.data$.pipe(takeUntil(this.destroyed$$)).subscribe(customNames => {
      this.setData(this.user, customNames);
    });
  }

  ngOnDestroy(): void {
    this.destroyed$$.next();
    this.destroyed$$.complete();
  }

  async setData(user?: UserUI, customNames: CustomNamesItem[] = []) {
    this.user = user;
    if (!user && !customNames) {
      return;
    }

    const roles = this.ngxRolesService.getRoles();

    customNames.forEach(customName => {
      const findedNavigationItem = this.navigationItems.find(
        navigationItem => navigationItem.id === customName.codeName,
      );

      // TODO: костыль, удалить, когда поправят бэк и отказаться от customNameItem, как раньше было использовать customName
      const customNameItem = this.getCustomName(customNames, customName);

      if (!!findedNavigationItem && !!findedNavigationItem.permissions) {
        switch (customNameItem.visibilityOption) {
          case VisibilityEnum.INVISIBLE:
            this.removePermissions(findedNavigationItem.permissions);
            break;

          case VisibilityEnum.ADMINISTRATORS:
            if (RoleCode.AdminUO in roles) {
              this.addPermissions(findedNavigationItem.permissions);
            } else {
              this.removePermissions(findedNavigationItem.permissions);
            }
            break;

          case VisibilityEnum.ALL:
            if (
              !!findedNavigationItem.permissions.find(permission =>
                this.includePermissionInDefaultPermissions(permission, roles),
              )
            ) {
              this.addPermissions(findedNavigationItem.permissions);
            }
            break;

          default:
            this.removePermissions(findedNavigationItem.permissions);
            break;
        }
      }
    });
  }

  private getCustomName(customNames: CustomNamesItem[], customName: CustomNamesItem): CustomNamesItem {
    if (!!customName.title) {
      return customName;
    } else {
      const customNameItemOtherLanguageCode = customNames.filter(
        customNameItem => customNameItem.codeName === customName.codeName && !!customNameItem.title,
      )[0];
      return customNameItemOtherLanguageCode || customName;
    }
  }

  private addPermissions(permission: string | string[]) {
    this.ngxPermissionsService.addPermission(permission);
  }

  private removePermissions(permissions: string | string[]) {
    if (typeof permissions === 'string') {
      this.ngxPermissionsService.removePermission(permissions);
    } else {
      permissions.forEach(permission => this.removePermissions(permission));
    }
  }

  // TODO: проверка разрешения в ролевой модели (дефолтных разрешениях)
  private includePermissionInDefaultPermissions(permission: string, roles: NgxRolesObject) {
    return !!Object.keys(roles).find(role => ROLE_PERMISSIONS_DEFAULT[role as RoleCode].includes(permission));
  }
}
