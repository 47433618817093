import { CommitteeViewWithParents } from '@src/api';

import { ContactUI } from './contact.model';

export interface CommitteeUI extends CommitteeViewWithParents {
  photo?: File;
  contacts?: ContactUI[];
  icon?: string;
  parentId?: string;
  children?: CommitteeUI[];
}

export interface CommitteeTreeItem {
  /** Идентификатор комитета */
  id?: string;

  /** Родительский комитет */
  parentId?: string;

  /** Наименование комитета */
  title?: string;

  /** Флаг, что данная запись является корнем */
  isRoot: boolean;
}
