import { Injectable } from '@angular/core';
import { BreakpointState } from '@angular/cdk/layout';
import { BreakpointsRules, ScreenTypes } from '@src/models';

@Injectable({
  providedIn: 'root',
})
export class BreakpointObserverHelperService {
  getScreenType(breakpointState: BreakpointState): ScreenTypes {
    return breakpointState.breakpoints[BreakpointsRules.xxlBreakpoint]
      ? 'double-extra-large'
      : breakpointState.breakpoints[BreakpointsRules.xlBreakpoint]
        ? 'extra-large'
        : breakpointState.breakpoints[BreakpointsRules.lgBreakpoint]
          ? 'large'
          : breakpointState.breakpoints[BreakpointsRules.mdBreakpoint]
            ? 'medium'
            : breakpointState.breakpoints[BreakpointsRules.smBreakpoint]
              ? 'small'
              : 'extra-small';
  }

  getScreenTypesSmallerThanTarget(target: ScreenTypes, includeTarget: boolean = true): ScreenTypes[] {
    let smallerScreenTypes: ScreenTypes[];

    switch (target) {
      case 'small':
        smallerScreenTypes = ['extra-small'];
        break;
      case 'medium':
        smallerScreenTypes = ['extra-small', 'small'];
        break;
      case 'large':
        smallerScreenTypes = ['extra-small', 'small', 'medium'];
        break;
      case 'extra-large':
        smallerScreenTypes = ['extra-small', 'small', 'medium', 'large'];
        break;
      case 'double-extra-large':
        smallerScreenTypes = ['extra-small', 'small', 'medium', 'large', 'extra-large'];
        break;
      default:
        smallerScreenTypes = [];
        break;
    }

    return includeTarget ? [...smallerScreenTypes, target] : smallerScreenTypes;
  }

  getScreenTypesBiggerThanTarget(target: ScreenTypes, includeTarget: boolean = true): ScreenTypes[] {
    let biggerScreenTypes: ScreenTypes[];

    switch (target) {
      case 'extra-small':
        biggerScreenTypes = ['small', 'medium', 'large', 'extra-large', 'double-extra-large'];
        break;
      case 'small':
        biggerScreenTypes = ['medium', 'large', 'extra-large', 'double-extra-large'];
        break;
      case 'medium':
        biggerScreenTypes = ['large', 'extra-large', 'double-extra-large'];
        break;
      case 'large':
        biggerScreenTypes = ['extra-large', 'double-extra-large'];
        break;
      case 'extra-large':
        biggerScreenTypes = ['double-extra-large'];
        break;
      default:
        biggerScreenTypes = [];
    }

    return includeTarget ? [target, ...biggerScreenTypes] : biggerScreenTypes;
  }

  get breakpointsSet(): BreakpointsRules[] {
    return [
      BreakpointsRules.xsBreakpoint,
      BreakpointsRules.smBreakpoint,
      BreakpointsRules.mdBreakpoint,
      BreakpointsRules.lgBreakpoint,
      BreakpointsRules.xlBreakpoint,
      BreakpointsRules.xxlBreakpoint,
    ];
  }
}
