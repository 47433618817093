<form class="poll-info-edit" [formGroup]="infoForm">
  <div class="poll-info-edit__basic">
    <app-photo
      class="field image"
      mode="edit"
      [class.tui-skeleton]="loading"
      [class.tui-skeleton_text]="loading"
      [photoId]="data?.photoId"
      [size]="tuiElementDoubleExtraLargeSize"
      [rounded]="true"
      [loading]="loading"
      (photoChange)="onPhotoChange($event)"
    ></app-photo>

    <tui-input
      tuiAutoFocus
      class="field"
      tabindex="2"
      formControlName="titleText"
      [tuiTextfieldSize]="tuiElementLargeSize"
      [class.tui-skeleton]="loading"
      [class.tui-skeleton_text]="loading"
      [readOnly]="mode === 'view'"
    >
      {{ 'components.pollInfo.fields.title' | translate }}
      <span class="tui-required"></span>
    </tui-input>

    <tui-textarea
      class="field field_height_min"
      tabindex="3"
      formControlName="descriptionText"
      [class.tui-skeleton]="loading"
      [class.tui-skeleton_text]="loading"
      [tuiTextfieldSize]="tuiElementLargeSize"
      [expandable]="true"
    >
      {{ 'components.pollInfo.fields.description' | translate }}
    </tui-textarea>

    <tui-input-number
      class="field"
      tabindex="4"
      formControlName="pollDuration"
      [tuiTextfieldSize]="tuiElementLargeSize"
      [class.tui-skeleton]="loading"
      [class.tui-skeleton_text]="loading"
    >
      {{ 'components.pollInfo.fields.pollDuration' | translate }}
    </tui-input-number>

    <div tuiGroup class="field">
      <tui-input-date
        tabindex="5"
        formControlName="dateStart"
        [class.tui-skeleton]="loading"
        [class.tui-skeleton_text]="loading"
        [tuiTextfieldSize]="tuiElementLargeSize"
      >
        {{ 'components.pollInfo.fields.dateStart' | translate }}
        <span class="tui-required"></span>
      </tui-input-date>
      <tui-input-time
        tabindex="6"
        formControlName="timeStart"
        [class.tui-skeleton]="loading"
        [class.tui-skeleton_text]="loading"
        [tuiTextfieldSize]="tuiElementLargeSize"
        [items]="timeItems"
      >
        {{ 'components.pollInfo.fields.timeStart' | translate }}
        <span class="tui-required"></span>
      </tui-input-time>
    </div>

    <div tuiGroup class="field">
      <tui-input-date
        tabindex="7"
        formControlName="dateEnd"
        [class.tui-skeleton]="loading"
        [class.tui-skeleton_text]="loading"
        [tuiTextfieldSize]="tuiElementLargeSize"
        [min]="dateStart"
      >
        {{ 'components.pollInfo.fields.dateEnd' | translate }}
        <span class="tui-required"></span>
      </tui-input-date>
      <tui-input-time
        tabindex="8"
        formControlName="timeEnd"
        [class.tui-skeleton]="loading"
        [class.tui-skeleton_text]="loading"
        [tuiTextfieldSize]="tuiElementLargeSize"
        [items]="timeItems"
      >
        {{ 'components.pollInfo.fields.timeEnd' | translate }}
        <span class="tui-required"></span>
      </tui-input-time>
    </div>

    <app-committee-field
      class="field"
      tabindex="9"
      [class.tui-skeleton]="loading"
      [class.tui-skeleton_text]="loading"
      [size]="tuiElementLargeSize"
      [selectedIds]="infoForm.get('committeeId')?.value ? [infoForm.get('committeeId')!.value] : []"
      (selectedIdsChange)="onChangeSelectedCommitteeIds($event)"
    ></app-committee-field>

    <div class="field" tabindex="10" [class.tui-skeleton]="loading" [class.tui-skeleton_text]="loading">
      <search-users-input
        formControlName="members"
        mode="multiple"
        [label]="'common.labels.members' | translate"
        [pluralize]="[
          'common.labels.memberOne' | translate,
          'common.labels.memberFew' | translate,
          'common.labels.memberMany' | translate
        ]"
        [showFilterButton]="true"
      ></search-users-input>
    </div>

    <div class="field" tabindex="11" [class.tui-skeleton]="loading" [class.tui-skeleton_text]="loading">
      <tui-input-files
        class="size_{{ tuiElementLargeSize }}"
        formControlName="documents"
        [link]="'components.pollInfo.fields.uploadDocuments' | translate"
        [label]="'common.fields.fileLabel' | translate"
        [maxFileSize]="maxDocFileSize"
        [size]="tuiElementLargeSize"
      >
        <input tuiInputFiles [multiple]="true" />
      </tui-input-files>
      <tui-files class="tui-space_top-1">
        <tui-file
          *ngFor="let file of infoForm.get('documents')?.value"
          [file]="file"
          (removed)="removeFile(infoForm.get('documents'), file)"
        ></tui-file>
      </tui-files>
    </div>
  </div>

  <ng-container *ngIf="mode === 'create' || (mode === 'edit' && !!data?.canEditPollQuestions)">
    <div class="poll-info-edit__questions" formArrayName="questions">
      <!-- TODO: add tui-reorder -->
      <tui-island
        *ngFor="let question of questions.controls; let qIndex = index"
        class="tui-island"
        [class.tui-skeleton]="loading"
        [class.tui-skeleton_text]="loading"
      >
        <div class="tui-island__paragraph item" [formGroupName]="qIndex">
          <div class="item__question">
            <tui-input
              tuiAutoFocus
              class="field"
              tabindex="11"
              formControlName="text"
              [tuiTextfieldSize]="tuiElementLargeSize"
            >
              {{ 'components.pollInfo.fields.question' | translate }}
              <span class="tui-required"></span>
            </tui-input>
          </div>
          <div class="item__answer-type">
            <tui-select
              formControlName="answerType"
              [valueContent]="answerTypesContent"
              [tuiTextfieldSize]="tuiElementLargeSize"
            >
              {{ 'components.pollInfo.fields.answerTypeTitle' | translate }}
              <span class="tui-required"></span>
              <tui-data-list-wrapper
                *tuiDataList
                [items]="answerTypes"
                [itemContent]="answerTypesContent"
              ></tui-data-list-wrapper>
            </tui-select>
          </div>
          <div class="item__answer-control" formArrayName="answers">
            <ng-container [ngSwitch]="question.get('answerType')?.value?.type">
              <div class="answer">
                <ng-container *ngFor="let answers of getAnswerOptionsByIndex(qIndex).controls; let aIndex = index">
                  <ng-container [formGroupName]="aIndex">
                    <tui-input *ngSwitchCase="'text'" [tuiTextfieldSize]="tuiElementLargeSize">
                      {{ 'components.pollInfo.fields.answerTypeText' | translate }}
                    </tui-input>

                    <tui-textarea
                      *ngSwitchCase="'area'"
                      [expandable]="true"
                      [rows]="10"
                      [tuiTextfieldSize]="tuiElementLargeSize"
                    >
                      {{ 'components.pollInfo.fields.answerTypeArea' | translate }}
                    </tui-textarea>

                    <div *ngSwitchCase="'radio'" class="answer__radiobox">
                      <tui-radio [size]="tuiElementLargeSize"></tui-radio>
                      <tui-input
                        formControlName="textValue"
                        [tuiTextfieldLabelOutside]="true"
                        [tuiTextfieldSize]="tuiElementLargeSize"
                      >
                        {{ ('components.pollInfo.labels.option' | translate) + ' ' + (aIndex + 1) }}
                      </tui-input>
                      <button
                        tuiIconButton
                        type="button"
                        appearance="accent"
                        icon="tuiIconCloseLarge"
                        size="s"
                        [title]="'common.buttons.delete' | translate"
                        (click)="removeAnswerOption(qIndex, aIndex)"
                      ></button>
                    </div>

                    <div *ngSwitchCase="'check'" class="answer__checkbox">
                      <tui-checkbox [size]="tuiElementLargeSize"></tui-checkbox>
                      <tui-input
                        formControlName="textValue"
                        [tuiTextfieldLabelOutside]="true"
                        [tuiTextfieldSize]="tuiElementLargeSize"
                      >
                        {{ ('components.pollInfo.labels.option' | translate) + ' ' + (aIndex + 1) }}
                      </tui-input>
                      <button
                        tuiIconButton
                        type="button"
                        appearance="accent"
                        icon="tuiIconCloseLarge"
                        size="s"
                        [title]="'common.buttons.delete' | translate"
                        (click)="removeAnswerOption(qIndex, aIndex)"
                      ></button>
                    </div>

                    <div *ngSwitchCase="'select'" class="answer__select">
                      <tui-input
                        formControlName="textValue"
                        [tuiTextfieldLabelOutside]="true"
                        [tuiTextfieldSize]="tuiElementLargeSize"
                      ></tui-input>
                      <button
                        tuiIconButton
                        type="button"
                        appearance="accent"
                        icon="tuiIconCloseLarge"
                        size="s"
                        [title]="'common.buttons.delete' | translate"
                        (click)="removeAnswerOption(qIndex, aIndex)"
                      ></button>
                    </div>

                    <!-- TODO: уточнить название поля для formControlName, не было указано, указал file -->
                    <tui-input-files
                      *ngSwitchCase="'file'"
                      formControlName="file"
                      class="size_{{ tuiElementLargeSize }}"
                      [tuiTextfieldSize]="tuiElementLargeSize"
                      [size]="tuiElementLargeSize"
                    >
                      <input tuiInputFiles [multiple]="true" />
                    </tui-input-files>
                    <tui-files class="tui-space_top-1">
                      <tui-file
                        *ngFor="let file of answers.get('file')?.value"
                        [file]="file"
                        (removed)="removeFile(answers.get('file'), file)"
                      ></tui-file>
                    </tui-files>

                    <div tuiGroup *ngSwitchCase="'date'">
                      <tui-input-date formControlName="date" [tuiTextfieldSize]="tuiElementLargeSize">
                        {{ 'components.pollInfo.fields.selectDate' | translate }}
                      </tui-input-date>

                      <tui-input-time
                        formControlName="time"
                        [tuiTextfieldSize]="tuiElementLargeSize"
                        [items]="timeItems"
                      >
                        {{ 'components.pollInfo.fields.selectTime' | translate }}
                      </tui-input-time>
                    </div>

                    <tui-input-slider
                      *ngSwitchCase="'slider'"
                      formControlName="textValue"
                      [min]="1"
                      [max]="10"
                      [steps]="9"
                      [segments]="9"
                      [disabled]="true"
                      [tuiTextfieldSize]="tuiElementLargeSize"
                    ></tui-input-slider>
                  </ng-container>
                </ng-container>

                <!-- TODO: refactoring -->
                <div *ngSwitchCase="'radio'" class="answer__buttons">
                  <button
                    tuiButton
                    type="button"
                    appearance="whiteblock-active"
                    size="s"
                    (click)="addAnswerOptionByIndex(qIndex)"
                  >
                    {{ 'components.pollInfo.buttons.addOption' | translate }}
                  </button>
                  <button
                    *ngIf="getOtherVisible(qIndex)"
                    tuiButton
                    type="button"
                    appearance="whiteblock-active"
                    size="s"
                    (click)="addAnswerOptionByIndex(qIndex, { isAlternative: true })"
                  >
                    {{ 'components.pollInfo.buttons.addAlternative' | translate }}
                  </button>
                </div>
                <div *ngSwitchCase="'check'">
                  <button
                    tuiButton
                    type="button"
                    appearance="whiteblock-active"
                    size="s"
                    (click)="addAnswerOptionByIndex(qIndex)"
                  >
                    {{ 'components.pollInfo.buttons.addOption' | translate }}
                  </button>
                  <button
                    *ngIf="getOtherVisible(qIndex)"
                    tuiButton
                    type="button"
                    appearance="whiteblock-active"
                    size="s"
                    (click)="addAnswerOptionByIndex(qIndex, { isAlternative: true })"
                  >
                    {{ 'components.pollInfo.buttons.addAlternative' | translate }}
                  </button>
                </div>
                <div *ngSwitchCase="'select'">
                  <button
                    tuiButton
                    type="button"
                    appearance="whiteblock-active"
                    size="s"
                    (click)="addAnswerOptionByIndex(qIndex)"
                  >
                    {{ 'components.pollInfo.buttons.addOption' | translate }}
                  </button>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
        <button
          tuiButton
          type="button"
          appearance="flat"
          size="m"
          class="tui-island__footer-button"
          (click)="removeQuestion(qIndex)"
        >
          {{ 'common.buttons.delete' | translate }}
        </button>
      </tui-island>
    </div>
    <button
      tuiButton
      type="button"
      appearance="primary"
      size="m"
      icon="tuiIconPlusCircleLarge"
      [class.tui-skeleton]="loading"
      [class.tui-skeleton_text]="loading"
      (click)="addQuestion()"
    >
      {{ 'components.pollInfo.buttons.addQuestion' | translate }}
    </button>
  </ng-container>

  <div *ngIf="!loading" class="poll-info-edit__buttons">
    <button
      tuiButton
      class="poll-info-edit-button poll-info-edit-button_edit"
      type="button"
      appearance="primary"
      [size]="tuiElementLargeSize"
      (click)="onClickSaveButton()"
    >
      {{ 'common.buttons.save' | translate }}
    </button>
    <button
      tuiButton
      class="poll-info-edit-button poll-info-edit-button_cancel"
      type="button"
      appearance="primary"
      [size]="tuiElementLargeSize"
      (click)="onClickCancelButton()"
    >
      {{ 'common.buttons.cancel' | translate }}
    </button>
  </div>
</form>

<ng-template #answerTypesContent let-answerType>
  <div class="answer-type">
    {{ answerType.caption | translate }}
  </div>
</ng-template>
