export * from './app.service';
import { AppService } from './app.service';
export * from './businessTypes.service';
import { BusinessTypesService } from './businessTypes.service';
export * from './businessTypesForUnion.service';
import { BusinessTypesForUnionService } from './businessTypesForUnion.service';
export * from './cC.service';
import { CCService } from './cC.service';
export * from './chats.service';
import { ChatsService } from './chats.service';
export * from './committeeContacts.service';
import { CommitteeContactsService } from './committeeContacts.service';
export * from './committeeSubscriptions.service';
import { CommitteeSubscriptionsService } from './committeeSubscriptions.service';
export * from './committeeUserSubscriptions.service';
import { CommitteeUserSubscriptionsService } from './committeeUserSubscriptions.service';
export * from './committees.service';
import { CommitteesService } from './committees.service';
export * from './contactsForOrganisation.service';
import { ContactsForOrganisationService } from './contactsForOrganisation.service';
export * from './contactsForUser.service';
import { ContactsForUserService } from './contactsForUser.service';
export * from './dashboard.service';
import { DashboardService } from './dashboard.service';
export * from './documents.service';
import { DocumentsService } from './documents.service';
export * from './event.service';
import { EventService } from './event.service';
export * from './invites.service';
import { InvitesService } from './invites.service';
export * from './jobTitles.service';
import { JobTitlesService } from './jobTitles.service';
export * from './jobTitlesForUnion.service';
import { JobTitlesForUnionService } from './jobTitlesForUnion.service';
export * from './labelItems.service';
import { LabelItemsService } from './labelItems.service';
export * from './letters.service';
import { LettersService } from './letters.service';
export * from './loyalty.service';
import { LoyaltyService } from './loyalty.service';
export * from './mails.service';
import { MailsService } from './mails.service';
export * from './newsletterTemplates.service';
import { NewsletterTemplatesService } from './newsletterTemplates.service';
export * from './newsletters.service';
import { NewslettersService } from './newsletters.service';
export * from './notificationUserSettings.service';
import { NotificationUserSettingsService } from './notificationUserSettings.service';
export * from './oPFTypes.service';
import { OPFTypesService } from './oPFTypes.service';
export * from './objectMenu.service';
import { ObjectMenuService } from './objectMenu.service';
export * from './organisation.service';
import { OrganisationService } from './organisation.service';
export * from './organisationSettings.service';
import { OrganisationSettingsService } from './organisationSettings.service';
export * from './participantRolesForUnion.service';
import { ParticipantRolesForUnionService } from './participantRolesForUnion.service';
export * from './photo.service';
import { PhotoService } from './photo.service';
export * from './polls.service';
import { PollsService } from './polls.service';
export * from './registrations.service';
import { RegistrationsService } from './registrations.service';
export * from './reports.service';
import { ReportsService } from './reports.service';
export * from './role.service';
import { RoleService } from './role.service';
export * from './subscriptionsForOrganisation.service';
import { SubscriptionsForOrganisationService } from './subscriptionsForOrganisation.service';
export * from './subscriptionsForUsers.service';
import { SubscriptionsForUsersService } from './subscriptionsForUsers.service';
export * from './test.service';
import { TestService } from './test.service';
export * from './token.service';
import { TokenService } from './token.service';
export * from './user.service';
import { UserService } from './user.service';
export * from './visibilityOptions.service';
import { VisibilityOptionsService } from './visibilityOptions.service';
export const APIS = [AppService, BusinessTypesService, BusinessTypesForUnionService, CCService, ChatsService, CommitteeContactsService, CommitteeSubscriptionsService, CommitteeUserSubscriptionsService, CommitteesService, ContactsForOrganisationService, ContactsForUserService, DashboardService, DocumentsService, EventService, InvitesService, JobTitlesService, JobTitlesForUnionService, LabelItemsService, LettersService, LoyaltyService, MailsService, NewsletterTemplatesService, NewslettersService, NotificationUserSettingsService, OPFTypesService, ObjectMenuService, OrganisationService, OrganisationSettingsService, ParticipantRolesForUnionService, PhotoService, PollsService, RegistrationsService, ReportsService, RoleService, SubscriptionsForOrganisationService, SubscriptionsForUsersService, TestService, TokenService, UserService, VisibilityOptionsService];
