<form class="organisation-info-edit" [formGroup]="infoForm">
  <div class="organisation-info-edit__basic">
    <app-photo
      class="image"
      mode="edit"
      [class.tui-skeleton]="loading"
      [class.tui-skeleton_text]="loading"
      [photoId]="data?.photoId"
      [size]="tuiElementDoubleExtraLargeSize"
      [rounded]="true"
      [loading]="!!loading"
      (photoChange)="onPhotoChange($event)"
    ></app-photo>

    <tui-input
      tuiAutoFocus
      class="field"
      tabindex="1"
      formControlName="fullName"
      [tuiTextfieldSize]="tuiElementLargeSize"
      [class.tui-skeleton]="loading"
      [class.tui-skeleton_text]="loading"
    >
      {{ 'components.organisationInfo.fields.fullName' | translate }}
      <span class="tui-required"></span>
    </tui-input>

    <tui-input
      class="field"
      tabindex="2"
      formControlName="shortName"
      [tuiTextfieldSize]="tuiElementLargeSize"
      [class.tui-skeleton]="loading"
      [class.tui-skeleton_text]="loading"
    >
      {{ 'components.organisationInfo.fields.shortName' | translate }}
      <span class="tui-required"></span>
    </tui-input>

    <tui-input-number
      *ngIf="!data?.parentOrganisation"
      class="field"
      tabindex="3"
      formControlName="numberOfOrganisations"
      [tuiTextfieldSize]="tuiElementLargeSize"
      [class.tui-skeleton]="loading"
      [class.tui-skeleton_text]="loading"
      [min]="1"
    >
      {{ 'components.organisationInfo.fields.numberOfOrganisations' | translate }}
    </tui-input-number>
  </div>

  <div class="organisation-info-edit__extended">
    <tui-select
      class="field"
      tabindex="4"
      formControlName="opfTypeId"
      [valueContent]="opfTypes ? opfTypesStringify(opfTypes) : ''"
      [tuiTextfieldSize]="tuiElementLargeSize"
      [class.tui-skeleton]="loading"
      [class.tui-skeleton_text]="loading"
      [tuiTextfieldCleaner]="true"
    >
      {{ 'components.organisationInfo.fields.opfType' | translate }}
      <tui-data-list *tuiDataList>
        <button *ngFor="let opfType of opfTypes" tuiOption [value]="opfType.id">
          {{ opfType.name }}
        </button>
      </tui-data-list>
    </tui-select>

    <tui-input
      class="field"
      tabindex="5"
      formControlName="addressPhysical"
      [tuiTextfieldSize]="tuiElementLargeSize"
      [class.tui-skeleton]="loading"
      [class.tui-skeleton_text]="loading"
    >
      {{ 'components.organisationInfo.fields.addressPhysical' | translate }}
    </tui-input>

    <tui-input
      class="field"
      tabindex="6"
      formControlName="addressLegal"
      [tuiTextfieldSize]="tuiElementLargeSize"
      [class.tui-skeleton]="loading"
      [class.tui-skeleton_text]="loading"
    >
      {{ 'components.organisationInfo.fields.addressLegal' | translate }}
    </tui-input>

    <tui-multi-select
      *ngIf="allowSpecialFieldsEditing"
      class="field"
      tabindex="7"
      formControlName="businessTypes"
      [class.tui-skeleton]="loading"
      [class.tui-skeleton_text]="loading"
      [tuiTextfieldSize]="tuiElementLargeSize"
      [tuiTextfieldCleaner]="true"
      [expandable]="true"
      [editable]="false"
      [stringify]="stringifyBusinessTypes"
    >
      {{ 'components.organisationInfo.fields.businessTypes' | translate }}
      <tui-data-list *tuiDataList tuiMultiSelectGroup>
        <button *ngFor="let businessType of businessTypes" tuiOption [value]="businessType">
          {{ businessType.businessTypeName }}
        </button>
      </tui-data-list>
    </tui-multi-select>

    <tui-input-date
      *ngIf="data?.parentOrganisation && allowSpecialFieldsForAssociationEditing"
      class="field"
      tabindex="8"
      formControlName="membershipPaidTill"
      [class.tui-skeleton]="loading"
      [class.tui-skeleton_text]="loading"
      [tuiTextfieldSize]="tuiElementLargeSize"
    >
      {{ 'components.organisationInfo.fields.membershipPaidTill' | translate }}
    </tui-input-date>

    <ng-container *ngIf="!infoForm.get('oldCard')?.value">
      <tui-input-files
        *ngIf="!infoForm.get('card')?.value"
        class="field size_{{ tuiElementLargeSize }}"
        tabindex="9"
        formControlName="card"
        [maxFileSize]="maxDocFileSize"
        [link]="'components.organisationInfo.fields.uploadCard' | translate"
        [label]="' '"
        [size]="tuiElementLargeSize"
        [class.tui-skeleton]="loading"
        [class.tui-skeleton_text]="loading"
      >
        <input tuiInputFiles accept=".pdf, .doc, .docx, .rtf" />
      </tui-input-files>
      <tui-files *ngIf="!!infoForm.get('card')?.value">
        <tui-file
          [file]="infoForm.get('card')?.value"
          (removed)="infoForm.get('card')?.reset()"
          [class.tui-skeleton]="loading"
          [class.tui-skeleton_text]="loading"
        ></tui-file>
      </tui-files>
    </ng-container>

    <ng-container *ngIf="!!infoForm.get('oldCard')?.value">
      <tui-files>
        <tui-file
          [file]="infoForm.get('oldCard')?.value"
          (removed)="infoForm.get('oldCard')?.reset()"
          [class.tui-skeleton]="loading"
          [class.tui-skeleton_text]="loading"
        ></tui-file>
      </tui-files>
    </ng-container>

    <ng-container
      *ngIf="!infoForm.value.oldInvoice && data?.parentOrganisation && allowSpecialFieldsForAssociationEditing"
    >
      <tui-input-files
        *ngIf="!infoForm.get('invoice')?.value"
        class="field size_{{ tuiElementLargeSize }}"
        tabindex="10"
        [maxFileSize]="maxDocFileSize"
        formControlName="invoice"
        [link]="'components.organisationInfo.fields.uploadInvoice' | translate"
        [label]="' '"
        [size]="tuiElementLargeSize"
        [class.tui-skeleton]="loading"
        [class.tui-skeleton_text]="loading"
      ></tui-input-files>
      <tui-files *ngIf="!!infoForm.get('invoice')?.value">
        <tui-file [file]="infoForm.get('invoice')?.value" (removed)="infoForm.get('invoice')?.reset()"></tui-file>
      </tui-files>
    </ng-container>

    <ng-container
      *ngIf="!!infoForm.value.oldInvoice && !!data?.parentOrganisation && allowSpecialFieldsForAssociationEditing"
    >
      <tui-files>
        <tui-file
          [file]="infoForm.get('oldInvoice')?.value"
          (removed)="infoForm.get('oldInvoice')?.reset()"
          [class.tui-skeleton]="loading"
          [class.tui-skeleton_text]="loading"
        ></tui-file>
      </tui-files>
    </ng-container>
  </div>

  <div class="organisation-info-edit__contacts" formArrayName="contacts">
    <tui-island *ngFor="let contact of contacts.controls; let cIndex = index" class="tui-island">
      <div class="tui-island__paragraph item" [formGroupName]="cIndex">
        <div class="contact">
          <div class="contact__type">
            <tui-select
              tabindex="11"
              formControlName="contactTypeId"
              [valueContent]="contactTypes ? contactTypesStringify(contactTypes) : ''"
              [tuiTextfieldSize]="tuiElementLargeSize"
            >
              {{ 'components.organisationInfo.fields.contactType' | translate }}
              <tui-data-list *tuiDataList>
                <button *ngFor="let contactType of contactTypes" tuiOption [value]="contactType.id">
                  {{ contactType.description | translate }}
                </button>
              </tui-data-list>
            </tui-select>
          </div>
          <tui-input
            class="contact__description"
            tabindex="12"
            formControlName="description"
            [tuiTextfieldSize]="tuiElementLargeSize"
            [class.tui-skeleton]="loading"
            [class.tui-skeleton_text]="loading"
          >
            {{ 'components.organisationInfo.fields.contactName' | translate }}
          </tui-input>
          <div class="contact__value">
            <tui-input
              *ngIf="isNotOtherAndNotFileContactLocal(contact.value.contactTypeId)"
              tabindex="13"
              formControlName="contact"
              [tuiTextfieldSize]="tuiElementLargeSize"
              [class.tui-skeleton]="loading"
              [class.tui-skeleton_text]="loading"
            >
              {{ 'components.organisationInfo.fields.contactValue' | translate }}
            </tui-input>
            <tui-textarea
              *ngIf="isOtherContactLocal(contact.value.contactTypeId)"
              class="field_height_min"
              tabindex="13"
              formControlName="contact"
              [tuiTextfieldSize]="tuiElementLargeSize"
              [class.tui-skeleton]="loading"
              [class.tui-skeleton_text]="loading"
              [expandable]="true"
            >
              {{ 'components.organisationInfo.fields.contactValue' | translate }}
            </tui-textarea>
            <ng-container *ngIf="isFileContactLocal(contact.value.contactTypeId)">
              <tui-files *ngIf="!!getDocumentFormControl(contact, 'oldDocument').value">
                <tui-file
                  [file]="getDocumentFormControl(contact, 'oldDocument').value"
                  (removed)="resetDocumentFormControl(contact, 'oldDocument')"
                  [class.tui-skeleton]="loading"
                  [class.tui-skeleton_text]="loading"
                ></tui-file>
              </tui-files>
              <ng-container *ngIf="!getDocumentFormControl(contact, 'oldDocument').value">
                <tui-input-files
                  *ngIf="!getDocumentFormControl(contact, 'newDocument').value"
                  class="size_{{ tuiElementLargeSize }}"
                  [link]="'common.fields.fileLink' | translate"
                  [label]="' '"
                  tabindex="15"
                  formControlName="newDocument"
                  [maxFileSize]="maxDocFileSize"
                  [tuiTextfieldSize]="tuiElementLargeSize"
                  [class.tui-skeleton]="loading"
                  [class.tui-skeleton_text]="loading"
                  [size]="tuiElementLargeSize"
                ></tui-input-files>
                <tui-files *ngIf="!!getDocumentFormControl(contact, 'newDocument').value">
                  <tui-file
                    [file]="getDocumentFormControl(contact, 'newDocument').value"
                    (removed)="resetDocumentFormControl(contact, 'newDocument')"
                    [class.tui-skeleton]="loading"
                    [class.tui-skeleton_text]="loading"
                  ></tui-file>
                </tui-files>
              </ng-container>
            </ng-container>
          </div>
          <tui-input-number
            class="contact__sortOrder"
            tabindex="16"
            formControlName="sortOrder"
            [tuiTextfieldSize]="tuiElementLargeSize"
            [class.tui-skeleton]="loading"
            [class.tui-skeleton_text]="loading"
          >
            {{ 'components.organisationInfo.fields.contactSortOrder' | translate }}
          </tui-input-number>
        </div>
      </div>
      <button
        tuiButton
        type="button"
        appearance="flat"
        size="m"
        class="tui-island__footer-button"
        tabindex="17"
        (click)="removeContact(cIndex)"
      >
        {{ 'common.buttons.delete' | translate }}
      </button>
    </tui-island>
  </div>
  <button
    *ngIf="!loading"
    tuiButton
    type="button"
    appearance="primary"
    size="m"
    icon="tuiIconPlusCircleLarge"
    tabindex="18"
    (click)="addContact()"
  >
    {{ 'components.organisationInfo.buttons.addField' | translate }}
  </button>
  <div *ngIf="!loading && allowEditing" class="organisation-info-edit__buttons">
    <button
      tuiButton
      class="organisation-info-edit-button organisation-info-edit-button_edit"
      tabindex="19"
      type="button"
      appearance="primary"
      [size]="tuiElementLargeSize"
      (click)="onClickSaveButton()"
    >
      {{ 'common.buttons.save' | translate }}
    </button>
    <button
      tuiButton
      class="organisation-info-edit-button organisation-info-edit-button_cancel"
      tabindex="20"
      type="button"
      appearance="primary"
      [size]="tuiElementLargeSize"
      (click)="onClickCancelButton()"
    >
      {{ 'common.buttons.cancel' | translate }}
    </button>
  </div>
</form>
