import { Pipe, PipeTransform } from '@angular/core';
import { ParentCommittee } from '@src/api';

@Pipe({
  name: 'getCommitteePath',
})
export class GetCommitteePathPipe implements PipeTransform {
  transform(parentCommittees?: ParentCommittee[]): string {
    return parentCommittees?.length ? parentCommittees.map(parentCommittee => parentCommittee.name).join(' > ') : '';
  }
}
